import React from 'react';

function CitywideButton({ phaseName, text, onClick }) {
  return (
    <div className={`ee-citywide ee-citywide-${phaseName} ee-animate`}>
      <button className="btn btn-link" onClick={() => onClick(null)}>{text}</button>
    </div>
  );
}

export default CitywideButton;
