import React from 'react';

import ChallengeTimer from '../ChallengeTimer';
import { getClassName } from '../../utils';
import i18n from '../../i18n';

function ChallengeBanner({ name, title, description, activeUntil, bannerRef }) {
  const useTimer = (activeUntil > 0);
  return (
    <div className="ee-challenge-notification-wrapper" ref={bannerRef}>
      <div className={`ee-challenge-notification ee-challenge-notification-${getClassName(name)}`}>
        <div className="ee-notification-details">
          <header>
            <h1 dangerouslySetInnerHTML={{ __html: title }}/>
          </header>
          <section dangerouslySetInnerHTML={{ __html: description }}/>
        </div>
        {useTimer ? (
          <div className="ee-notification-timer">
            <div className="ee-notification-timer-value">
              <ChallengeTimer activeUntil={activeUntil}/>
            </div>
            <div className="ee-notification-timer-label">
              {i18n.t('common.remainingTime.label')}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ChallengeBanner;
