import {Type} from '../action';

const defaultState = '';

function loadGameId(){
  let ret = defaultState;
  if( sessionStorage ){
    // We have a local place to store the session (ie - browser)
    ret = sessionStorage.gameId;
    if( typeof ret === 'undefined' ){
      ret = defaultState;
      sessionStorage.gameId = ret;
    }
  }
  return ret;
}

function setGameId( val ){
  if( sessionStorage && sessionStorage.gameId !== val ){
    sessionStorage.gameId = val;
  }
  return val;
}

export function gameId( state, action ){
  if( typeof state === 'undefined' ){
    state = loadGameId();
  }

  switch( action.type ){
    case Type.setGameId:    return setGameId(action.gameId);
    case Type.reset:        return setGameId(defaultState);
    default:                return state;
  }
}
