import React from 'react';
import { mapStateToPropsUser,mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as GameDb from '../../action/gamedb';
import { FullPageWrapper } from '../../components/Wrapper';
import Header from '../../components/Header';
import { formatDate, getAllPhaseNames, makeCancelable } from '../../utils';
import Footer from '../../components/Footer';
import i18n from '../../i18n';
import ConfirmPopup from '../../components/ConfirmPopup';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamesAvailable: false,
      gamesList: [],
      isRemoveGamePopupOpen: false,
      isStartGamePopupOpen: false,
      selectedGameId: null
    };
    this.getGames = makeCancelable(GameDb.listGames());
    this.setGames = this.setGames.bind(this);
    this.handleClickStartGame = this.handleClickStartGame.bind(this);
    this.handleOpenStartGamePopup = this.handleOpenStartGamePopup.bind(this);
    this.handleCloseStartGamePopup = this.handleCloseStartGamePopup.bind(this);
    this.handleClickStartGameConfirm = this.handleClickStartGameConfirm.bind(this);
    this.handleClickReconnectGame = this.handleClickReconnectGame.bind(this);
    this.handleClickRemoveGame = this.handleClickRemoveGame.bind(this);
    this.handleOpenRemoveGamePopup = this.handleOpenRemoveGamePopup.bind(this);
    this.handleCloseRemoveGamePopup = this.handleCloseRemoveGamePopup.bind(this);
    this.handleClickRemoveGameConfirm = this.handleClickRemoveGameConfirm.bind(this);
  }

  componentDidMount() {
    this.getGames
      .promise
      .then(games => this.setGames(games))
      .catch( err => {
        console.error('List.componentDidMount',err);
      })
  }

  componentWillUnmount() {
    this.getGames.cancel();
  }

  setGames(games) {
    this.setState({
      gamesAvailable: true,
      gamesList: games
    })
  }

  handleClickStartGame() {
    const hasInProgressGames = this.state.gamesList.some(game => (game.state.display.part < 5));
    if (hasInProgressGames) {
      this.handleOpenStartGamePopup();
    } else {
      this.props.history.push('/create/new');
    }
  }

  handleOpenStartGamePopup() {
    this.setState({ isStartGamePopupOpen: true });
  }

  handleCloseStartGamePopup() {
    this.setState({ isStartGamePopupOpen: false });
  }

  handleClickStartGameConfirm() {
    this.handleCloseStartGamePopup();
    this.props.history.push('/create/new');
  }

  handleClickReconnectGame(gameId) {
    this.props.setGameId(gameId);
    this.props.history.push('/play');
  }

  handleClickRemoveGame(gameId) {
    this.setState({ selectedGameId: gameId });
    this.handleOpenRemoveGamePopup();
  }

  handleOpenRemoveGamePopup() {
    this.setState({ isRemoveGamePopupOpen: true });
  }

  handleCloseRemoveGamePopup() {
    this.setState({ isRemoveGamePopupOpen: false });
  }

  handleClickRemoveGameConfirm() {
    this.props.del( this.state.selectedGameId )
      .then( () => GameDb.listGames() )
      .then( games => {
        this.setGames(games);
        this.setState({ selectedGameId: null });
        this.handleCloseRemoveGamePopup();
      });
  }

  render() {
    const { gamesAvailable, gamesList } = this.state;
    const hasGames = (gamesAvailable && gamesList.length > 0);
    const confirmStartGameProps = {
      title: i18n.t('myGamesModal.inProgressTitle'),
      content: i18n.t('myGamesModal.inProgressDescription'),
      button: i18n.t('myGamesModal.inProgressConfirmNewGame'),
      type: 'info'
    };
    const confirmRemoveGameProps = {
      title: i18n.t('myGamesModal.endGameTitle'),
      content: i18n.t('myGamesModal.endGameDescription'),
      button: i18n.t('myGamesModal.endGameConfirmDelete')
    };
    return (
      <FullPageWrapper withBackground withHeader withFooter>
        <Header />
        <main className="ee-list-games-view">
          <header className="ee-list-games-header">
            <h1>{i18n.t('myGamesScreen.title')}</h1>
            <div className="ee-game-actions">
              <Link to="/create/test" className="ee-test-btn btn btn-link">
                {i18n.t('myGamesScreen.testGame')}
              </Link>
              <button className="ee-start-btn btn btn-link" onClick={() => this.handleClickStartGame()}>
                {i18n.t('myGamesScreen.startGame')}
              </button>
            </div>
          </header>
          {gamesAvailable ? (
            hasGames ? (
              <GameList
                games={gamesList}
                onClickReconnectGame={this.handleClickReconnectGame}
                onClickRemoveGame={this.handleClickRemoveGame}
              />
            ) : (
              <section className="ee-no-games">
                <h2>{i18n.t('myGamesScreen.noActiveGames')}</h2>
                <h3>{i18n.t('myGamesScreen.createGameNow')}</h3>
              </section>
            )
          ) : null}
        </main>
        <Footer />
        <ConfirmPopup
          isOpen={this.state.isStartGamePopupOpen}
          onClose={this.handleCloseStartGamePopup}
          onConfirm={this.handleClickStartGameConfirm}
          {...confirmStartGameProps}
        />
        <ConfirmPopup
          isOpen={this.state.isRemoveGamePopupOpen}
          onClose={this.handleCloseRemoveGamePopup}
          onConfirm={this.handleClickRemoveGameConfirm}
          {...confirmRemoveGameProps}
        />
      </FullPageWrapper>
    )
  }
}

function GameList({ games, onClickReconnectGame, onClickRemoveGame }) {
  const allPhases = getAllPhaseNames();
  return (
    <section className="ee-game-list">
      <table>
        <thead>
          <tr>
            <th className="ee-game-type">{i18n.t('myGamesScreen.gameTypeHeader')}</th>
            <th>{i18n.t('myGamesScreen.dateCreatedHeader')}</th>
            <th>{i18n.t('myGamesScreen.statusHeader')}</th>
            <th>{i18n.t('myGamesScreen.gameCodeHeader')}</th>
            <th className="ee-game-actions">{i18n.t('myGamesScreen.gameActionsHeader')}</th>
          </tr>
        </thead>
        <tbody>
          {games.map(({ id, created, state: { scenario, isDemo, display: { part } }}) => {
            let scenarioClassName = '';
            if (scenario) {
              scenarioClassName = `ee-game-type-${scenario}`;
            }
            const isCompleted = (part === 5);
            let phaseName = '';
            if (isCompleted) {
              phaseName = i18n.t('phases.thankYou');
            } else if (part === 0) {
              if (isDemo) {
                phaseName = i18n.t('phases.startTestGame');
              } else {
                phaseName = i18n.t('phases.startGame');
              }
            } else if (scenario) {
              phaseName = allPhases[scenario][part - 1]['title'];
            }
            return (
              <tr key={`game-${id}`}>
                <td className={`ee-game-type ${scenarioClassName}`}>
                  <span className="sr-only">{scenario}</span>
                </td>
                <td>{formatDate(created)}</td>
                <td>{phaseName}</td>
                <td>{id}</td>
                <td className="ee-game-actions">
                  {!isCompleted ? (
                    <button onClick={() => onClickReconnectGame(id)} className="ee-reconnect-btn btn btn-link">
                      {i18n.t('myGamesScreen.reconnectGame')}
                    </button>
                  ) : (
                    <span className="ee-game-complete">
                      {i18n.t('myGamesScreen.gameComplete')}
                    </span>
                  )}
                  <button className="ee-end-btn btn btn-link" onClick={() => onClickRemoveGame(id)}>
                    {isCompleted ? i18n.t('myGamesScreen.deleteGame') : i18n.t('myGamesScreen.endGame')}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(List);
