import {Type} from '../action';

const showActions = [
  0, // Lobby has no action panel
  3, // Prepare shows actions on screen 3
  3, // Respond shows actions on screen 3
  2, // Recover shows actions on screen 2
  1, // Adapt shows actions on screen 1
  0  // Credits has no action panel
];

const defaultState = {
  part: 0,
  screen: 1,
  eventStart: 0,
  showActions: false
};

function baseState( state, base ){
  console.log('baseState state',state,base);
  let ret = {
    part:   base.part,
    screen: base.screen,
    eventStart: state.eventStart ? state.eventStart : base.eventStart,
    showActions: (base.screen >= showActions[base.part])
  };

  // Set the event to start when we move from /2/1 to /2/2
  if( state.part === 2 && state.screen === 1 && base.part === 2 && base.screen === 2 ){
    ret.eventStart = Date.now();
  }
  console.log('baseState',state.part,state.screen,base.part,base.screen,ret);

  return ret;
}

export function display( state = defaultState, action ){
  switch( action.type ){
    case Type.state:        return baseState( state, action.state.display );
    case Type.changeScreen: return baseState( state, action );
    case Type.reset:        return defaultState;
    default:                return state;
  }
}
