import {combineReducers} from 'redux';

import {Type,Status} from '../action';

import {maxPlayers,maxTips,maxChallenges} from './player';

/**
 * Create the player array.
 * There is no player 0, so element 0 is undefined, and 1-maxPlayers (inclusive)
 * are defined.
 * @return {Array}
 */
function playerState(){
  let state = [];
  state.push( undefined );
  for( let co=1; co<=maxPlayers; co++ ){
    state.push({
      sent: false,
      acknowledged: false
    })
  }
  return state;
}

/**
 * Create a tip or challenge array
 * @param max
 * @return {Array}
 */
function tipChallengeState( max ){
  let state = [];
  for( let co=0; co<max; co++ ){
    state.push({
      player: playerState()
    });
  }
  return state;
}

function updatePlayerAttribute( state, index, client, attribute, value ){
  state = state.slice();
  state[index].player = state[index].player.slice();

  let current = state[index].player[client];
  let update = {};
  update[attribute] = value;
  state[index].player[client] = Object.assign( {}, current, update );

  return state;
}

function updatePlayer( state, action, index, client ){
  if( action.status === Status.set ){
    state = updatePlayerAttribute( state, index, client, 'sent', true );

  } else if( action.status === Status.acknowledged ){
    state = updatePlayerAttribute( state, index, client, 'acknowledged', true );

  } else {
    // Ignore
  }

  return state;
}

function wasSent( state, index, client ){
  let current = state[index].player[client];
  return current['sent'];
}

/**
 * This is either a tipRelease or challengeRelease, and either sent or acked.
 * @param state
 * @param action
 * @param index Which tip or challenge is being updated
 */
function update( state, action, index ){
  const client = action.client;
  const min = client ? client : 1;
  const max = client ? client : maxPlayers;
  for( let co=min; co<=max; co++ ){
    state = updatePlayer( state, action, index, co );
  }
  return state;
}

/**
 * If the challenge was ended, we can set it to being acked
 * if it was sent for this index, since we don't care anymore.
 * @param state
 * @param action
 * @param index
 */
function ended( state, action, index ){
  const client = action.client;
  const min = client ? client : 1;
  const max = client ? client : maxPlayers;
  for( let co=min; co<=max; co++ ){
    if( wasSent( state, index, co ) ){
      state = updatePlayerAttribute( state, index, co, 'acknowledged', true );
    }
  }
  return state;
}

function tip( state, action ){
  if( !state ){
    state = tipChallengeState( maxTips );
  }
  switch( action.type ){
    case Type.state     : return action.state.notification.tip;
    case Type.tipRelease: return update( state, action, action.tip );
    case Type.reset:      return tipChallengeState( maxTips );
    default:              return state;
  }
}

function challenge( state, action ){
  if( !state ){
    state = tipChallengeState( maxChallenges );
  }
  switch( action.type ){
    case Type.state:            return action.state.notification.challenge;
    case Type.challengeRelease: return update( state, action, action.challenge );
    case Type.challengeEnded:   return ended(  state, action, action.challenge );
    case Type.reset:            return tipChallengeState( maxChallenges );
    default:                    return state;
  }
}

export const notification = combineReducers({
  tip,
  challenge
});

