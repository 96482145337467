import React from 'react';
import { mapDispatchToProps, mapStateToPropsUser } from '../../action';
import { connect } from 'react-redux';

import * as GameDb from '../../action/gamedb';
import { history } from '../../History';

class CreateGame extends React.Component {
  componentDidMount() {
    const { isTest, setGameId } = this.props;
    GameDb.newGame(isTest).then(game => {
      setGameId(game.id);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { gameId } = this.props;
    if (gameId && (gameId !== prevProps.gameId)) {
      history.push('/play/0/1');
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(CreateGame);
