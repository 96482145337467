import React from 'react';
import { Link } from 'react-router-dom';

import i18n from '../i18n';

function HeaderAlternate({ title, onClickCancel }) {
  return (
    <header className="ee-alternate-header">
      <div className="ee-logo">
        <Link to="/">
          <span className="sr-only">{i18n.t('global.title')}</span>
        </Link>
      </div>
      {title ? (
        <div className="ee-title">
          <h1>{title}</h1>
        </div>
      ) : null}
      <div className="ee-header-actions">
        <button className="ee-cancel-btn btn btn-link" onClick={onClickCancel}>
          {i18n.t('common.cancel')}
        </button>
      </div>
    </header>
  );
}

export default HeaderAlternate;
