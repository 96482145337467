import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withModuleTranslation } from '../i18n';
import { getClassName } from '../utils';

class PlayerTypesRecover extends React.Component {
  render() {
    const { t, tm, player, currentScenario, section, onClick } = this.props;
    const neighborhoods = currentScenario.neighborhood.slice(1);
    // If no section has been sent, then assume we should show them all.
    let showAll = true;
    let currentNeighborhood = 0;
    if (section) {
      showAll = false;
      // Subtract 1 because there is an explanation page before the scoring starts
      currentNeighborhood = section - 1;
    }
    let cityWideScore = 0;
    let cityWideMaxScore = 0;
    return (
      <React.Fragment>
        <ul className="ee-player-type-list ee-player-type-list-recover ee-animate">
          {neighborhoods.map((neighborhood, neighborhoodIndex) => {
            const isDone = (showAll || neighborhoodIndex < currentNeighborhood);
            const isCurrent = (neighborhoodIndex === currentNeighborhood);
            const neighborhoodName = tm(`module.neighborhoods.${neighborhood}`);
            const neighborhoodClassName = `ee-player-type ee-player-type-${neighborhoodIndex} ee-player-type-${getClassName(neighborhoodName)}`;
            const neighborhoodPlayer = player[neighborhoodIndex + 1];
            const neighborhoodScore = neighborhoodPlayer.score.completed * 100;
            const neighborhoodMaxScore = neighborhoodPlayer.score.needMet.length * 100;
            cityWideScore += neighborhoodScore;
            cityWideMaxScore += neighborhoodMaxScore;
            return (
              <li
                className={`${neighborhoodClassName} ee-animate`}
                key={`neighborhood-${neighborhoodIndex}`}
              >
                <button
                  className="btn btn-link"
                  onClick={onClick ? (() => onClick(neighborhood)) : null}
                  disabled={!onClick || !(isDone || isCurrent)}
                >
                  <span className="sr-only">{neighborhoodName}</span>
                </button>
                {isDone ? (
                  <div className="ee-neighborhood-score">
                    <span
                      className="ee-neighborhood-score-value"
                      dangerouslySetInnerHTML={{__html: t('phaseDialog.recover.neighborhoodScore', { score: neighborhoodScore })}}
                    />
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
        <div
          className="ee-citywide ee-citywide-recover ee-animate"
          dangerouslySetInnerHTML={{__html: t('phaseDialog.recover.cityWideScore', { score: cityWideScore, maxScore: cityWideMaxScore })}}
        />
      </React.Fragment>
    );
  }
}

const translatedComponent = withTranslation()(withModuleTranslation()(PlayerTypesRecover));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
