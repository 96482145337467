import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withModuleTranslation } from '../../i18n';
import PhaseDialogHeader from './PhaseDialogHeader';
import { getClassName } from '../../utils';

class PhaseDialogRecover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectScreen: true,
      showDetailsScreen: false,
      showDoneScreen: false,
      selectedDecision: null,
      selectedScore: null
    };
    this.playerTypeIndex = 0;
    this.handleClickDecision = this.handleClickDecision.bind(this);
    this.handleClickSelectContinue = this.handleClickSelectContinue.bind(this);
    this.handleClickScore = this.handleClickScore.bind(this);
    this.handleClickDetailsBack = this.handleClickDetailsBack.bind(this);
    this.handleClickDetailsContinue = this.handleClickDetailsContinue.bind(this);
    this.handleClickDoneBack = this.handleClickDoneBack.bind(this);
    this.handleClickDoneSubmit = this.handleClickDoneSubmit.bind(this);
  }

  componentDidMount() {
    const { player, playerTypeKey, currentScenario } = this.props;
    this.playerTypeIndex = currentScenario.neighborhood.indexOf(playerTypeKey);
    const playerScore = player[this.playerTypeIndex].score;
    // Only initialize the decision and score if the decision was already set.
    const decision = playerScore.decision;
    if (decision > 0) {
      this.setState({
        selectedDecision: decision,
        selectedScore: playerScore.completed
      });
    }
  }

  handleClickDecision(decision) {
    if (this.state.selectedDecision !== decision) {
      this.setState({ selectedDecision: decision });
    }
  }

  handleClickSelectContinue() {
    this.setState({
      showSelectScreen: false,
      showDetailsScreen: true
    });
  }

  handleClickScore(score) {
    if (this.state.selectedScore !== score) {
      this.setState({ selectedScore: score });
    }
  }

  handleClickDetailsBack() {
    this.setState({
      showSelectScreen: true,
      showDetailsScreen: false
    });
  }

  handleClickDetailsContinue() {
    this.setState({
      showSelectScreen: false,
      showDetailsScreen: false,
      showDoneScreen: true
    });
  }

  handleClickDoneBack() {
    this.setState({
      showDetailsScreen: true,
      showDoneScreen: false
    });
  }

  handleClickDoneSubmit() {
    this.props.setDecision(this.state.selectedDecision, this.playerTypeIndex);
    this.props.setCompleted(this.state.selectedScore, this.playerTypeIndex);
    this.setState({
      showSelectScreen: true,
      showDetailsScreen: false,
      showDoneScreen: false,
      selectedDecision: null,
      selectedScore: null
    });
    this.props.handleCloseDialog();
    this.props.displayNext();
  }

  render() {
    const { tm, playerTypeKey, handleCloseDialog } = this.props;
    const { showSelectScreen, showDetailsScreen, showDoneScreen, selectedDecision, selectedScore } = this.state;
    const neighborhoodName = tm(`module.neighborhoods.${playerTypeKey}`);
    let screen = null;
    let handleBack = null;
    if (showSelectScreen) {
      const screenProps = {
        ...this.props,
        selectedDecision,
        onClickDecision: this.handleClickDecision,
        onClickContinue: this.handleClickSelectContinue
      };
      screen = <SelectDecisionScreen {...screenProps} />;
    } else if (showDetailsScreen) {
      const screenProps = {
        ...this.props,
        playerTypeIndex: this.playerTypeIndex,
        selectedScore,
        neighborhoodName,
        onClickScore: this.handleClickScore,
        onClickContinue: this.handleClickDetailsContinue
      };
      screen = <SelectCompletedNeedsScreen {...screenProps} />;
      handleBack = this.handleClickDetailsBack;
    } else if (showDoneScreen) {
      const screenProps = {
        ...this.props,
        selectedDecision,
        selectedScore,
        onClickSubmit: this.handleClickDoneSubmit
      };
      screen = <ScoreReviewScreen {...screenProps} />;
      handleBack = this.handleClickDoneBack;
    }
    const neighborhoodClassFragment = getClassName(neighborhoodName);
    return (
      <div className={`ee-phase-dialog-recover ee-phase-dialog-recover-${neighborhoodClassFragment}`}>
        <PhaseDialogHeader
          classFragment={`player-type-${neighborhoodClassFragment}`}
          onClickBack={handleBack ? handleBack : null}
          onClickClose={handleCloseDialog}
          caption={neighborhoodName}
        />
        <div className="ee-phase-dialog-content">{screen}</div>
      </div>
    );
  }
}

function SelectDecisionScreen({ t, tm, playerTypeKey, selectedDecision, onClickDecision, onClickContinue }) {
  const boardPaths = tm(`module.participant.respond.challenges.${playerTypeKey}.options`, { returnObjects: true });
  return (
    <React.Fragment>
      <div className="ee-phase-dialog-body">
        <div className="ee-phase-dialog-title">{t('phaseDialog.recover.challengeTitle')}</div>
        <div className="ee-decisions">
          {boardPaths.map((challenge, challengeIndex) => {
            const challengeDecision = challengeIndex + 1;
            const selectedClassName = ((selectedDecision === challengeDecision) ? `ee-decision-selected ee-decision-${challengeIndex}-selected` : '');
            return (
              <button
                className={`ee-decision ee-decision-${challengeIndex} ${selectedClassName} btn btn-link`}
                key={`decision-${challengeIndex}`}
                onClick={() => onClickDecision(challengeDecision)}
                dangerouslySetInnerHTML={{__html: challenge.name}}
              />
            );
          })}
        </div>
      </div>
      <div className="ee-phase-dialog-footer">
        <button className="btn btn-link" onClick={() => onClickContinue()} disabled={selectedDecision === null}>
          {t('phaseDialog.recover.continueButton')}
        </button>
      </div>
    </React.Fragment>
  );
}

function SelectCompletedNeedsScreen({ t, player, playerTypeIndex, selectedScore, neighborhoodName, onClickScore, onClickContinue }) {
  const numNeeds = player[playerTypeIndex].score.needMet.length;
  return (
    <React.Fragment>
      <div className="ee-phase-dialog-body">
        <div className="ee-phase-dialog-title">{t('phaseDialog.recover.categoriesTitle')}</div>
        <p>{t('phaseDialog.recover.neighborhoodCategories', { neighborhood: neighborhoodName })}</p>
        <div className="ee-completed-needs">
          {[...Array(numNeeds + 1)].map((e, index) => {
            const selectedClassName = ((selectedScore === index) ? `ee-completed-selected ee-completed-${index}-selected` : '');
            return (
              <button
                className={`ee-completed ee-completed-${index} ${selectedClassName} btn btn-link`}
                key={`score-${index}`}
                onClick={() => onClickScore(index)}
              >
                {index}
              </button>
            );
          })}
        </div>
      </div>
      <div className="ee-phase-dialog-footer">
        <button className="btn btn-link" onClick={() => onClickContinue()} disabled={selectedScore === null}>
          {t('phaseDialog.recover.continueButton')}
        </button>
      </div>
    </React.Fragment>
  );
}

function ScoreReviewScreen({ t, tm, playerTypeKey, selectedDecision, selectedScore, onClickSubmit }) {
  const decision = selectedDecision - 1;
  const challenge = tm(`module.participant.respond.challenges.${playerTypeKey}.options.${decision}`, { returnObjects: true });
  return (
    <React.Fragment>
      <div className="ee-phase-dialog-body ee-phase-dialog-body-done">
        <div className="ee-phase-dialog-title">{t('phaseDialog.recover.scoreReviewTitle')}</div>
        <div className="ee-phase-dialog-subtitle">{t('phaseDialog.recover.challengeSelectedTitle')}</div>
        <div className={`ee-decision ee-decision-${decision}`} dangerouslySetInnerHTML={{__html: challenge.name}} />
        <hr />
        <div className="ee-phase-dialog-subtitle">{t('phaseDialog.recover.categoriesCompletedTitle')}</div>
        <div className="ee-score">{selectedScore}</div>
      </div>
      <div className="ee-phase-dialog-footer">
        <button className="btn btn-link" onClick={() => onClickSubmit()}>
          {t('phaseDialog.recover.submitButton')}
        </button>
      </div>
    </React.Fragment>
  );
}

const translatedComponent = withTranslation()(withModuleTranslation()(PhaseDialogRecover));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
