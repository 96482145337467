import React from 'react';
import { mapStateToPropsUser } from '../../action';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import JsxParser from 'react-jsx-parser';

import * as Auth from '../../action/auth';
import i18n from '../../i18n';
import { FullPageWrapper } from '../../components/Wrapper';
import Footer from '../../components/Footer';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    toast.dismiss(this.loginErrorId);
  }

  handleChangeInput(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    toast.dismiss(this.loginErrorId);
    if (this.isFormValid()) {
      Auth.login(this.state.email, this.state.password)
        .catch(err => {
          const errorCode = err.name === 'NotAuthenticated' ?
            'error.unableToLogin' :
            'error.authFailure';
          const loginErrorMessage =
            <JsxParser
              components={{ Link }}
              jsx={i18n.t(errorCode)}
              renderInWrapper={false}
            />;
          this.loginErrorId = toast.error(loginErrorMessage);
        });
    }
  }

  isFormValid() {
    return (this.state.email !== '' && this.state.password !== '');
  }

  render() {
    return (
      <FullPageWrapper withBackground withFooter>
        <main className="ee-login-view">
          <div className="ee-login">
            <header>
              <h1>
                <span className="sr-only">{i18n.t('global.title')}</span>
              </h1>
              <h2>{i18n.t('authScreen.title')}</h2>
            </header>
            <form className="ee-form" onSubmit={this.handleSubmit}>
              <div className="ee-form-body">
                <label htmlFor="email" className="sr-only">{i18n.t('authScreen.emailLabel')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={this.state.email}
                  placeholder={i18n.t('authScreen.emailLabel')}
                  autoFocus
                  onChange={this.handleChangeInput}
                />
                <label htmlFor="password" className="sr-only">{i18n.t('authScreen.passwordLabel')}</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={this.state.password}
                  placeholder={i18n.t('authScreen.passwordLabel')}
                  onChange={this.handleChangeInput}
                />
              </div>
              <div className="ee-form-actions">
                <button
                  type="submit"
                  className="ee-submit-btn btn btn-link"
                  disabled={!this.isFormValid()}
                  onClick={this.handleSubmit}
                >
                  {i18n.t('authScreen.signInButton')}
                </button>
                <Link to="/account/create" className="ee-create-account-btn btn btn-link">
                  {i18n.t('authScreen.createAccountButton')}
                </Link>
                <Link to="/account/resetPassword">
                  {i18n.t('authScreen.requestPasswordReset')}
                </Link>
              </div>
            </form>
          </div>
        </main>
        <Footer />
      </FullPageWrapper>
    );
  }
}

export default connect(mapStateToPropsUser, null)(Login);
