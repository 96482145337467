import * as Action from "./action";
import feathers from "@feathersjs/client/index";
import primusClient from '@feathersjs/primus-client';

import {createBrowserHistory} from "history";
import {pathDisplay, history as sharedHistory} from "./History";

import * as queryString from 'query-string';

import * as Auth from "./action/auth";

const port = 3030;
const protocol = 'http';
let hostname = window.location.host;
const colonIndex = hostname.indexOf(':');
if( colonIndex > 0 ){
  hostname = hostname.substring( 0, colonIndex );
}
const urlhost = `${protocol}://${hostname}:${port}`;

const envhost = process.env.REACT_APP_SOCKET_URL || process.env.REACT_APP_GAME_URL;
console.log('host',urlhost,envhost);
console.log(process.env);
const host = envhost ? envhost : urlhost;

console.log('connecting to', host);

let store;
export function setStore( s ){
  console.log('setStore');
  store = s;
  Action.observeStore( store, state => state.gameId, onConnectGame );
  Action.observeStore( store, state => state.isDisconnected, discon => console.log('Disconnected',discon));
}

let storeService;
export let gameDbService;
export let gameListService;
export let userListService;

export const featherMiddleware = store => next => action => {
  console.log('feather',action);
  switch( action.status ){

    // Requests are sent to the server
    case Action.Status.request:
      return storeService.patch( Action.gameId, action )
        .then( result => {
          console.log('patched', result);
          return result;
        });

    case Action.Status.del:
      return storeService.remove( action.gameId, action )
        .then( result => {
          console.log('removed', result);
          return result;
        });

    // Everything else is handled locally
    default: return next( action );
  }
};

function socketEvent( status, store, {info = null, err = null} ){
  let now = Date.now();
  console.log('socket status',now,status,info,err);
  store.dispatch(Action.socket( now, status, info, err ));
}

export async function init( s ){
  const socketOpts = {
    reconnect:{
      retries: 5
    }
  };

  if( process.env.REACT_APP_SOCKET_PATH ){
    console.log('init setting socket path', process.env.REACT_APP_SOCKET_PATH);
    socketOpts.path = process.env.REACT_APP_SOCKET_PATH;
  }

  // If necessary, set some information that we need to connect from our path
  let history = createBrowserHistory({ basename: process.env.REACT_APP_BASE_PATH });
  let location = history.location;

  let client = location.hash;
  console.log( 'init client', client );
  client = Action.normalizeClient( client );
  console.log( 'init normalClient', client, typeof client );
  if( !client ){
    client = Action.client;
    console.log('init client from Action.client', client, typeof client, Action.client, typeof Action.client );
  }

  if( client ){
    console.log('init setting client', client, typeof client);
    Action.setClient( client );
  }

  let gameId = sessionStorage.gameId;
  if( location.search ){
    let potentialGameId = location.search.substring(1);
    if( potentialGameId && potentialGameId.length === 6 && potentialGameId.indexOf('=') === -1){
      // Only set this as a gameId if it actually looks like a game ID
      // (it has only 6 characters and doesn't look like attribute=value
      gameId = potentialGameId
    }
  }
  if( gameId ){
    console.log('init setting gameid', gameId);
    s.dispatch(Action.setGameId( gameId ));
  }

  console.log('init socketOpts',JSON.stringify(socketOpts));
  const socket = new window.Primus(host,socketOpts);

  // Setup a way that connections add the clientId and gameId if appropriate.
  // See https://github.com/primus/primus/issues/386
  socket.on('outgoing::url', function(url){
    console.log('outgoing::url  in',url);

    let queryObj = {};
    let gameId = Action.gameId;
    if( gameId ){
      queryObj.gameId = gameId;
    }
    let clientId = Action.client;
    if( clientId ){
      queryObj.clientId = clientId;
    }
    url.query = queryString.stringify( queryObj );

    console.log('outgoing::url out',url);
  });

  socket.on('open',                ()         => socketEvent('open',                s, {}));
  socket.on('error',               (err)      => socketEvent('open failed',         s, {err}));
  socket.on('reconnect',           (info)     => socketEvent('reconnect',           s, {info}));
  socket.on('reconnect scheduled', (info)     => socketEvent('reconnect scheduled', s, {info}));
  socket.on('reconnected',         (info)     => socketEvent('reconnected',         s, {info}));
  socket.on('reconnect timeout',   (err,info) => socketEvent('reconnect timeout',   s, {err,info}));
  socket.on('reconnect failed',    (err,info) => socketEvent('reconnect failed',    s, {err,info}));
  socket.on('end',                 ()         => socketEvent('end',                 s, {}));

  const feathersApp = feathers();
  feathersApp.configure(primusClient(socket));

  // Setup authentication and attempt to login (in case we have stored credentials)
  await Auth.init( feathersApp, s );

  storeService = feathersApp.service('game');
  gameDbService = feathersApp.service('gameDbService');
  gameListService = feathersApp.service('gameListService');
  userListService = feathersApp.service('userListService');

  if( gameId ){
    console.log('init gameId', gameId);
    socket.on('connect', () => onConnectGame());
  }

  storeService.on('patched', update => onPatch(update));
  storeService.on('removed', update => onRemove(update));

  setStore( s );
}

export function onConnectGame(){
  console.log('socket connect', Action.gameId);
  if( !Action.gameId ){
    return;
  }

  storeService.get( Action.gameId )
    .then( image => {
      console.log( 'get', image );
      Action.setClient( image.client );
      store.dispatch( image );

      let history = createBrowserHistory({ basename: process.env.REACT_APP_BASE_PATH });
      let location = history.location;
      console.log('onConnectGame location', location);

      let display = pathDisplay( location.pathname );
      if( display ){
        console.log('onConnectGame display',display);
        let message = Action.displaySet( display );
        store.dispatch( message );
      }
    })
    .catch( err => {
      console.error( 'Problem with initial get', err );

      let history = sharedHistory;
      let location = history.location;
      console.log('history.location',location,Action.gameId);
      if( location.pathname === `/${Action.gameId}` ){
        history.push('/list');
      }

      let message = Action.setGameId('');
      store.dispatch( message );
    })
    .catch( err2 => {
      console.error( 'Problem in catch for initial get', JSON.stringify(err2,null,1) );
    });
}

async function onPatch( update ){
  console.log('received update', update);

  if( update.status === Action.Status.set || update.status === Action.Status.resend ){
    // Explicitly changing state
    let result = store.dispatch( update );
    console.log( 'dispatched', result );

    if( result.id &&
        (Action.client && (Action.client !== 'a')) &&
        (!update.client || update.client === Action.client) ){
      // There is a request for confirmation
      // We are a player client
      // Either this was sent to everyone, or was sent to just us
      // So we should reply
      let ack = {
        status: Action.Status.acknowledged,
        client: Action.client
      };
      let response = Object.assign( {}, result, ack );
      console.log( 'response', response );

      // Send the acknowledgement to the server
      storeService.patch( Action.gameId, response )
        .then( patchResult =>{
          console.log( 'acknowledged sent to server', patchResult );
        } );
    }

  } else if( update.status === Action.Status.acknowledged ){
    // The server is relaying an acknowledgement, so update the state
    store.dispatch( update );

  } else if( update.status === Action.Status.error ){
    // TODO: Handle error. Can't just dispatch it to store.

  } else {
    // We shouldn't have gotten this
  }
}

async function onRemove( update ){
  console.log('received reset', update);
  return await onPatch( update );
}
