import React from 'react';
import Popup from 'reactjs-popup';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import i18n from '../../i18n';

function TabbedPopup({ title, tabs, ...otherProps }) {
  // otherProps may contain any of the props for Popup from the reactjs-popup library
  return (
    <div className="ee-popup-wrapper">
      <Popup {...otherProps} modal>
        {close => (
          <div className="ee-popup ee-popup-tabbed">
            <button className="ee-popup-close-btn btn btn-link" onClick={close}>
              <span className="sr-only">{i18n.t('common.close')}</span>
            </button>
            <header>
              <div className="ee-popup-header"><span>{title}</span></div>
            </header>
            <Tabs>
              <TabList>
                {tabs.map((tab, index) => {
                  return (
                    <Tab key={`tab-${index}`}>{tab.title}</Tab>
                  );
                })}
              </TabList>
              {tabs.map((tab, index) => {
                return (
                  <TabPanel key={`tabpanel-${index}`} dangerouslySetInnerHTML={{__html: tab.content}} />
                );
              })}
            </Tabs>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default TabbedPopup;
