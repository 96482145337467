
import {myProps} from "./my";

import {createBrowserHistory} from 'history';

export const Type = {
  state:'state',
  reset:'reset',
  connected:'connected',
  disconnected:'disconnected',
  setScenario:'setScenario',
  setDemo:'setDemo',
  setSurvey:'setSurvey',
  changeScreen:'changeScreen',
  playAudio:'playAudio',
  tipRelease:'tipRelease',
  tipShow:'tipShow',
  tipHide:'tipHide',
  challengeRelease:'challengeRelease',
  challengeEnded:'challengeEnded',
  challengeShow:'challengeShow',
  challengeHide:'challengeHide',
  resourceUse:'resourceUse',
  resourceRelease:'resourceRelease',
  setDecision:'setDecision',
  setCompleted:'setCompleted',
  error:'error',
  setGameId:'setGameId', // Local
  setUser:'setUser',     // Local
  socket:'socket'        // Local
};

export const Status = {
  local:'local',
  del:'delete',
  request:'request',
  set:'set',
  resend:'resend',
  error:'error',
  acknowledged:'acknowledged'
};

export let client = 0;
export function setClient( newClient ){
  console.log('Action.setClient',newClient);
  client = normalizeClient( newClient );
  if( client ){
    const history = createBrowserHistory({ basename: process.env.REACT_APP_BASE_PATH });
    history.replace({
      hash: `#${client}`
    })
  }
}

export let gameId = '';
export function setGameId( newGameId ){
  console.log('Action.setGameId',newGameId);
  gameId = newGameId;
  /*
  if( gameId ){
    const history = createBrowserHistory({ basename: process.env.REACT_APP_BASE_PATH });
    history.replace({
      search: `?${gameId}`
    })
  }
  */
  return {
    type: Type.setGameId,
    status: Status.local,
    gameId: gameId
  };
}

export function setUser( user ){
  return {
    type:   Type.setUser,
    status: Status.local,
    user:   user
  }
}

export function socket( timestamp, event, info, err ){
  return {
    type:   Type.socket,
    status: Status.local,
    timestamp,
    event,
    info,
    err
  }
}

function reset(){
  gameId = '';
  return {
    type:   Type.reset,
    status: Status.local,
  };
}

export function normalizeClient( client ){
  // If this still has a hash in front of it, strip the hash
  if( typeof client === 'string' && client.startsWith('#') ){
    client = client.substring(1);
  }

  // If this is a number, even as a string, turn it back into a number
  if( client === '0' ){
    client = 0;
  } else if( typeof client === 'string' ){
    let iClient = parseInt( client, 10 );
    if( iClient ){
      client = iClient;
    }
  }
  return client;
}

let store = undefined;
export function setStore( currentStore ){
  store = currentStore;
}

export function observeStore(store, select, onChange) {
  let currentState;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}

function getState(){
  return store.getState();
}

export function mapStateToPropsUser( state ){
  let ret = {
    gameId:       state.gameId,
    userInfo:     state.userInfo,
    client:       client,
    scenario:     state.scenario,
    display:      state.display,
    audio:        state.audio,
    isDemo:       state.isDemo,
    player:       state.player,
    notification: state.notification,
    isDisconnected: state.isDisconnected,
    connection:   state.connection,
    my:           myProps( client, state )
  };
  if( client && client >= 1 && client <= 6 ){
    ret.me = state.player[client]
  }
  return ret;
}

export function buildAction( details ){
  let ret = Object.assign(
    {},
    {client: client},
    {status: Status.request},
    details
  );
  return ret;
}

export function setScenario( scenario ){
  return buildAction({
    type: Type.setScenario,
    scenario: scenario
  });
}

export function setDemo( isDemo ){
  return buildAction({
    type: Type.setDemo,
    isDemo: Boolean( isDemo )
  })
}

export function setSurvey( survey ){
  return buildAction({
    type: Type.setSurvey,
    survey: survey
  })
}

const maxScreen = [
  1, // Lobby
  3, // Prepare
  3, // Respond
  9, // Recover
  2, // Adapt
  1  // Credits
];

function boundedValue( value, minValue, maxValue ){
  if( typeof value === 'undefined' ){
    value = 0;
  } else if( typeof value === 'string' ){
    value = parseInt( value, 10 );
    if( !value ){
      // Possibly NaN
      value = 0;
    }
  }

  if( value < minValue ){
    value = minValue;
  } else if( value > maxValue ){
    value = maxValue;
  }
  return value;
}

export function displaySet( {part, screen} ){
  console.log('displaySet', part, screen);
  part   = boundedValue( part,   0, maxScreen.length-1 );
  screen = boundedValue( screen, 1, maxScreen[part] );

  return buildAction({
    type: Type.changeScreen,
    part,
    screen
  });
}

export function displayNext(){
  let { part, screen } = getState().display;

  screen++;

  if( screen > maxScreen[part] ){
    part++;
    screen = 1;
  }

  if( part >= maxScreen.length ){
    return buildAction({
      type: Type.error,
      status: Status.error,
      message: "Invalid part"
    });
  }

  return buildAction({
    type: Type.changeScreen,
    part,
    screen
  });
}

export function displayPrevious(){
  let { part, screen } = getState().display;

  screen--;

  if( screen === 0 ){
    part--;
    screen = maxScreen[part];
  }

  if( part < 0 ){
    return buildAction({
      type: Type.error,
      status: Status.error,
      message: "Invalid part"
    });
  }

  return buildAction({
    type: Type.changeScreen,
    part,
    screen
  });

}

export function playAudio( audio, clientOptional ){
  return buildAction({
    type: Type.playAudio,
    client: clientOptional ? clientOptional : 0,
    audio: audio
  });
}

export function stopAudio( clientOptional ){
  return playAudio('', clientOptional);
}

function tipRelease( tip, tipClientOptional ){
  let action = {
    type:   Type.tipRelease,
    status: Status.request,
    client: tipClientOptional ? tipClientOptional : 0,
    tip:    tip,
    id:     Date.now()
  };
  return action;
}

function tipShowHide( tip, show ){
  let type = show ? Type.tipShow : Type.tipHide;
  let action = {
    type:    type,
    status:  Status.request,
    client:  client,
    tip:     tip
  };
  return action;
}

function challengeRelease( challenge, challengeClientOptional ){
  let action = {
    type:   Type.challengeRelease,
    status: Status.request,
    client: challengeClientOptional ? challengeClientOptional : 0,
    challenge:    challenge,
    id:     Date.now()
  };
  return action;
}

function challengeEnded( challenge, challengeClientOptional ){
  let action = {
    type:   Type.challengeEnded,
    status: Status.request,
    client: challengeClientOptional ? challengeClientOptional : 0,
    challenge:    challenge
  };
  return action;

}

function challengeShowHide( challenge, show ){
  let type = show ? Type.challengeShow : Type.challengeHide;
  let action = {
    type:    type,
    status:  Status.request,
    client:  client,
    challenge:     challenge
  };
  return action;
}

function resourceUse( resourceName, use ){
  let type = use ? Type.resourceUse : Type.resourceRelease;
  let action = {
    type:     type,
    status:   Status.request,
    client:   client,
    resource: resourceName
  };
  return action;
}

function setDecision( decision, clientOptional ){
  clientOptional = normalizeClient( clientOptional );
  clientOptional = clientOptional ? clientOptional : client;
  let action = {
    type:     Type.setDecision,
    status:   Status.request,
    client:   clientOptional,
    decision: decision
  };
  return action;
}

function setCompleted( completed, client ){
  let action = {
    type:      Type.setCompleted,
    status:    Status.request,
    client:    client,
    completed: completed
  };
  return action;
}

function del( gameId ){
  let action = {
    type:   Type.state,
    status: Status.del,
    gameId: gameId
  };
  return action;
}


export function mapDispatchToProps( dispatch ){
  return {
    del:             (gameId) => dispatch(del(gameId)),

    setScenario:     (scenario) => dispatch(setScenario(scenario)),
    setDemo:         (isDemo) => dispatch(setDemo(isDemo)),
    setSurvey:       (survey) => dispatch(setSurvey(survey)),

    displaySet:      (display) => dispatch(displaySet(display)),
    displayNext:     () => dispatch(displayNext()),
    displayPrevious: () => dispatch(displayPrevious()),

    playAudio:       (audio, client) => dispatch(playAudio(audio, client)),
    stopAudio:       (client) => dispatch(stopAudio(client)),

    tipRelease:      (tip,tipClient) => dispatch(tipRelease(tip,tipClient)),
    tipShow:         (tip) => dispatch(tipShowHide(tip,true)),
    tipHide:         (tip) => dispatch(tipShowHide(tip,false)),

    challengeRelease: (challenge,challengeClient) => dispatch(challengeRelease(challenge,challengeClient)),
    challengeEnded:   (challenge,challengeClient) => dispatch(challengeEnded(challenge,challengeClient)),
    challengeShow:    (challenge) => dispatch(challengeShowHide(challenge,true)),
    challengeHide:    (challenge) => dispatch(challengeShowHide(challenge,false)),

    resourceUse:      (resourceName) => dispatch(resourceUse(resourceName,true)),
    resourceRelease:  (resourceName) => dispatch(resourceUse(resourceName,false)),

    setDecision:      (decision, clientOptional) => dispatch(setDecision(decision,clientOptional)),
    setCompleted:     (completed,client) => dispatch(setCompleted(completed,client)),

    setGameId:        (gameId) => dispatch(setGameId(gameId)),
    setUser:          (user) => dispatch(setUser(user)),
    reset:            () => dispatch(reset())
  }
}
