import React, {useState, useEffect, useRef} from 'react';
import {FullPageWrapper} from "../../components/Wrapper";
import Footer from "../../components/Footer";

import BootstrapTable from "react-bootstrap-table-next";

/**
 * Workaround for issues with Webpack 5
 * See https://github.com/react-bootstrap-table/react-bootstrap-table2/pull/1506
 * which, as of 25 Jan 2022, has not been incorporated into the main branch.
 * Once it is in the main branch, this workaround probably unnecessary.
 */
//import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';

import {NavLink} from "react-router-dom";
import queryString from "query-string";

import { DateTime } from "luxon";

import {gameListService,userListService} from "../../server";
import Header from "../../components/Header";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const periods = {
  // 'week-this': {
  //   label: 'This Week',
  //   unit: 'week',
  //   offset: 0
  // },
  // 'week-last': {
  //   label: 'Last Week',
  //   unit: 'week',
  //   offset: 1
  // },
  'month-this': {
    label: 'This month',
    unit: 'month',
    offset: 0
  },
  'month-last': {
    label: 'Last Month',
    unit: 'month',
    offset: 1
  },
  // 'quarter-this': {
  //   label: 'This Quarter',
  //   unit: 'quarter',
  //   offset: 0
  // },
  // 'quarter-last': {
  //   label: 'Last Quarter',
  //   unit: 'quarter',
  //   offset: 1
  // },
  'year-this': {
    label: 'This Year',
    unit: 'year',
    offset: 0
  },
  'year-last': {
    label: 'Last Year',
    unit: 'year',
    offset: 1
  },
};

function periodUnit( period ){
  return (period && period.unit)   || 'day';
}

function dateOffset( period ){
  const offset = (period && period.offset) || 0;
  const unit   = periodUnit( period );
  return DateTime.local().minus({[unit]:offset});
}

function periodBegin( period ){
  const unit   = periodUnit( period );
  return dateOffset( period ).startOf( unit );
}

/**
 * End of the period or end of today, whichever comes first.
 * @param period
 * @return {DateTime}
 */
function periodEnd( period ){
  const unit   = periodUnit( period );
  const pEnd = dateOffset( period ).endOf( unit );
  const todayEnd = DateTime.local().endOf('day');
  return pEnd < todayEnd ? pEnd : todayEnd;
}

function periodWhenInfo( when ){
  const period = periods[when] || periods['month-this'];
  const beginDate = periodBegin( period );
  const endDate = periodEnd( period );
  const ret = {
    begin:    beginDate,
    end:      endDate,
    filename: customFilename( beginDate, endDate ),
    when,
    ...period
  }
  return ret;
}

function customFilename( begin, end ){
  return `${begin.toISODate()}--${end.toISODate()}`;
}

function customWhenInfo( queryParameters ){
  const begin = queryParameters.begin;
  const end   = queryParameters.end;
  const beginDate = (begin && DateTime.fromISO(begin).startOf('day')) || DateTime.local();
  const endDate   = (end && DateTime.fromISO(end).endOf('day')) || DateTime.local();
  const ret = {
    begin:    beginDate,
    end:      endDate,
    filename: customFilename( beginDate, endDate ),
    when: 'custom'
  }
  return ret;
}

function makeWhenInfo( queryParameters ){
  const when = queryParameters.when || 'month-this';
  if( when === 'custom' && queryParameters.begin && queryParameters.end ){
    return customWhenInfo( queryParameters );
  } else {
    return periodWhenInfo( when );
  }
}

function CustomDateButton({whenInfo,setDateRange,...props}){
  const calendar = useRef(null);
  const [start,setStart] = useState(whenInfo.begin.toJSDate());
  const [end,setEnd]     = useState(whenInfo.end.toJSDate());

  function onChange( dates ){
    const [start,end] = dates;
    setStart(start);
    setEnd(end);
    if( start && end ){
      calendar.current.setOpen( false );
      setDateRange(
        DateTime.fromJSDate(start).toISODate(),
        DateTime.fromJSDate(end).toISODate()
      );
    }
  }

  const CustomButton = ({ onClick, ...props }) => {
    return(<button
        className={`btn btn-link ${whenInfo.when === "custom" ? "ee-when-btn-active" : "ee-when-btn"}`}
        onClick={onClick}
      >
        Custom
      </button>
    )
  };

  return (
    <DatePicker
      ref={calendar}
      selected={start}
      startDate={start}
      endDate={end}
      onChange={onChange}
      customInput={<CustomButton />}
      shouldCloseOnSelect={false}
      selectsRange
    />
  );
}

function TableExportButton(props){
  function handleClick(){
    props.onExport();
  }

  return (
    <button className="btn ee-when-btn-download float-right" onClick={handleClick}>Download</button>
  );
}

export function DateRangeData({header,namePrefix,service,columns,location,history,...props}){
  const queryParameters = location ? queryString.parse(location.search) : {};
  const whenInfo = makeWhenInfo( queryParameters );

  const [results,setResults] = useState([]);

  function setDateRange( begin, end ){
    const newLocation = {
      ...location,
      search: `?when=custom&begin=${begin}&end=${end}`
    }
    history.push( newLocation );
  }

  useEffect( () => {

    function loadResults(){
      const begin = whenInfo.begin.toMillis();
      const end   = whenInfo.end.toMillis();
      return service.find({
        query: {
          begin,
          end
        }
      });
    }

    async function onLoad(){
      try{
        if( !whenInfo ){
          return;
        }
        const results = await loadResults();
        if( results ){
          setResults( results );
        }
      } catch( e ){
        console.error( e );
      }
    }

    onLoad();
  }, [whenInfo.filename]);

  return (
    <FullPageWrapper withBackground withHeader withFooter>
      <Header />
      <main className="ee-list-games-view ee-game-data">
        <header className="ee-list-games-header">
          <h1>{header}</h1>
          {
            whenInfo &&
            <h1>
              {whenInfo.begin.toLocaleString(DateTime.DATE_MED)}
              &nbsp;-&nbsp;
              {whenInfo.end.toLocaleString(DateTime.DATE_MED)}
            </h1>
          }
        </header>
        <ToolkitProvider
          bootstrap4
          data={results}
          keyField="id"
          columns={columns}
          exportCSV={{
            fileName: `${namePrefix}-${whenInfo.filename}.csv`
          }}
        >
          {
            props =>
              <div>
                <div className="ee-when">
                  {
                    Object.keys( periods ).map( period => {
                      return (
                        <NavLink
                          to={`?when=${period}`}
                          className={`btn btn-link ${whenInfo.when===period?"ee-when-btn-active":"ee-when-btn"}`}
                          activeClassName="active"
                          key={period}
                        >
                          {periods[period].label}
                        </NavLink>
                      )
                    })
                  }
                  <CustomDateButton
                    whenInfo={whenInfo}
                    setDateRange={setDateRange}
                  />
                  <TableExportButton { ...props.csvProps }/>
                </div>
                <div>
                  {
                    results &&
                    <section className="ee-game-list">
                      <BootstrapTable {...props.baseProps} />
                    </section>
                  }
                </div>
              </div>
          }
        </ToolkitProvider>
      </main>
      <Footer/>
    </FullPageWrapper>
  )
}

function dateFormatter( val ){
  const date = DateTime.fromMillis( val );
  return date.toLocaleString( DateTime.DATETIME_MED_WITH_WEEKDAY );
}

function dateCsvFormatter( val ){
  const date = DateTime.fromMillis( val );
  return date.toISO()
}

function orgFormatter( row, field ){
  return (row.survey && row.survey[field]) ||
    (row.user[field]) ||
    '';
}

export function GameData({location,history,...props}){
  const columns = [
    {
      text:      "Name",
      dataField: "name",
      formatter:    (cel,row) => `${row.user.firstName} ${row.user.lastName}`,
      csvExport: false
    },
    {
      text: "First Name",
      dataField: "user.firstName",
      hidden: true,
      csvExport: true
    },
    {
      text: "Last Name",
      dataField: "user.lastName",
      hidden: true,
      csvExport: true
    },
    {
      text: "Email",
      dataField: "user.email",
      hidden: true,
      csvExport: true
    },
    {
      text: "Phone",
      dataField: "user.phoneNumber",
      hidden: true,
      csvExport: true
    },
    {
      text: "Organization",
      dataField: "orgName",
      formatter:    (cell,row) => orgFormatter(row, 'orgName'),
      csvFormatter: (cell,row) => orgFormatter(row, 'orgName'),
    },
    {
      text: "Organization Type",
      dataField: "orgType",
      formatter:    (cell,row) => orgFormatter(row,'orgType'),
      csvFormatter: (cell,row) => orgFormatter(row,'orgType'),
      hidden: true,
      csvExport: true
    },
    {
      text: "Organization Type (Other)",
      dataField: "orgTypeOther",
      formatter:    (cell,row) => orgFormatter(row,'orgTypeOther'),
      csvFormatter: (cell,row) => orgFormatter(row,'orgTypeOther'),
      hidden: true,
      csvExport: true
    },
    {
      text:      "Scenario",
      dataField: "scenario",
      formatter:    (cell,row) => `${row.scenario}${row.isDemo?' (Demo)':''}`,
      csvFormatter: (cell,row) => `${row.scenario}${row.isDemo?' (Demo)':''}`
    },
    {
      text:      "Updated",
      dataField: "updated",
      formatter:    (cell,row) => dateFormatter(row.updated),
      csvFormatter: (cell,row) => dateCsvFormatter(row.updated)
    },
    {
      text: "Completed",
      dataField: 'scene.part',
      formatter:     (cell,row) => (row.scene && row.scene.part >= 4) ? 'Yes' : 'No',
      csvFormatter:  (cell,row) => (row.scene && row.scene.part >= 4) ? 'Yes' : 'No',
    }
  ];
  return(
    <DateRangeData
      header="Game Data"
      namePrefix="dgp-games"
      service={gameListService}
      columns={columns}
      location={location}
      history={history}
      />
  )
}

export function UserData({location,history,...props}){
  const columns=[
    {
      text:      "Name",
      dataField: "name",
      formatter:    (cel,row) => `${row.firstName} ${row.lastName}`,
      csvExport: false
    },
    {
      text: "First Name",
      dataField: "firstName",
      hidden: true,
      csvExport: true
    },
    {
      text: "Last Name",
      dataField: "lastName",
      hidden: true,
      csvExport: true
    },
    {
      text: "Email",
      dataField: "email",
    },
    {
      text: "Phone",
      dataField: "phoneNumber",
    },
    {
      text: "Organization",
      dataField: "orgName",
    },
    {
      text: "Organization Type",
      dataField: "orgType",
    },
    {
      text: "Organization Type (Other)",
      dataField: "orgTypeOther",
    },
    {
      text:      "Updated",
      dataField: "updated",
      formatter:    (cell,row) => dateFormatter(row.updated),
      csvFormatter: (cell,row) => dateCsvFormatter(row.updated)
    },

  ];
  return(
    <DateRangeData
      header="Users Updated"
      namePrefix="dgp-users"
      service={userListService}
      columns={columns}
      location={location}
      history={history}
    />
  )
}
