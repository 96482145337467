import React, {useState,useEffect} from 'react';
import {mapStateToPropsUser,mapDispatchToProps} from '../../action';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import HeaderAlternate from '../../components/HeaderAlternate';
import ConfirmPopup from '../../components/ConfirmPopup';
import { FullPageWrapper } from '../../components/Wrapper';
import i18n from '../../i18n';
import { getConnectedPlayerCount, getPlayers } from '../../utils';
import Footer from '../../components/Footer';

import { scenario as allScenarios } from '../../scenario';
import Select from "react-select";

class Lobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedScenario: null,
      showTestInstructions: true,
      testPlayerOpened: false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.handleClickCancelConfirm = this.handleClickCancelConfirm.bind(this);
    this.handleClickContinue = this.handleClickContinue.bind(this);
    this.handleClickScenario = this.handleClickScenario.bind(this);
    this.handleClickTestContinue = this.handleClickTestContinue.bind(this);
    this.handleClickOpenTestPlayer = this.handleClickOpenTestPlayer.bind(this);
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  handleClickCancel() {
    const { scenario, isDemo } = this.props;
    if (scenario && !isDemo) {
      this.handleOpenModal();
    } else {
      this.props.reset();
    }
  }

  handleClickCancelConfirm() {
    this.setState({ selectedScenario: null });
    this.handleCloseModal();
    this.props.reset();
  }

  handleClickContinue() {
    const { scenario } = this.props;
    const selectedScenario = this.state.selectedScenario;
    if (!scenario) {
      this.props.setScenario(selectedScenario);
    } else {
      this.props.displayNext();
    }
  }

  handleClickScenario(scenario) {
    this.setState({ selectedScenario: scenario });
  }

  handleClickTestContinue() {
    this.setState({ showTestInstructions: false });
  }

  handleClickOpenTestPlayer() {
    this.setState({ testPlayerOpened: true });
  }

  render() {
    const { scenario, isDemo, userInfo, player, gameId, setSurvey } = this.props;
    const title = (isDemo ? i18n.t('startGameScreen.testAGame') : i18n.t('startGameScreen.startNewGame'));
    const gameProps = {
      player,
      gameId,
      onClickContinue: this.handleClickContinue,
      userInfo,
      setSurvey
    };
    const confirmCancelProps = {
      title: i18n.t('startGameModal.confirmCancelTitle'),
      content: i18n.t('startGameModal.confirmCancelDescription'),
      button: i18n.t('startGameModal.confirmCancel')
    };
    let view = null;
    if (scenario) {
      if (isDemo) {
        if (this.state.showTestInstructions) {
          view = <TestGameInstructions onClickContinue={this.handleClickTestContinue} />;
        } else {
          view = <TestGameLobby {...gameProps} onClickPlayer={this.handleClickOpenTestPlayer} />;
        }
      } else {
        view = <StartGameLobby {...gameProps} />;
      }
    } else {
      view =
        <ChooseScenario
          name={userInfo.firstName}
          selectedScenario={this.state.selectedScenario}
          onClickScenario={this.handleClickScenario}
          onClickContinue={this.handleClickContinue}
        />;
    }
    return (
      <FullPageWrapper withBackground withAlternateHeader withFooter>
        <HeaderAlternate title={title} onClickCancel={this.handleClickCancel} />
        {view}
        <Footer />
        {!isDemo ? (
          <ConfirmPopup
            isOpen={this.state.isModalOpen}
            onClose={this.handleCloseModal}
            onConfirm={this.handleClickCancelConfirm}
            {...confirmCancelProps}
          />
        ) : null}
      </FullPageWrapper>
    );
  }
}

function ChooseScenario({ name, selectedScenario, onClickScenario, onClickContinue }) {
  return (
    <main className="ee-choose-scenario-view">
      <header>
        <h1>{i18n.t('common.hi')} {name}! {i18n.t('moduleSelect.scenarioSelect')}</h1>
      </header>
      <section className="ee-scenario-list text-center">
        {Object.keys(allScenarios).map((scenario, index) => {
          const isSelected = (scenario === selectedScenario);
          const selectedClassName = (isSelected ? `ee-scenario-selected ee-scenario-${scenario}-selected` : '');
          return (
            <div
              className={`ee-scenario ee-scenario-${scenario} ${selectedClassName}`}
              onClick={() => onClickScenario(scenario)}
              key={`scenario-${index}`}
            >
              {i18n.t(`gameType.${scenario}`)}
            </div>
          );
        })}
        <button
          className="ee-continue-btn btn btn-link"
          disabled={selectedScenario === null}
          onClick={onClickContinue}
        >
          {i18n.t('startGameScreen.startGameButton')}
        </button>
      </section>
    </main>
  );
}

function TestGameInstructions({ onClickContinue }) {
  return (
    <main className="ee-game-lobby-test-view">
      <header>
        <h1>{i18n.t('testGameScreen.testTheGameTitle')}</h1>
      </header>
      <p>{i18n.t('testGameScreen.testTheGameInstructions')}</p>
      <img src={require('../../assets/images/test-game-monitors.png')} alt="" />
      <button className="ee-continue-btn btn btn-link" onClick={() => onClickContinue()}>
        {i18n.t('common.continue')}
      </button>
    </main>
  );
}

function TestGameLobby({ player, gameId, onClickPlayer, onClickContinue }) {
  const players = getPlayers(player);
  const playerUrl = `${process.env.REACT_APP_PLAYER_BASE_URL}/${gameId}`;
  const enableContinue = players[0].connected;
  return (
    <main className="ee-game-lobby-test-view">
      <header>
        <h1>{i18n.t('startTestGameScreen.startTestGameTitle')}</h1>
      </header>
      <section className="ee-game-steps">
        <GameStep
          number={i18n.t('startGameScreen.stepCountOne')}
          help={i18n.t('testGameScreen.testGameLinkHelp')}
        >
          <a
            href={playerUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="ee-player-btn btn btn-link"
            onClick={() => onClickPlayer()}
          >
            {playerUrl}
          </a>
        </GameStep>
        <GameStep
          number={i18n.t('startGameScreen.stepCountTwo')}
        >
          <button
            disabled={!enableContinue}
            className="ee-start-btn btn btn-link"
            onClick={() => onClickContinue()}
          >
            {i18n.t('startGameScreen.startYourTestGame')}
          </button>
        </GameStep>
      </section>
    </main>
  );
}

function StartGameLobby({ player, gameId, onClickContinue, userInfo, setSurvey }) {
  const players = getPlayers(player);
  const connectedCount = getConnectedPlayerCount(players);
  const areClientsConnected = (players.length === connectedCount);
  const gameUrl = `${process.env.REACT_APP_PLAYER_BASE_URL}/${gameId}`;

  console.log('userInfo',userInfo);

  const [state,setState] = useState({
    count: '',
    orgName: (userInfo && userInfo.orgName) || '',
    orgType: (userInfo && userInfo.orgType) || '',
    orgTypeOther: (userInfo && userInfo.orgTypeOther) || ''
  });
  const isStateReady =
    state.count &&
    state.orgName &&
    state.orgType &&
    (state.orgType === 'Other' ? state.orgTypeOther : true);
  const isGameReady = areClientsConnected && isStateReady;
  console.log({areClientsConnected,isStateReady,isGameReady});

  const orgTypes = i18n.t('account.orgTypes',{returnObjects:true});

  function orgTypeObject( orgType ){
    if( orgType ){
      return {
        label: orgType,
        value: orgType,
        target: {
          name: "orgType",
          value: orgType
        }
      }
    }
  }

  function handleChangeInput(event) {
    console.log(event);
    const name = event.target.name;
    let value = event.target.value;
    if( name === 'count' && typeof value === 'string' && value.length > 0 ){
      value = parseInt( value, 10 );
    }
    setState({ ...state, [name]: value });
  }

  useEffect( () => {
    console.log('state',state);
    setSurvey( state );
  }, [state,setSurvey]);

  return (
    <main className="ee-game-lobby-view">
      <header>
        <h1>{i18n.t('startGameScreen.okGameReady')}</h1>
      </header>
      <section className="ee-game-steps">
        <GameStep
          number={i18n.t('startGameScreen.stepCountOne')}
          caption={i18n.t('startGameScreen.yourGameLink')}
          help={i18n.t('startGameScreen.gameCodeHelp')}
        >
          <div className="ee-game-link">
            <span className="ee-url">{gameUrl}</span>
            <CopyToClipboard text={gameUrl}>
              <button className="btn btn-link">Copy</button>
            </CopyToClipboard>
          </div>
        </GameStep>
        <GameStep
          number={i18n.t('startGameScreen.stepCountTwo')}
          caption={i18n.t('startGameScreen.connectedPlayerDevices')}
          help={i18n.t('startGameScreen.connectedDevicesHelp')}
        >
          <ul className="ee-participant-list">
            {players.map((player, index) => {
              const isConnected = player.connected;
              const connectedClassName = (isConnected ? 'ee-participant-connected' : '');
              return (
                <li
                  className={`ee-participant ee-participant-${index + 1} ${connectedClassName}`}
                  key={`connected-device-${index}`}
                >
                  <span className="sr-only">{isConnected ? 'waiting' : 'empty'}</span>
                </li>
              );
            })}
          </ul>
          <div className="ee-connected-participants">
            <strong className="ee-connected-participant-count">{connectedCount}</strong> {}
            <span dangerouslySetInnerHTML={{__html: i18n.t('startGameScreen.connectedDevices')}} />
          </div>
        </GameStep>
        <GameStep
          number={i18n.t('startGameScreen.stepCountThree')}
          caption={i18n.t('startGameScreen.surveyCaption')}
          help={i18n.t('startGameScreen.surveyHelp')}
        >
          <form className="ee-form">
            <div className="ee-form-body">
              <fieldset className="mb-0">
                <div className="ee-form-row">
                  <label htmlFor="lobby-count" className="sr-only">{i18n.t('startGameScreen.surveyCountPlaceholder')}</label>
                  <input
                    type="text"
                    id="lobby-count"
                    name="count"
                    value={state.count}
                    placeholder={i18n.t('startGameScreen.surveyCountPlaceholder')}
                    required
                    style={{width: "33%"}}
                    //className={this.hasFieldError('lobbyCount', errors) ? `${errorClassName}` : ''}
                    onChange={handleChangeInput}
                    //onBlur={this.handleBlurInput}
                  />
                  <label htmlFor="lobby-org-name" className="sr-only">{i18n.t('account.orgNamePlaceholder')}</label>
                  <input
                    type="text"
                    id="lobby-org-name"
                    name="orgName"
                    value={state.orgName}
                    placeholder={i18n.t('account.orgNamePlaceholder')}
                    required
                    //className={this.hasFieldError('orgName', errors) ? `${errorClassName}` : ''}
                    onChange={handleChangeInput}
                    //onBlur={this.handleBlurInput}
                  />
                </div>
                <label htmlFor="lobby-org-type-select" className="sr-only">{i18n.t('startGameScreen.orgTypePlaceholder')}</label>
                <Select
                  id="lobby-org-type-select"
                  name="lobbyOrgType"
                  value={orgTypeObject( state.orgType )}
                  placeholder={i18n.t('account.orgTypePlaceholder')}
                  required
                  //className={this.hasFieldError('orgType', errors) ? `ee-form-select ${errorClassName}` : 'ee-form-select'}
                  className="ee-form-select"
                  classNamePrefix="ee-form-select-"
                  onChange={handleChangeInput}
                  //onBlur={this.handleBlurInput}
                  options={orgTypes.map( orgType => orgTypeObject( orgType ) )}
                />
                {
                  (state.orgType === orgTypes[orgTypes.length-1]) &&
                  <div>
                    <label htmlFor="org-type-other" className="sr-only">{i18n.t('account.orgTypeOtherPlaceholder')}</label>
                    <input
                      type="text"
                      id="lobby-org-type-other"
                      name="orgTypeOther"
                      value={state.orgTypeOther}
                      placeholder={i18n.t('account.orgTypeOtherPlaceholder')}
                      required
                      //className={hasFieldError('orgTypeOther', errors) ? `${errorClassName}` : ''}
                      onChange={handleChangeInput}
                      //onBlur={this.handleBlurInput}
                    />
                  </div>
                }
              </fieldset>
            </div>
          </form>
        </GameStep>
        <button
          className="ee-continue-btn btn btn-link"
          disabled={!isGameReady}
          onClick={onClickContinue}
        >
          {i18n.t('startGameScreen.startGameButton')}
        </button>
      </section>
    </main>
  );
}

function GameStep({ number, caption, help, children }) {
  return (
    <div className="ee-game-step">
      <div className="ee-step-number">{number}</div>
      <div className="ee-step-caption">{caption}</div>
      <div className="ee-step-data">{children}</div>
      <div className="ee-step-help">{help}</div>
    </div>
  );
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(Lobby);
