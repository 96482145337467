import { combineReducers } from 'redux';

import {userInfo}     from './userInfo';
import {gameId}       from './gameId';
import {scenario}     from './scenario';
import {display}      from './display';
import {isDemo}       from './isDemo';
import {player}       from './player';
import {notification} from './notification';
import {connection,isDisconnected} from './connection';
import {survey}       from "./survey";

import * as Action from "../action";

let isLocal = true;
export function setLocal( local = true ){
  isLocal = local;
}

function supplementScenario( state, action ){
  let scenario = state.scenario;
  return {
    ...action,
    scenario
  };
}

function supplement( state, action ){
  switch( action.type ){
    case Action.Type.resourceUse:
    case Action.Type.resourceRelease:
    case Action.Type.setDecision:
    case Action.Type.challengeRelease:
      return supplementScenario( state, action );
    default: return action;
  }
}

const combiner = combineReducers({
  gameId,
  userInfo,
  connection,
  isDisconnected,
  scenario,
  display,
  isDemo,
  player,
  survey,
  notification
});

const combinerServer = combineReducers({
  scenario,
  display,
  isDemo,
  player,
  survey,
  notification
});

export function reducer( state, action ){

  // Don't process this if this is a local-only action,
  // but we are on the server.
  if( !isLocal && action.status === Action.Status.local){
    return state;
  }

  action = supplement( state, action );
  let ret = isLocal
    ? combiner( state, action )
    : combinerServer( state, action );
  //console.log('reducer',ret, action);
  return ret;
}

export default reducer;