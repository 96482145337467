import {Type} from '../action';

const defaultState = '';

export function scenario( state = defaultState, action ){
  switch( action.type ){
    case Type.state:        return action.state.scenario;
    case Type.setScenario:  return action.scenario;
    case Type.reset:        return defaultState;
    default:                return state;
  }
}
