import React from 'react';
import GoogleAnalytics from 'react-ga';

import { isUserLoggedIn } from './utils';

const gaCodes = process.env.REACT_APP_GA_CODE.split(',');
const trackersConfig = gaCodes.map( (id, index) => {
  const name = `tracker${index}`;
  return {
    trackingId: id,
    gaOptions: {
      name: name
    }
  }
});
const trackers = trackersConfig.map( conf => conf.gaOptions.name );
console.log({trackers,trackersConfig});
GoogleAnalytics.initialize(trackersConfig);

const pageTitles = {
  '/play/0/1': '%ROLE% | %TYPE% Game Lobby',
  '/play/0/2': '%SCENARIO% | %ROLE% | %TYPE% Game Lobby',
  '/play/1/[1-3]': '%SCENARIO% | %ROLE% | %TYPE% Game Prepare %SCREEN%',
  '/play/2/[1-3]': '%SCENARIO% | %ROLE% | %TYPE% Game Respond %SCREEN%',
  '/play/3/[1-9]': '%SCENARIO% | %ROLE% | %TYPE% Game Recover %SCREEN%',
  '/play/4/[1-2]': '%SCENARIO% | %ROLE% | %TYPE% Game Adapt %SCREEN%',
  '/play/5/1': '%SCENARIO% | %ROLE% | %TYPE% Game Credits %SCREEN%',
  '/create/new': 'Facilitator | Create Game',
  '/create/test': 'Facilitator | Create Test Game',
  '/join': 'Player | Join Game',
  '/[A-Za-z0-9]{6}': 'Player | Join Game',
  '/list': 'Facilitator | List Games',
  '/account/login(.*)?': 'Facilitator | Login',
  '/account/create': 'Facilitator | Create Account',
  '/account/edit': 'Facilitator | Edit Account',
  '/account/changePassword': 'Facilitator | Change Password',
  '/account/resetPassword': 'Facilitator | Reset Password',
  '/': 'Home'
};

function getPageTitle(path, titleVars) {
  let title = document.title;
  if (!path) return title;
  const patternKey = Object.keys(pageTitles).find(pathPattern => path.match(new RegExp(`^${pathPattern}$`)));
  if (patternKey) {
    title = `${title} | ${pageTitles[patternKey]}`;
    if (titleVars) {
      Object.keys(titleVars).map(titleVar => {
        const titleVal = titleVars[titleVar];
        title = title.replace(`%${titleVar}%`, titleVal);
        return null;
      });
      // Get rid of any double spaces caused by no TYPE variable.
      title = title.replace(/  +/g, ' ');
    }
  }
  return title;
}

const withTracker = (WrappedComponent) => {
  const trackPage = (page, props) => {
    const { scenario, client, gameId, isDemo, display: { screen }, userInfo } = props;
    const isLoggedIn = isUserLoggedIn(userInfo);
    let titleVars = {};
    let options = {};
    if (gameId) {
      options.dimension1 = gameId.toUpperCase();
    }
    if (scenario) {
      options.dimension2 = scenario;
      titleVars['SCENARIO'] = scenario.charAt(0).toUpperCase() + scenario.slice(1);
    }
    options.dimension3 = isDemo.toString();
    titleVars['TYPE'] = (isDemo ? 'Test' : '');
    if (client) {
      options.dimension4 = client.toString();
    }
    if (client || isLoggedIn) {
      let role = 'Player';
      if (client === 'a' || isLoggedIn) {
        role = 'Facilitator';
      }
      titleVars['ROLE'] = role;
    }
    if (screen) {
      titleVars['SCREEN'] = screen;
    }
    options.title = getPageTitle(page, titleVars);
    GoogleAnalytics.set({
      page,
      ...options,
    }, trackers);
    GoogleAnalytics.pageview(page,trackers);
  };

  const HOC = class extends React.Component {
    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search;
      const nextPage = this.props.location.pathname + this.props.location.search;
      // Treat the pending flag clearing as if it was componentDidMount and always
      // log it. We don't want to log on componentDidMount because some variables we
      // need are not available yet. Otherwise, only log it if we're changing paths
      // (no logging re-renders).
      if ((!this.props.userInfo.pending && prevProps.userInfo.pending) || (currentPage !== nextPage)) {
        trackPage(nextPage, this.props);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
