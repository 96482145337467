import React from 'react';

export function FullPageWrapper({ withBackground, withDoubleBackground, withHeader, withAlternateHeader, withFooter, children }) {
  const withHeaderClassName = (withHeader ? 'ee-page-with-header' : '');
  const withAlternateHeaderClassName = (withAlternateHeader ? 'ee-page-with-alternate-header' : '');
  const withFooterClassName = (withFooter ? 'ee-page-with-footer' : '');
  if (withDoubleBackground) {
    return (
      <div className={`ee-full-page-background ${withHeaderClassName} ${withAlternateHeaderClassName} ${withFooterClassName}`}>
        <div className="ee-full-page-double-background">
          {children}
        </div>
      </div>
    );
  } else if (withBackground) {
    return (
      <div className={`ee-full-page-background ${withHeaderClassName} ${withAlternateHeaderClassName} ${withFooterClassName}`}>
        {children}
      </div>
    );
  }
  return (
    <div className={`${withHeaderClassName} ${withAlternateHeaderClassName} ${withFooterClassName}`}>
      {children}
    </div>
  );
}

export function BoxedPageWrapper({ children }) {
  return (
    <div className="ee-boxed-content-page">
      <main className="ee-boxed-content">
        {children}
      </main>
    </div>
  );
}
