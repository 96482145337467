import React from 'react';

import i18n from '../../i18n';

function PhaseDialogHeader({ classFragment, onClickBack, onClickClose, caption }) {
  return (
    <header className={`ee-phase-dialog-header ee-phase-dialog-header-${classFragment}`}>
      {onClickBack ? (
        <button className="ee-phase-dialog-back-btn btn btn-link" onClick={onClickBack}>
          <span className="sr-only">{i18n.t('common.back')}</span>
        </button>
      ) : null}
      <button className="ee-phase-dialog-cancel-btn btn btn-link" onClick={onClickClose}>
        <span className="sr-only">{i18n.t('common.cancel')}</span>
      </button>
      <span className="ee-player-type-logo">
        <span className="sr-only">{caption}</span>
      </span>
    </header>
  );
}

export default PhaseDialogHeader;
