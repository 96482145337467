import React from 'react';
import PropTypes from 'prop-types';
import { TimelineLite, Power0 } from 'gsap';

class EventAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.modalTween = new TimelineLite({paused: true});
    this.rainElement = null;
    this.lightning1Element = null;
    this.lightning2Element = null;
  }

  componentDidMount() {
    if (this.rainElement && this.lightning1Element && this.lightning2Element) {
      this.modalTween
        .from(this.lightning1Element, 0.5, { opacity: 1, repeat: -1, repeatDelay: 10, ease: Power0.easeIn })
        .from(this.lightning2Element, 0.5, { opacity: 1, repeat: -1, repeatDelay: 14, ease: Power0.easeIn })
        .to(this.rainElement, 1.5, { backgroundPosition: "800px 3072px", repeat: -1, ease: Power0.easeIn })
        .play();
    }
  }

  render() {
    const { scenario } = this.props;
    let extraAnimations = null;
    if (scenario === 'hurricane' || scenario === 'flood') {
      extraAnimations =
        <React.Fragment>
          <div className="ee-event-animation-rain" ref={div => this.rainElement = div} />
          <div
            className="ee-event-animation-lightning ee-event-animation-lightning-1"
            ref={div => this.lightning1Element = div}
          />
          <div
            className="ee-event-animation-lightning ee-event-animation-lightning-2"
            ref={div => this.lightning2Element = div}
          />
        </React.Fragment>;
    }
    return (
      <div className="ee-event-animation">
        <div className="ee-event-animation-vignette"></div>
        {extraAnimations}
      </div>
    );
  }
}

EventAnimation.propTypes = {
  scenario: PropTypes.string.isRequired
};

export default EventAnimation;
