import React from 'react';
import { Link } from 'react-router-dom';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';

import Footer from '../../components/Footer';
import { FullPageWrapper } from '../../components/Wrapper';
import i18n from '../../i18n';

class Credits extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickLogo = this.handleClickLogo.bind(this);
  }

  handleClickLogo() {
    this.props.reset();
  }

  render() {
    return (
      <FullPageWrapper withBackground>
        <main className="ee-thankyou-view">
          <header className="ee-header">
            <h1 dangerouslySetInnerHTML={{__html: i18n.t('thankYouScreen.title')}} />
            <div className="ee-logo">
              <Link to="/list" onClick={this.handleClickLogo}>
                <span className="sr-only">{i18n.t('global.title')}</span>
              </Link>
            </div>
          </header>
          <section className="ee-thankyou">
            <header>
              <h2 dangerouslySetInnerHTML={{__html: i18n.t('thankYouScreen.sponsorHeading')}} />
            </header>
          </section>
          <Footer />
        </main>
      </FullPageWrapper>
    );
  }
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(Credits);
