import React from 'react';
import { Link } from 'react-router-dom';

import { FullPageWrapper } from '../components/Wrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import i18n from '../i18n';

class Home extends React.Component {
  render() {
    return (
      <FullPageWrapper withBackground withHeader withFooter>
        <Header />
        <main className="ee-home-view">
          <header>
            <h1>{i18n.t('gamePortal.title')}</h1>
          </header>
          <section className="ee-game-options">
            <div className="ee-game-option">
              <h2 className="ee-option-title ee-option-title-start">{i18n.t('gamePortal.startAGame')}</h2>
              <div className="ee-divider" />
              <p>{i18n.t('gamePortal.startGameDescription')}</p>
              <div className="ee-option-action">
                <Link to="/account/login?next=/create/new" className="ee-start-btn btn btn-link">
                  {i18n.t('gamePortal.startAGame')}
                </Link>
              </div>
            </div>
            <div className="ee-game-option">
              <h2 className="ee-option-title ee-option-title-test">{i18n.t('gamePortal.testAGame')}</h2>
              <div className="ee-divider" />
              <p>{i18n.t('gamePortal.testGameDescription')}</p>
              <div className="ee-option-action">
                <Link to="/account/login?next=/create/test" className="ee-test-btn btn btn-link">
                  {i18n.t('gamePortal.testAGame')}
                </Link>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </FullPageWrapper>
    )
  }
}

export default Home;
