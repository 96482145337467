import React from 'react';
import { mapStateToPropsUser } from '../../action';
import { resetPassword } from "../../action/auth";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import i18n from '../../i18n';
import { FullPageWrapper } from '../../components/Wrapper';
import Footer from '../../components/Footer';
import { history } from '../../History';


const queryString = require('query-string');

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    const {username, resetToken} = queryString.parse(window.location.search);
    this.state = {
      password: '',
      confirmPassword: '',
      touched: {
        password: false,
        confirmPassword: false
      },
      username,
      resetToken
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleBlurInput = this.handleBlurInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    toast.dismiss(this.changePasswordErrorId);
  }

  handleChangeInput(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleBlurInput(event) {
    this.setState({ touched: { ...this.state.touched, [event.target.name]: true } });
  }

  handleSubmit(event) {
    event.preventDefault();
    toast.dismiss(this.changePasswordErrorId);
    if (this.isFormValid()) {
      resetPassword( this.state.username, this.state.resetToken, this.state.password )
        .then( () => history.push('/') )
        .catch( err => {
          history.push('/')
          console.error( err );
        });

    }
  }

  showError(message) {
    this.changePasswordErrorId = toast.error(message);
  }

  isFormValid() {
    const errors = this.validate();
    return !(Object.values(errors).some(val => val));
  }

  hasFieldError(field, errors) {
    const hasError = errors[field];
    const shouldShow = this.state.touched[field];
    return (hasError ? shouldShow : false);
  }

  validate() {
    const passwordMismatch = (this.state.password !== this.state.confirmPassword);
    const passwordTooShort = (this.state.password.length < 6);
    return {
      password: (passwordMismatch || this.state.password === '' || passwordTooShort),
      confirmPassword: (passwordMismatch || this.state.confirmPassword === '')
    };
  }

  render() {
    const errorClassName = 'ee-field-error';
    const errors = this.validate();
    return (
      <FullPageWrapper withBackground withFooter>
        <main className="ee-login-view">
          <div className="ee-login">
            <header>
              <h1>
                <span className="sr-only">{i18n.t('global.title')}</span>
              </h1>
              <h2>{i18n.t('authScreen.resetPasswordTitle')}</h2>
            </header>
            <form className="ee-form" onSubmit={this.handleSubmit}>
              <div className="ee-form-body">
                <label htmlFor="password" className="sr-only">{i18n.t('account.passwordPlaceholder')}</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={this.state.password}
                  placeholder={i18n.t('account.passwordPlaceholder')}
                  required
                  className={this.hasFieldError('password', errors) ? `${errorClassName}` : ''}
                  onChange={this.handleChangeInput}
                  onBlur={this.handleBlurInput}
                />
                <label htmlFor="confirm-password" className="sr-only">{i18n.t('account.confirmPasswordPlaceholder')}</label>
                <input
                  type="password"
                  id="confirm-password"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  placeholder={i18n.t('account.confirmPasswordPlaceholder')}
                  required
                  className={this.hasFieldError('confirmPassword', errors) ? `${errorClassName}` : ''}
                  onChange={this.handleChangeInput}
                  onBlur={this.handleBlurInput}
                />
              </div>
              <div className="ee-form-actions">
                <button
                  type="submit"
                  className="ee-submit-btn btn btn-link"
                  disabled={!this.isFormValid()}
                  onClick={this.handleSubmit}
                >
                  {i18n.t('authScreen.resetPassword')}
                </button>
              </div>
            </form>
          </div>
        </main>
        <Footer />
      </FullPageWrapper>
    );
  }
}

export default connect(mapStateToPropsUser, null)(ChangePassword);
