import React, { Suspense } from 'react';
import { mapDispatchToProps, mapStateToPropsUser } from './action';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer, Slide, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

import HomePage from './pages/Home';
import Account from './pages/account';
import Admin from './pages/admin';
import Facilitator from './pages/facilitator';
import Player from './pages/player';
import ViewAllClientsPage from './pages/ViewAllClients';
import History, { history } from './History';
import { isUserLoggedIn, isUserAdmin, isUserFacilitator, isUserPlayer, useWindowDimensions } from './utils';
import i18n from './i18n';

import './styles/main.scss';

class App extends React.Component {
  errorShow(errorCode) {
    console.log('errorShow',errorCode);
    this.errorHide();
    this.connectionErrorId = toast.error( i18n.t( `error.${errorCode}` ) );
  }

  errorHide() {
    toast.dismiss(this.connectionErrorId);
    this.connectionErrorId = 0;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const event = this.props.connection.event;

    console.log('App didUpdate', event, this.props.isDisconnected, prevProps.isDisconnected);

    if (event === 'open failed'){
      this.errorShow('apiInitNotAvailable');

    } else if (event === 'reconnect' && !this.connectionErrorId) {
      this.errorShow('apiRetrying');

    } else if (this.props.isDisconnected && !prevProps.isDisconnected) {
      this.errorShow('apiNotAvailable');
    }
  }

  componentWillUnmount() {
    this.errorHide()
  }

  render() {
    const { client, userInfo, setGameId } = this.props;
    const isLoggedIn = isUserLoggedIn(userInfo);
    const isAdmin = isUserAdmin(userInfo);
    const isAuthenticating = userInfo.pending;
    return (
      <Suspense fallback={<div>Loading...</div>}>
        {isAuthenticating ? null : (
          <Router history={history}>
            <GameHeight />
            <ToastContainer
              position="top-center"
              autoClose={false}
              closeButton={false}
              transition={Slide}
              hideProgressBar
              closeOnClick={false}
              draggable={false}
            />
            <Switch>
              <Route exact path="/" render={() => (
                isLoggedIn ? <Facilitator /> : <HomePage />
              )} />
              <Route path="/viewall" component={ViewAllClientsPage} />
              <Route path="/admin"   render={() => (
                isAdmin ? <Admin /> : <Redirect to="/" />
              )} />
              <Route path="/account" component={Account} />
              <Route path={['/list', '/create']} render={() => (
                isLoggedIn ? <Facilitator /> : <Player />
              )} />
              <Route path="/play" render={(props) => (
                <Game {...props} client={client} isLoggedIn={isLoggedIn} setGameId={setGameId} />
              )} />
              <Route path="/join" component={Player} />
              <Route path="/:gameId([A-Za-z0-9]{6})" render={(props) => (
                <Game {...props} client={client} isLoggedIn={isLoggedIn} setGameId={setGameId} />
              )} />
              <Redirect to="/" />
            </Switch>
            <History />
          </Router>
        )}
      </Suspense>
    );
  }
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(App);

function GameHeight() {
  const { height } = useWindowDimensions();
  return (
    <Helmet>
      <style type="text/css">{`
       .ee-player-phase-view { height: ${height}px; }
     `}</style>
    </Helmet>
  );
}

function Game({ setGameId, match, client, isLoggedIn }) {
  const gameId = match.params.gameId;
  const isFacilitator = isUserFacilitator(client);
  const isPlayer = isUserPlayer(client);
  const isSetGame = (!isFacilitator && !isPlayer && gameId);
  let component = null;
  if (isSetGame) {
    setGameId(gameId);
  } else if (isLoggedIn && !isPlayer) {
    component = <Facilitator />;
  } else {
    component = <Player />;
  }
  return component;
}
