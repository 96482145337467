import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withModuleTranslation } from '../../i18n';
import { getStatuses, getClassName, getActiveChallenge, isEmptyObject } from '../../utils';
import PhaseDialogHeader from './PhaseDialogHeader';
import ChallengeTimer from '../ChallengeTimer';

class PhaseDialogRespond extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectScreen: true,
      showDetailsScreen: false,
      showDoneScreen: false,
      selectedChallenge: null,
      isCityWide: (props.playerTypeKey === null)
    };
    this.handleClickChallenge = this.handleClickChallenge.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleClickSend = this.handleClickSend.bind(this);
    this.handleClickDone = this.handleClickDone.bind(this);
    this.handleClickEnd = this.handleClickEnd.bind(this);
  }

  handleClickChallenge(selectedChallenge) {
    const { currentScenario, player } = this.props;
    const players = player.slice(1);
    const challengeIndex = currentScenario.challenges.indexOf(selectedChallenge);
    const challengeInProgressCount = players.map(p => p.challenge[challengeIndex]).filter(c => c.isActive).length;
    if (this.state.isCityWide && (challengeInProgressCount > 0)) {
      this.setState({ selectedChallenge: selectedChallenge });
    } else {
      this.setState({
        showSelectScreen: false,
        showDetailsScreen: true,
        selectedChallenge: selectedChallenge
      })
    }
  }

  handleClickBack() {
    this.setState({
      showSelectScreen: true,
      showDetailsScreen: false,
      selectedChallenge: null
    });
  }

  handleClickSend(playerTypeIndex) {
    // Back end expects 0 to mean everyone and 1-6 to be clients so add one
    // here because we keep the playerTypeIndex as -1 and 0-5.
    const { currentScenario, player } = this.props;
    const { selectedChallenge, isCityWide } = this.state;
    const challengeIndex = currentScenario.challenges.indexOf(selectedChallenge);
    const clientIndex = playerTypeIndex + 1;
    // If this is a city-wide challenge, end any in-progress ones first.
    if (isCityWide) {
      const players = player.slice(1);
      players.forEach((p, index) => {
        const activeChallenge = getActiveChallenge(p.challenge, currentScenario);
        if (!isEmptyObject(activeChallenge)) {
          this.props.challengeEnded(activeChallenge.index, index + 1);
        }
      });
    }
    this.props.challengeRelease(challengeIndex, clientIndex);
    this.setState({
      showDetailsScreen: false,
      showDoneScreen: true
    });
  }

  handleClickDone() {
    this.setState({
      showSelectScreen: true,
      showDetailsScreen: false,
      showDoneScreen: false
    });
    this.props.handleCloseDialog();
  }

  handleClickEnd(challengeInProgressKey, playerTypeIndex) {
    // Back end expects 0 to mean everyone and 1-6 to be clients so add one
    // here because we keep the playerTypeIndex as -1 and 0-5.
    const { currentScenario } = this.props;
    const challengeIndex = currentScenario.challenges.indexOf(challengeInProgressKey);
    const clientIndex = playerTypeIndex + 1;
    this.props.challengeEnded(challengeIndex, clientIndex);
    this.props.handleCloseDialog();
  }

  render() {
    const { tm, notification, player, currentScenario, playerTypeKey } = this.props;
    const { showSelectScreen, showDetailsScreen, showDoneScreen, selectedChallenge, isCityWide } = this.state;
    const neighborhoods = currentScenario.neighborhood.slice(1);
    const players = player.slice(1);
    const playerTypeIndex = neighborhoods.indexOf(playerTypeKey);
    const caption = (this.state.isCityWide ? tm('module.city') : tm(`module.neighborhoods.${playerTypeKey}`));
    let screenProps = {
      ...this.props,
      playerTypeIndex
    };
    let challengeInProgress = {};
    const challengeStatuses = getStatuses(notification.challenge);
    if (!isCityWide) {
      challengeInProgress = getActiveChallenge(players[playerTypeIndex].challenge, currentScenario);
    } else if (isCityWide && selectedChallenge !== null) {
      const challengeIndex = currentScenario.challenges.findIndex(c => c === selectedChallenge);
      const challengeInProgressCount = players.map(p => p.challenge[challengeIndex]).filter(c => c.isActive).length;
      if (challengeInProgressCount > 0) {
        challengeInProgress = {
          index: challengeIndex,
          key: selectedChallenge
        };
      }
    }
    let screen = null;
    if (showSelectScreen) {
      if (!isEmptyObject(challengeInProgress)) {
        screenProps = {
          ...screenProps,
          challengeInProgress,
          onClickEnd: this.handleClickEnd
        };
        screen = <InProgressChallengeScreen {...screenProps} />;
      } else {
        screenProps = {
          ...screenProps,
          currentScenario,
          isCityWide,
          challengeStatuses,
          players,
          onClickChallenge: this.handleClickChallenge
        };
        screen = <SelectChallengeScreen {...screenProps} />;
      }
    } else if (showDetailsScreen) {
      screenProps = {
        ...screenProps,
        selectedChallenge,
        onClickSend: this.handleClickSend
      };
      screen = <SendChallengeScreen {...screenProps} />;
    } else if (showDoneScreen) {
      screenProps = {
        ...screenProps,
        selectedChallenge,
        neighborhoodName: caption,
        onClickDone: this.handleClickDone
      };
      screen = <SentChallengeScreen {...screenProps} />;
    }
    return (
      <div className="ee-phase-dialog-respond">
        <PhaseDialogHeader
          classFragment={`player-type-${playerTypeIndex < 0 ? 'all' : getClassName(caption)}`}
          onClickBack={showDetailsScreen ? this.handleClickBack : null}
          onClickClose={this.handleClickDone}
          caption={caption}
        />
        <div className="ee-phase-dialog-content">{screen}</div>
      </div>
    );
  }
}

function SelectChallengeScreen(props) {
  const { t, tm, currentScenario, playerTypeIndex, isCityWide, challengeStatuses, players, onClickChallenge } = props;
  const challengeKeys = currentScenario.challenges;
  return (
    <ul className="ee-phase-dialog-list">
      {challengeKeys.map((challengeKey, index) => {
        const name = tm(`module.phases.1.actions.challenges.${challengeKey}.name`);
        let text = '';
        let sentAck = '';
        let cityWideInProgressCount = 0;
        if (isCityWide) {
          // This means we're trying to send it to all players. Set if it's been
          // sent or acknowledged only if every player has that status.
          if (challengeStatuses[index].player.every(p => p.acknowledged)) {
            sentAck = 'ack';
          } else if (challengeStatuses[index].player.every(p => p.sent)) {
            sentAck = 'sent';
          }
          cityWideInProgressCount = players.map(p => p.challenge[index]).filter(c => c.isActive).length;
          if (cityWideInProgressCount > 0) {
            text = t('phaseDialog.respond.endAllCityChallengeButton', { challenge: name, count: cityWideInProgressCount });
          } else {
            text = t('phaseDialog.respond.sendChallengeButton', { challenge: name });
          }
        } else {
          text = t('phaseDialog.respond.sendChallengeButton', { challenge: name });
          if (challengeStatuses[index].player[playerTypeIndex].acknowledged) {
            sentAck = 'ack';
          } else if (challengeStatuses[index].player[playerTypeIndex].sent) {
            sentAck = 'sent';
          }
        }
        const challengeDisabled = (sentAck === 'ack' && cityWideInProgressCount === 0);
        const challengeSent = (sentAck === 'ack' || sentAck === 'sent');
        return (
          <li className={`ee-phase-dialog-list-item ee-phase-dialog-list-item-${index}`} key={`phase-dialog-${index}`}>
            <button
              disabled={challengeDisabled}
              onClick={() => onClickChallenge(challengeKey)}
              className="ee-phase-dialog-select-btn btn btn-link"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {challengeSent ? (
              <span className={`ee-phase-dialog-sent-label ee-phase-dialog-sent-label-${sentAck}`}>
                {t(`phaseDialog.respond.sent`)}
              </span>
            ) : null}
          </li>
        );
      })}
    </ul>
  );
}

function SendChallengeScreen({ playerTypeIndex, selectedChallenge, onClickSend, ...otherProps }) {
  const { t, tm } = otherProps;
  const challenge = tm(`module.phases.1.actions.challenges.${selectedChallenge}`, { returnObjects: true });
  let location = '';
  if (playerTypeIndex < 0) {
    // This means we're trying to send it to all players.
    location = 'the entire city';
  } else {
    location = 'this neighborhood';
  }
  const title = t('phaseDialog.respond.challengeTitle', { article: challenge.article, challenge: challenge.name, location: location });
  const hasCost = ('cost' in challenge);
  return (
    <React.Fragment>
      <div className="ee-phase-dialog-body ee-phase-dialog-send-body">
        <div className="ee-phase-dialog-send-title" dangerouslySetInnerHTML={{__html: title}} />
        <div
          className="ee-phase-dialog-send-description"
          dangerouslySetInnerHTML={{__html: tm(`module.phases.1.actions.challenges.${selectedChallenge}.description`)}}
        />
        {hasCost ? (
          <React.Fragment>
            <div className="ee-phase-dialog-send-subtitle">{t('phaseDialog.respond.resourceCost')}</div>
            <div className="ee-phase-dialog-send-details">{challenge.cost}</div>
          </React.Fragment>
        ) : null}
      </div>
      <div className="ee-phase-dialog-footer">
        <button className="btn btn-link" onClick={() => onClickSend(playerTypeIndex)}>
          {t('phaseDialog.respond.sendChallenge')}
        </button>
      </div>
    </React.Fragment>
  );
}

function SentChallengeScreen({ playerTypeIndex, selectedChallenge, neighborhoodName, onClickDone, ...otherProps }) {
  const { t, tm } = otherProps;
  const challenge = tm(`module.phases.1.actions.challenges.${selectedChallenge}`, { returnObjects: true });
  const name = challenge.name;
  const title = t('phaseDialog.respond.challengeSent', { challenge: name });
  const hasCost = ('cost' in challenge);
  let message1 = '';
  if (playerTypeIndex < 0) {
    // This means we're trying to send it to all players. The neighborhoodName
    // is actually the city name.
    message1 = t('phaseDialog.respond.messageAllCity', { city: neighborhoodName });
  }
  let message2 = '';
  if (challenge.confirmation) {
    message2 = challenge.confirmation;
  } else {
    message2 = t('phaseDialog.respond.message', {neighborhood: neighborhoodName});
  }
  return (
    <React.Fragment>
      <div className="ee-phase-dialog-body">
        <div
          className={`ee-notification-sent-title ee-challenge-${getClassName(name)}`}
          dangerouslySetInnerHTML={{__html: title}}
        />
        {hasCost ? (
          <div
            className="ee-notification-sent-subtitle"
            dangerouslySetInnerHTML={{__html: t('phaseDialog.respond.messageToNeighborhood')}}
          />
        ) : null}
        <div className="ee-notification-sent-message" dangerouslySetInnerHTML={{__html: message1}} />
        <div className="ee-notification-sent-message" dangerouslySetInnerHTML={{__html: message2}} />
      </div>
      <div className="ee-phase-dialog-footer">
        <button className="btn btn-link" onClick={onClickDone}>
          {t('phaseDialog.done')}
        </button>
      </div>
    </React.Fragment>
  );
}

function InProgressChallengeScreen({ playerTypeIndex, challengeInProgress, isCityWide, onClickEnd, ...otherProps }) {
  const { t, tm } = otherProps;
  const challengeInProgressKey = challengeInProgress.key;
  const challenge = tm(`module.phases.1.actions.challenges.${challengeInProgressKey}`, { returnObjects: true });
  const name = challenge.name;
  const title = t('phaseDialog.respond.challengeInProgress', { challenge: name });
  const activeUntil = challengeInProgress.activeTill;
  return (
    <div className="ee-phase-dialog-in-progress">
      <div className="ee-phase-dialog-body">
        <div
          className={`ee-notification-sent-title ee-challenge-${getClassName(name)}`}
          dangerouslySetInnerHTML={{__html: title}}
        />
        <div
          className="ee-notification-sent-message"
          dangerouslySetInnerHTML={{ __html: challenge.inProgress }}
        />
        {activeUntil > 0 ? (
          <div className="ee-notification-sent-message">
            <span className="ee-time-remaining-label">{t('phaseDialog.respond.remainingTime')}</span>
            <span className="ee-time-remaining-value">
              <ChallengeTimer activeUntil={activeUntil} />
            </span>
          </div>
        ) : null}
      </div>
      <div className="ee-phase-dialog-footer">
        <button className="btn btn-link" onClick={() => onClickEnd(challengeInProgressKey, playerTypeIndex)}>
          {t('phaseDialog.respond.endChallengeButton')}
        </button>
      </div>
    </div>
  );
}

const translatedComponent = withTranslation()(withModuleTranslation()(PhaseDialogRespond));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
