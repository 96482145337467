import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import PhaseFooter from '../../components/facilitator/PhaseFooter';
import PhaseDialog from '../../components/facilitator/PhaseDialog';
import { BoxedPageWrapper } from '../../components/Wrapper';
import i18n, { withModuleTranslation } from '../../i18n';
import { scenario as allScenarios } from '../../scenario';
import PlayerTypes from '../../components/facilitator/PlayerTypes';
import PhaseScript from '../../components/facilitator/PhaseScript';
import TutorialTip from '../../components/facilitator/TutorialTip';

class GamePhase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      selectedPlayerType: null
    };
    this.scriptTopRef = React.createRef();
    this.handleClickContinue = this.handleClickContinue.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.display.part !== prevProps.display.part) {
      // When we change phases, move the display back up to the top and close
      // any open dialogs.
      window.scrollTo(0, 0);
      this.scriptTopRef.current.scrollTop = 0;
      this.handleCloseDialog();
    }
  }

  handleClickContinue() {
    this.props.displayNext();
  }

  handleOpenDialog(selectedPlayerType) {
    this.setState({
      showDialog: true,
      selectedPlayerType: selectedPlayerType
    });
  }

  handleCloseDialog() {
    this.setState({
      showDialog: false,
      selectedPlayerType: null
    });
  }

  render() {
    const { t, tm, scenario, isDemo, display: { showActions }, onClickQuit } = this.props;
    const currentScenario = allScenarios[scenario];
    const phase = this.props.display.part - 1;
    const section = this.props.display.screen - 1;
    const script = tm(`module.phases.${phase}.sections`, { returnObjects: true });
    const showDialog = this.state.showDialog;
    let hasActionTip = false;
    let actionTipProps = {};
    if (isDemo && (phase === 0)) {
      switch (section) {
        case 2:
          hasActionTip = true;
          actionTipProps = {
            text: i18n.t('tooltip.actionPanelTip'),
            position: 'top',
            className: 'action'
          };
          break;
        default:
          break;
      }
    }
    return (
      <BoxedPageWrapper>
        <div className={`ee-facilitator-view ee-module-${scenario}`}>
          <div className="ee-phase-script-wrapper">
            <header className="ee-phase-script-header">
              <h1>
                <span className="font-weight-normal">{t('facilitator.scriptLabel')}</span> {}
                {tm(`module.phases.${phase}.title`)}
              </h1>
            </header>
            <PhaseScript
              showTutorial={isDemo}
              phase={phase}
              section={section}
              currentScenario={currentScenario}
              script={script}
              onClickContinue={this.handleClickContinue}
              topRef={this.scriptTopRef}
            />
          </div>
          <div className="ee-phase-actions-wrapper">
            <header className="ee-phase-actions-header">
              <h1 className={`${showDialog ? 'invisible' : ''}`}>
                {tm(`module.phases.${phase}.actions.heading`)}
              </h1>
            </header>
            {hasActionTip ? (
              <div className="ee-tutorial">
                <TutorialTip {...actionTipProps} />
              </div>
            ) : null}
            <section className="ee-phase-actions-content">
              <div className="ee-phase-actions">
                {showActions ? (
                  <React.Fragment>
                    <PlayerTypes
                      phase={phase}
                      section={section}
                      currentScenario={currentScenario}
                      showDialog={showDialog}
                      onOpenDialog={this.handleOpenDialog}
                    />
                    {showDialog ? (
                      <PhaseDialog
                        currentScenario={currentScenario}
                        phase={phase}
                        playerTypeKey={this.state.selectedPlayerType}
                        handleCloseDialog={this.handleCloseDialog}
                      />
                    ) : null}
                  </React.Fragment>
                ) : (
                  <div className="ee-phase-actions-overview-wrapper">
                    <div className="ee-phase-actions-overview">
                      {tm(`module.phases.${phase}.actions.overview`)}
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          <PhaseFooter currentPhase={phase} onClickQuit={onClickQuit} />
        </div>
      </BoxedPageWrapper>
    );
  }
}

const translatedComponent = withTranslation()(withModuleTranslation()(GamePhase));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
