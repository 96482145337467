import React from 'react';

import i18n from '../../i18n';

class TutorialTip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTip: true
    };
    this.handleClickClose = this.handleClickClose.bind(this);
  }

  handleClickClose() {
    this.setState({ showTip: false });
  }

  render() {
    const { text, position, className } = this.props;
    return (
      <React.Fragment>
        {this.state.showTip ? (
          <div className={`ee-tutorial-tip ee-tutorial-tip-${position} ee-tutorial-tip-${className}`}>
            <button className="ee-close-btn btn" onClick={this.handleClickClose}>
              <span className="sr-only">{i18n.t('tooltip.closeTip')}</span>
            </button>
            {text}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default TutorialTip;
