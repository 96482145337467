import {gameDbService} from "../server";
import {setDemo} from "./index";

export async function newGame( demo = false ){
  console.log('newGame',demo);
  let data = {
    actions: []
  };

  data.actions.push( setDemo(demo) );

  let game = await gameDbService.create( data );
  return game;
}

export async function listGames(){
  console.log('listGames');
  return await gameDbService.find();
}
