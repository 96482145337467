import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withModuleTranslation } from '../../i18n';
import { getStatuses } from '../../utils';
import CitywideButton from './CitywideButton';

class PlayerTypesPrepare extends React.Component {
  render() {
    const { t, tm, notification, currentScenario, onClick } = this.props;
    const sectors = currentScenario.sector.slice(1);
    const tips = currentScenario.tip;
    const tipStatuses = getStatuses(notification.tip);
    return (
      <React.Fragment>
        <ul className="ee-player-type-list ee-player-type-list-prepare ee-animate">
          {sectors.map((sector, sectorIndex) => {
            return (
              <li
                className={`ee-player-type ee-player-type-${sectorIndex} ee-animate`}
                key={`sector-${sectorIndex}`}
              >
                <button className="btn btn-link" onClick={() => onClick(sector)}>
                  <span className="sr-only">{tm(`module.sectors.${sector}`)}</span>
                </button>
                <ul className="ee-notification-sent-list">
                  {tipStatuses.map((tipStatus, tipIndex) => {
                    let status = 'no';
                    if (tipStatus.player[sectorIndex].acknowledged){
                      status = 'ack';
                    } else if (tipStatus.player[sectorIndex].sent){
                      status = 'yes';
                    }
                    return (
                      <li className={`ee-notification-sent ee-notification-sent-${status}`} key={`tip-status-${tipIndex}`}>
                          <span
                            className="sr-only"
                            dangerouslySetInnerHTML={{__html: tm(`module.phases.0.actions.tips.${tips[tipIndex]}.caption`)}}
                          />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
        <CitywideButton
          phaseName="prepare"
          text={t('phaseDialog.prepare.cityWideTip')}
          onClick={onClick}
        />;
      </React.Fragment>
    );
  }
}

const translatedComponent = withTranslation()(withModuleTranslation()(PlayerTypesPrepare));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
