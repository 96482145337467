import React from 'react';

import i18n from '../../i18n';
import { FullPageWrapper } from '../../components/Wrapper';
import Footer from '../../components/Footer';
import HeaderAlternate from '../../components/HeaderAlternate';
import AccountForm from '../../components/account/AccountForm';
import { history } from '../../History';

function Create() {
  return (
    <FullPageWrapper withBackground withAlternateHeader>
      <HeaderAlternate onClickCancel={() => history.push('/account/login')} />
      <main className="ee-account-view">
        <div className="ee-account">
          <header>
            <h1>{i18n.t('createAccountScreen.title')}</h1>
            <p dangerouslySetInnerHTML={{ __html: i18n.t('createAccountScreen.content') }} />
          </header>
          <AccountForm isNew submitText={i18n.t('createAccountScreen.submit')} />
        </div>
      </main>
      <Footer/>
    </FullPageWrapper>
  );
}

export default Create;
