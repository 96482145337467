import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withModuleTranslation } from '../../i18n';
import { getStatuses, getClassName, getReceivedChallengeCount } from '../../utils';
import PhaseDialogHeader from './PhaseDialogHeader';

class PhaseDialogAdapt extends React.Component {
  render() {
    const { t, tm, player, notification, playerTypeKey, currentScenario, handleCloseDialog } = this.props;
    const players = player.slice(1);
    const neighborhoods = currentScenario.neighborhood.slice(1);
    const playerTypeIndex = neighborhoods.indexOf(playerTypeKey);
    const caption = tm(`module.neighborhoods.${playerTypeKey}`);
    const challengeStatuses = getStatuses(notification.challenge);
    return (
      <div className="ee-phase-dialog-adapt">
        <PhaseDialogHeader
          classFragment={`player-type-${getClassName(caption)}`}
          onClickBack={null}
          onClickClose={handleCloseDialog}
          caption={caption}
        />
        <div className="ee-phase-dialog-content">
          <div className="ee-phase-dialog-body">
            <div className="ee-insights-title">{t('phaseDialog.adapt.gameInsights')}</div>
            <div className="ee-insights-subtitle">{t('phaseDialog.adapt.challenges')}</div>
            <div className="ee-insight">
              <strong>{getReceivedChallengeCount(challengeStatuses, playerTypeIndex)}</strong>
            </div>
            <hr />
            <div className="ee-insights-subtitle">{t('phaseDialog.adapt.score')}</div>
            <div className="ee-insight">
              <strong>{players[playerTypeIndex].score.completed * 100}</strong>
              <div className="ee-insight-label">{t('phaseDialog.adapt.points')}</div>
            </div>
          </div>
          <div className="ee-phase-dialog-footer">
            <button className="btn btn-link" onClick={handleCloseDialog}>
              {t('phaseDialog.done')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const translatedComponent = withTranslation()(withModuleTranslation()(PhaseDialogAdapt));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
