import React from 'react';
import { mapStateToPropsUser } from '../../action';
import { requestResetPassword} from "../../action/auth";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import i18n from '../../i18n';
import { FullPageWrapper } from '../../components/Wrapper';
import Footer from '../../components/Footer';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSent: false
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    toast.dismiss(this.resetPasswordErrorId);
  }

  handleChangeInput(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    toast.dismiss(this.resetPasswordErrorId);
    if (this.isFormValid()) {
      requestResetPassword( this.state.email )
        .then( () => this.setState({ isSent: true }) )
        .catch( err => {
          console.error( err );
          this.setState({ isSent: true });
        });
    }
  }

  isFormValid() {
    const wrongEmailFormat = !(/[^\s]+@[^\s]+/.test(this.state.email));
    return (this.state.email !== '' && !wrongEmailFormat);
  }

  render() {
    const { isSent } = this.state;
    return (
      <FullPageWrapper withBackground withFooter>
        <main className="ee-login-view">
          <div className="ee-login">
            <header>
              <h1>
                <span className="sr-only">{i18n.t('global.title')}</span>
              </h1>
              <h2>{i18n.t(`authScreen.${isSent ? 'requestPasswordEmailSentTitle' : 'resetPasswordTitle'}`)}</h2>
            </header>
            {isSent ? (
              <React.Fragment>
                <p>{i18n.t('authScreen.requestPasswordEmailInstructions')}</p>
                <img src={require('../../assets/images/email-sent-image.png')} alt="" />
                <Link to="/account/login" className="ee-login-btn btn btn-link">
                  {i18n.t('authScreen.requestPasswordEmailContinue')}
                </Link>
              </React.Fragment>
            ) : (
              <form className="ee-form" onSubmit={this.handleSubmit}>
                <div className="ee-form-body">
                  <label htmlFor="email" className="sr-only">{i18n.t('authScreen.emailLabel')}</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={this.state.email}
                    placeholder={i18n.t('authScreen.emailLabel')}
                    onChange={this.handleChangeInput}
                  />
                </div>
                <div className="ee-form-actions">
                  <button
                    type="submit"
                    className="ee-submit-btn btn btn-link"
                    disabled={!this.isFormValid()}
                    onClick={this.handleSubmit}
                  >
                    {i18n.t('authScreen.resetPassword')}
                  </button>
                </div>
              </form>
            )}
          </div>
        </main>
        <Footer />
      </FullPageWrapper>
    );
  }
}

export default connect(mapStateToPropsUser, null)(ResetPassword);
