import React from 'react';
import ReactTimeout from 'react-timeout';

class EventTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeElapsed: 0
    };
    this.startTime = props.eventStart || Date.now();
  }

  componentDidMount() {
    const timeElapsed = Date.now() - this.startTime;
    this.setState({ timeElapsed: timeElapsed });
    this.props.setInterval(() => {
      const timeElapsed = Date.now() - this.startTime;
      this.setState({ timeElapsed: timeElapsed });
    }, 50);
  }

  getElapsedTimeFields(timeElapsed) {
    // Time moves fast, not real-time.
    let elapsedHours = parseInt((timeElapsed / 43200) % 60);
    elapsedHours = (elapsedHours < 10) ? `0${elapsedHours}` : elapsedHours;
    let elapsedMinutes = parseInt((timeElapsed / 720) % 60);
    elapsedMinutes = (elapsedMinutes < 10) ? `0${elapsedMinutes}` : elapsedMinutes;
    let elapsedSeconds = parseInt((timeElapsed / 12) % 60);
    elapsedSeconds = (elapsedSeconds < 10) ? `0${elapsedSeconds}` : elapsedSeconds;
    return {
      hours: elapsedHours,
      minutes: elapsedMinutes,
      seconds: elapsedSeconds
    };
  }

  render() {
    const { t, tm } = this.props;
    const elapsedTimeFields = this.getElapsedTimeFields(this.state.timeElapsed);
    return (
      <div className="ee-event-tracker-time">
        <div className="ee-time-title">
          {tm('module.participant.respond.aside.stormTracker.timerHeading')}
        </div>
        <div className="ee-time-value">
          <div className="ee-time-unit-wrapper">
            <div className="ee-time-unit ee-time-unit-hours">{elapsedTimeFields.hours}</div>
            <div className="ee-time-unit-label">{t('common.hours')}</div>
          </div>
          <div className="ee-time-unit-wrapper">
            <div className="ee-time-unit ee-time-unit-minutes">{elapsedTimeFields.minutes}</div>
            <div className="ee-time-unit-label">{t('common.minutes')}</div>
          </div>
          <div className="ee-time-unit-wrapper">
            <div className="ee-time-unit ee-time-unit-seconds">{elapsedTimeFields.seconds}</div>
            <div className="ee-time-unit-label">{t('common.seconds')}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReactTimeout(EventTimer);
