import React from 'react';
import { mapStateToPropsUser } from '../action';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Auth from '../action/auth';
import i18n from '../i18n';
import { isUserLoggedIn, isUserAdmin } from '../utils';

class Header extends React.Component {
  render() {
    const { userInfo } = this.props;
    const isLoggedIn = isUserLoggedIn(userInfo);
    const isAdmin = isUserAdmin(userInfo);
    return (
      <header className="ee-header">
        <div className="ee-header-actions">
          {isLoggedIn ? (
            <React.Fragment>
              <Link to="/account/edit" className="ee-account-btn btn btn-link">
                Welcome, {userInfo.firstName}
              </Link>
              {isAdmin &&
              <Link to="/admin/" className="ee-admin-btn btn btn-link">
                Administration
              </Link>
              }
            </React.Fragment>
          ) : null}
        </div>
        <div className="ee-logo">
          <Link to="/">
            <span className="sr-only">{i18n.t('global.title')}</span>
          </Link>
        </div>
        <div className="ee-header-actions">
          {isLoggedIn ? (
            <React.Fragment>
              <button className="ee-logout-btn btn btn-link" onClick={Auth.logout}>
                {i18n.t('common.signout')}
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Link to="/account/login" className="ee-login-btn btn btn-link">
                {i18n.t('common.signIn')}
              </Link>
              <Link to="/account/create" className="ee-create-account-btn btn btn-link">
                {i18n.t('common.createAnAccount')}
              </Link>
            </React.Fragment>
          )}
        </div>
      </header>
    );
  }
}

export default connect(mapStateToPropsUser, null)(Header);
