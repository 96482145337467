import React from 'react';
import ReactTimeout from 'react-timeout';

class ChallengeTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRemaining: 0
    };
    this.interval = null;
  }

  componentDidMount() {
    const { activeUntil } = this.props;
    const timeRemaining = (activeUntil - Date.now());
    this.setState({ timeRemaining: (timeRemaining > 0 ? timeRemaining : 0) });
    this.interval = this.props.setInterval(() => {
      const timeRemaining = (activeUntil - Date.now());
      this.setState({ timeRemaining: (timeRemaining > 0 ? timeRemaining : 0) });
    }, 1000);
  }

  componentDidUpdate() {
    const { timeRemaining } = this.state;
    if (timeRemaining <= 0) {
      this.props.clearInterval(this.interval);
    }
  }

  getFormattedTimeRemaining(timeRemaining) {
    if (timeRemaining <= 0) {
      return '00:00';
    }
    let remainingMinutes = parseInt((timeRemaining / (1000 * 60)) % 60);
    remainingMinutes = (remainingMinutes < 10) ? "0" + remainingMinutes : remainingMinutes;
    let remainingSeconds = parseInt((timeRemaining / 1000) % 60);
    remainingSeconds = (remainingSeconds < 10) ? "0" + remainingSeconds : remainingSeconds;
    return (remainingMinutes + ':' + remainingSeconds);
  }

  render() {
    const formattedTimeRemaining = this.getFormattedTimeRemaining(this.state.timeRemaining);
    return (
      <React.Fragment>
        {formattedTimeRemaining.split('').map((char, index) => {
          return (
            <span
              className={`ee-char ee-char-${index}`}
              key={`challenge-timer-char-${index}`}
            >
              {char}
            </span>
          );
        })}
      </React.Fragment>
    );
  }
}

export default ReactTimeout(ChallengeTimer);
