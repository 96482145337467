import {Type} from '../action';

const defaultState = {
  timestamp: 0,
  event: "initializing",
  info: null,
  err:  null
};

function actionToState( action ){
  return {
    timestamp: action.timestamp,
    event:     action.event,
    info:      action.info,
    err:       action.err
  };
}

export function connection( state = defaultState, action ){
  switch( action.type ){
    case Type.socket: return actionToState( action );
    default:          return state;
  }
}

const disconnectEvents = [
  'open failed',
  'end',
  'reconnect failed'
];
export function isDisconnected( state = false, action ){
  switch( action.type ){
    case Type.socket: return (action.event && disconnectEvents.indexOf(action.event) >= 0) ? true : state;
    default:          return state;
  }
}
