import React from 'react';

import i18n from '../../i18n';
import { FullPageWrapper } from '../../components/Wrapper';
import Footer from '../../components/Footer';
import HeaderAlternate from '../../components/HeaderAlternate';
import AccountForm from '../../components/account/AccountForm';
import { history } from '../../History';

function Edit() {
  return (
    <FullPageWrapper withBackground withAlternateHeader>
      <HeaderAlternate onClickCancel={() => history.push('/list')} />
      <main className="ee-account-view">
        <div className="ee-account">
          <header>
            <h1>{i18n.t('accountSettingsScreen.title')}</h1>
          </header>
          <AccountForm submitText={i18n.t('accountSettingsScreen.submit')} />
        </div>
      </main>
      <Footer/>
    </FullPageWrapper>
  );
}

export default Edit;
