import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { TimelineLite, Power0 } from 'gsap';

import i18n from '../../i18n';
import { getClassName } from '../../utils';

class GamePlayModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalTween = new TimelineLite({ paused: true });
    this.backgroundLines1Element = null;
    this.backgroundLines2Element = null;
    this.modalHeaderElement = null;
    this.modalContentIconElement = null;
    this.modalContentDividerElement = null;
    this.modalContentElement = null;
    this.modalFooterElement = null;
  }

  componentDidMount() {
    this.modalTween
      .from(this.backgroundLines1Element, 0.5, { y: '-100%', ease: Power0.easeIn })
      .from(this.backgroundLines2Element, 0.5, { y: '100%', ease: Power0.easeIn })
      .from(this.modalContentIconElement, 0.5, { opacity: 0, ease: Power0.easeIn, delay: 0.4 })
      .from(this.modalContentDividerElement, 0.5, { opacity: 0, width: 0, ease: Power0.easeIn, delay: 0.6 })
      .from([this.modalHeaderElement, this.modalContentElement], 0.5, { height: 0, ease: Power0.easeIn, delay: 0.9 })
      .from(this.modalFooterElement, 0.3, { height: 0, ease: Power0.easeIn, delay: 1 })
      .play();
    this.props.onOpen();
  }

  render() {
    const {isOpen, onClose, title, content, button, type, name} = this.props;
    const nameClassName = (name ? `ee-popup-${getClassName(name)}` : '');
    return (
      <div className={`ee-game-play-modal ee-game-play-${type}-modal`}>
        <div className="ee-game-play-modal-background">
          <div className="ee-lines">
            <div className="ee-lines-1" ref={div => this.backgroundLines1Element = div}></div>
          </div>
          <div className="ee-lines">
            <div className="ee-lines-2" ref={div => this.backgroundLines2Element = div}></div>
          </div>
        </div>
        <button className="ee-popup-close-btn btn btn-link" onClick={onClose}>
          <span className="sr-only">{i18n.t('common.close')}</span>
        </button>
        <Popup open={isOpen} onClose={onClose} modal closeOnDocumentClick={false} lockScroll={true}>
          <div className={`ee-popup ${nameClassName}`}>
            <header className="ee-popup-title-wrapper" ref={div => this.modalHeaderElement = div}>
              <div className="ee-popup-title">
                <h1 dangerouslySetInnerHTML={{__html: title}}/>
              </div>
            </header>
            <div className="ee-popup-content-icon" ref={div => this.modalContentIconElement = div}></div>
            <div className="ee-popup-content-divider" ref={div => this.modalContentDividerElement = div}></div>
            <section className="ee-popup-content-wrapper" ref={div => this.modalContentElement = div}>
              <div className="ee-popup-content" dangerouslySetInnerHTML={{__html: content}} />
            </section>
            <footer className="ee-popup-actions-wrapper" ref={div => this.modalFooterElement = div}>
              <div className="ee-popup-actions">
                <button className="btn btn-link" onClick={onClose}>
                  {button}
                </button>
              </div>
            </footer>
          </div>
        </Popup>
      </div>
    );
  }
}

GamePlayModal.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  button: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string
};

GamePlayModal.defaultProps = {
  isOpen: false,
  title: '',
  content: '',
  button: '',
  type: 'default',
  name: ''
};

export default GamePlayModal;

