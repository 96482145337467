import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { Router, Route } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Action from './action';
import reducers from './reducer';
import * as Server from './server';
import { history } from './History';
import withTracker from './withTracker';
import { mapStateToPropsUser } from './action';

import './i18n';

async function main(){
  const middleware = [Server.featherMiddleware];
  const store = createStore(reducers, applyMiddleware(...middleware));
  await Server.init( store );
  Action.setStore( store );

  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Route component={connect(mapStateToPropsUser, null)(withTracker(App))} />
      </Router>
    </Provider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
main()
  .catch( err => {
    console.error('mail',err);
  });
