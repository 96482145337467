import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withModuleTranslation } from '../../i18n';
import { getClassName } from '../../utils';

class PlayerTypesAdapt extends React.Component {
  render() {
    const { tm, currentScenario, onClick } = this.props;
    const neighborhoods = currentScenario.neighborhood.slice(1);
    return (
      <React.Fragment>
        <ul className="ee-player-type-list ee-player-type-list-adapt ee-animate">
          {neighborhoods.map((neighborhood, neighborhoodIndex) => {
            const neighborhoodName = tm(`module.neighborhoods.${neighborhood}`);
            const neighborhoodClassName = `ee-player-type-${getClassName(neighborhoodName)}`;
            return (
              <li
                className={`ee-player-type ee-player-type-${neighborhoodIndex} ${neighborhoodClassName} ee-animate`}
                key={`neighborhood-${neighborhoodIndex}`}
              >
                <button className="btn btn-link" onClick={() => onClick(neighborhood)}>
                  <span className="sr-only">{neighborhoodName}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }
}

const translatedComponent = withTranslation()(withModuleTranslation()(PlayerTypesAdapt));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
