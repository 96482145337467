import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import i18n from '../i18n';

function ConfirmPopup({ isOpen, onClose, onConfirm, title, content, button, type}) {
  return (
    <div className="ee-popup-wrapper">
      <Popup open={isOpen} onClose={onClose}>
        <div className="ee-popup ee-popup-confirm">
          <button className="ee-popup-close-btn btn btn-link" onClick={onClose}>
            <span className="sr-only">{i18n.t('common.close')}</span>
          </button>
          <div className="ee-popup-title" dangerouslySetInnerHTML={{__html: title}} />
          <div className="ee-popup-content" dangerouslySetInnerHTML={{__html: content}} />
          <div className={`ee-popup-actions ee-popup-actions-${type}`}>
            <button className="btn btn-link" onClick={onConfirm}>
              {button}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

ConfirmPopup.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  button: PropTypes.string,
  type: PropTypes.string
};

ConfirmPopup.defaultProps = {
  isOpen: false,
  title: '',
  content: '',
  button: '',
  type: 'alert'
};

export default ConfirmPopup;
