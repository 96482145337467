import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withModuleTranslation } from '../../i18n';
import { getStatuses } from '../../utils';
import PhaseDialogHeader from './PhaseDialogHeader';

class PhaseDialogPrepare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectScreen: true,
      showDetailsScreen: false,
      showDoneScreen: false,
      selectedTip: null
    };
    this.handleClickTip = this.handleClickTip.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleClickSend = this.handleClickSend.bind(this);
    this.handleClickDone = this.handleClickDone.bind(this);
  }

  handleClickTip(selectedTip) {
    this.setState({
      selectedTip: selectedTip,
      showSelectScreen: false,
      showDetailsScreen: true
    });
  }

  handleClickBack() {
    this.setState({
      showSelectScreen: true,
      showDetailsScreen: false,
      selectedTip: null
    });
  }

  handleClickSend(playerTypeIndex) {
    // Back end expects 0 to mean everyone and 1-6 to be clients so add one
    // here because we keep the playerTypeIndex as -1 and 0-5.
    const { currentScenario } = this.props;
    const { selectedTip } = this.state;
    const tipIndex = currentScenario.tip.indexOf(selectedTip);
    const clientIndex = playerTypeIndex + 1;
    this.props.tipRelease(tipIndex, clientIndex);
    this.setState({
      showDetailsScreen: false,
      showDoneScreen: true
    });
  }

  handleClickDone() {
    const { handleCloseDialog } = this.props;
    this.setState({
      showSelectScreen: true,
      showDetailsScreen: false,
      showDoneScreen: false,
      selectedTip: null
    });
    handleCloseDialog();
  }

  render() {
    const { tm, playerTypeKey, currentScenario } = this.props;
    const { showSelectScreen, showDetailsScreen, showDoneScreen, selectedTip } = this.state;
    const sectors = currentScenario.sector.slice(1);
    let playerTypeIndex = -1;
    let caption = '';
    if (playerTypeKey !== null) {
      playerTypeIndex = sectors.indexOf(playerTypeKey);
      caption = tm(`module.sectors.${playerTypeIndex}`);
    } else {
      caption = tm(`module.city`);
    }
    let screen = null;
    if (showSelectScreen) {
      const screenProps = {
        ...this.props,
        currentScenario,
        playerTypeIndex,
        onClickTip: this.handleClickTip
      };
      screen = <SelectTipScreen {...screenProps} />
    } else if (showDetailsScreen) {
      const screenProps = {
        ...this.props,
        playerTypeIndex,
        selectedTip,
        onClickSend: this.handleClickSend
      };
      screen = <SendTipScreen {...screenProps} />
    } else if (showDoneScreen) {
      const screenProps = {
        ...this.props,
        selectedTip,
        onClickDone: this.handleClickDone
      };
      screen = <SentTipScreen {...screenProps} />
    }
    return (
      <div className="ee-phase-dialog-prepare">
        <PhaseDialogHeader
          classFragment={`player-type-${playerTypeIndex < 0 ? 'all' : playerTypeIndex}`}
          onClickBack={showDetailsScreen ? this.handleClickBack : null}
          onClickClose={this.handleClickDone}
          caption={caption}
        />
        <div className="ee-phase-dialog-content">{screen}</div>
      </div>
    );
  }
}

function SelectTipScreen({ currentScenario, playerTypeIndex, onClickTip, ...otherProps }) {
  const { t, tm, notification } = otherProps;
  const tipKeys = currentScenario.tip;
  const tipStatuses = getStatuses(notification.tip);
  return (
    <ul className="ee-phase-dialog-list">
      {tipKeys.map((tipKey, index) => {
        let sentAck = '';
        if (playerTypeIndex < 0) {
          // This means we're trying to send it to all players. Set if it's been
          // sent or acknowledged only if every player has that status.
          if (tipStatuses[index].player.every(p => p.acknowledged)) {
            sentAck = 'ack';
          } else if (tipStatuses[index].player.every(p => p.sent)) {
            sentAck = 'sent';
          }
        } else {
          if (tipStatuses[index].player[playerTypeIndex].acknowledged) {
            sentAck = 'ack';
          } else if (tipStatuses[index].player[playerTypeIndex].sent) {
            sentAck = 'sent';
          }
        }
        let tipDisabled = (sentAck === 'ack');
        return (
          <li className="ee-phase-dialog-list-item" key={`phase-dialog-${index}`}>
            <button
              disabled={tipDisabled}
              onClick={() => onClickTip(tipKey)}
              className="ee-phase-dialog-select-btn btn btn-link"
              dangerouslySetInnerHTML={{__html: tm(`module.phases.0.actions.tips.${tipKey}.caption`)}}
            />
            {sentAck ? (
              <span className={`ee-phase-dialog-sent-label ee-phase-dialog-sent-label-${sentAck}`}>
                {t(`phaseDialog.prepare.${sentAck}`)}
              </span>
            ) : null}
          </li>
        );
      })}
    </ul>
  );
}

function SendTipScreen({ playerTypeIndex, selectedTip, onClickSend, ...otherProps }) {
  const { t, tm } = otherProps;
  const caption = tm(`module.phases.0.actions.tips.${selectedTip}.caption`);
  let title = '';
  if (playerTypeIndex < 0) {
    // This means we're trying to send it to all players.
    title = t('phaseDialog.prepare.sendTipTitleCity', { tip: caption });
  } else {
    title = t('phaseDialog.prepare.sendTipTitle', { tip: caption });
  }
  return (
    <React.Fragment>
      <div className="ee-phase-dialog-body ee-phase-dialog-send-body">
        <div className="ee-phase-dialog-send-title" dangerouslySetInnerHTML={{__html: title}} />
        <div className="ee-phase-dialog-send-description">
          {tm(`module.phases.0.actions.tips.${selectedTip}.description`)}
        </div>
        <div className="ee-phase-dialog-send-subtitle">{t('phaseDialog.prepare.details')}</div>
        <div className="ee-phase-dialog-send-details">
          {tm(`module.phases.0.actions.tips.${selectedTip}.tipDetail`)}
        </div>
      </div>
      <div className="ee-phase-dialog-footer">
        <button className="btn btn-link" onClick={() => onClickSend(playerTypeIndex)}>
          {t('phaseDialog.prepare.sendTip')}
        </button>
      </div>
    </React.Fragment>
  );
}

function SentTipScreen({ playerTypeIndex, selectedTip, onClickDone, ...otherProps }) {
  const { t, tm } = otherProps;
  const caption = tm(`module.phases.0.actions.tips.${selectedTip}.name`);
  const title = t('phaseDialog.prepare.tipSent', { tip: caption });
  let subtitle = '';
  if (playerTypeIndex < 0) {
    // This means we're trying to send it to all players.
    subtitle = t('phaseDialog.prepare.messageToCity')
  } else {
    subtitle = t('phaseDialog.prepare.messageToSector');
  }
  const message = t('phaseDialog.prepare.message');
  return (
    <React.Fragment>
      <div className="ee-phase-dialog-body">
        <div className="ee-notification-sent-title" dangerouslySetInnerHTML={{__html: title}} />
        <div className="ee-notification-sent-subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
        <div className="ee-notification-sent-message" dangerouslySetInnerHTML={{__html: message}} />
      </div>
      <div className="ee-phase-dialog-footer">
        <button className="btn btn-link" onClick={onClickDone}>
          {t('phaseDialog.done')}
        </button>
      </div>
    </React.Fragment>
  );
}

const translatedComponent = withTranslation()(withModuleTranslation()(PhaseDialogPrepare));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
