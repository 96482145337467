import React from 'react';
import { mapStateToPropsUser } from '../../action';
import { connect } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { history } from '../../History';
import LoginPage from './Login';
import CreatePage from './Create';
import EditPage from './Edit';
import ResetPasswordPage from './ResetPassword';
import ChangePasswordPage from './ChangePassword';
import { isUserLoggedIn, isEmptyObject } from '../../utils';

class Account extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isEmptyObject(prevProps.userInfo) && !isEmptyObject(this.props.userInfo) && this.props.userInfo.id) {
      const values = queryString.parse(this.props.location.search);
      const next = (values.next ? values.next : '/list');
      this.props.history.push(next);
    }
  }

  render() {
    const { userInfo } = this.props;
    const isLoggedIn = isUserLoggedIn(userInfo);
    return (
      <Router history={history}>
        <Switch>
          <Route path="/account/login" render={() => (
            isLoggedIn ? (
              <Redirect to="/list" />
            ) : (
              <LoginPage />
            )
          )} />
          <Route path="/account/create" render={() => (
            isLoggedIn ? (
              <Redirect to="/list" />
            ) : (
              <CreatePage />
            )
          )} />
          <Route path="/account/edit" render={() => (
            !isLoggedIn ? (
              <Redirect to="/account/login" />
            ) : (
              <EditPage />
            )
          )} />
          <Route path="/account/resetPassword" render={() => (
            isLoggedIn ? (
              <Redirect to="/account/edit" />
            ) : (
              <ResetPasswordPage />
            )
          )} />
          <Route path="/account/changePassword" render={() => (
            isLoggedIn ? (
              <Redirect to="/account/edit" />
            ) : (
              <ChangePasswordPage />
            )
          )} />
          <Route path="/account" render={() => (
            !isLoggedIn ? (
              <Redirect to="/account/login" />
            ) : (
              <Redirect to="/account/edit" />
            )
          )} />
        </Switch>
      </Router>
    );
  }
}

export default connect(mapStateToPropsUser, null)(Account);
