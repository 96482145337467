import {Type} from '../action';

import {challengeResources, resources, board, challengeAudio} from '../scenario';

export const maxPlayers = 6;
export const maxTips = 4;
export const maxChallenges = 4;
export const maxNeeds = 5;


function defaultTipChallenge( max, field ){
  let ret = [];
  for( let co=0; co<max; co++ ){
    let val = {
      isVisible: false
    };
    val[field] = false;
    if( field === 'isActive' ){
      val.activeTill = 0;
      val.isEnded = false;
    }
    ret.push( val );
  }
  return ret;
}

function defaultNeedMet(){
  return Array(maxNeeds).fill(false);
}

function defaultPlayerState(){
  let ret = {
    connected: false,
    audio: '',
    tip: defaultTipChallenge( maxTips, 'isAvailable' ),
    challenge: defaultTipChallenge( maxChallenges, 'isActive' ),
    resource: {},
    score:{
      decision: 0,
      completed: 0,
      needMet: defaultNeedMet()
    }
  };
  return ret;
}

function defaultState(){
  let state = [];

  for( let co=0; co<=maxPlayers; co++ ){
    state.push( defaultPlayerState() )
  }

  return state;
}

function setScenario( state, action ){
  let scenarioName = action.scenario;
  let allResources = resources( scenarioName );

  let ret = [];
  ret.push( state[0] );

  for( let co=1; co<=maxPlayers; co++ ){
    let playerResources = {};
    for( let c1=0; c1<allResources[co].length; c1++ ){
      let resourceName = allResources[co][c1];
      playerResources[resourceName] = {
        isBlocked: false,
        isUsed:    false
      }
    }
    ret.push({
      ...state[co],
      resource: playerResources
    })
  }

  return ret;
}

function connected( state, action, isConnected ){
  let ret = state.slice();
  let client = action.client;
  ret[client] = {
    ...ret[client],
    connected: isConnected
  };
  return ret;
}

function setPlayerAudio( player, audio ){
  let newPlayer = {
    ...player,
    audio: audio
  };
  return newPlayer;
}

function audio( state, action ){
  let ret = state.slice();
  let client = action.client;
  let min = client ? client : 1;
  let max = client ? client : 6;

  for( let co=min; co<=max; co++ ){
    ret[co] = setPlayerAudio( ret[co], action.audio );
  }

  return ret;
}

/**
 *
 * @param player A single player
 * @param field Which field to update ("tip" or "challenge")
 * @param index Which tip or challenge to update
 * @param attribute Which attribute to update ("isAvailable", "isActive", "isVisible")
 * @param value The value to set the attribute to.
 * @return A new version of the player
 */
function setPlayerFieldAttribute( player, field, index, attribute, value ){
  let current = player[field][index];
  let updated = {};
  updated[attribute] = value;

  // Create new versions of the object and changed portions
  let ret = Object.assign( {}, player );
  ret[field] = ret[field].slice();
  ret[field][index] = Object.assign( {}, current, updated );

  return ret;
}

function adjustPlayerBlockedResource( player, resourceName ){
  let resource = player.resource[resourceName];
  if( !resource ){
    return player;
  }

  let updatedValues = {
    ...resource,
    isBlocked: true,
    isUsed:    false
  };

  let updatedResource = {};
  updatedResource[resourceName] = updatedValues;

  let updatedResources = Object.assign( {}, player.resource, updatedResource );

  let updatedPlayer = {
    ...player,
    resource: updatedResources
  };

  return updatedPlayer;
}

function adjustPlayerBlockedResources( player, action ){
  let challenge = action.challenge;
  let scenarioName = action.scenario;
  let blockedResources = challengeResources( scenarioName, challenge );

  console.log('adjustPlayerBlockedResources', blockedResources);

  for( let co=0; blockedResources && co<blockedResources.length; co++ ){
    let resourceName = blockedResources[co];
    player = adjustPlayerBlockedResource( player, resourceName );
  }

  return player;
}

function metResources( needsResources, hasResources ){
  let ret = true;

  for( let co=0; co<needsResources.length; co++ ){
    let needs = needsResources[co];
    let has = hasResources[needs];
    ret = ret && has.isUsed && !has.isBlocked;
  }

  return ret;
}

function metNeed( need, resources ){
  let ret = false;

  for( let co=0; co<need.length; co++ ){
    let needsResources = need[co];
    ret = ret || metResources( needsResources, resources );
  }

  return ret;
}

function computeScore( scenario, client, decision, clientResources ){
  let score = 0;

  let met = defaultNeedMet();
  if( decision ){
    let needs = board( scenario, client, decision );
    if( needs ){
      met = needs.map( need => metNeed( need, clientResources) );
      score = met.reduce( (acc, cur) => cur ? acc+1 : acc, score );
    }
  }

  return {score, met};
}

function adjustPlayerScore( scenario, player, clientId ){
  let decision = player.score.decision;
  let resources = player.resource;

  let {score,met} = computeScore( scenario, clientId, decision, resources );
  let oldScore  = player.score.completed;

  if( score !== oldScore ){
    player = {
      ...player,
      score: {
        ...player.score,
        completed: score,
        needMet: met
      }
    }
  }

  return player;
}

function alreadyReleased( player, field, index ){
  let ret = false;

  if( field === 'challenge' ){
    let challenge = player[field][index];
    let activeAvailable = (field === 'tip') ? 'isAvailable' : 'isActive';
    ret = challenge[activeAvailable] || challenge['isEnded'];
  }

  return ret;
}

function releaseClient( player, action, field ){
  let index = action[field];
  if( alreadyReleased( player, field, index ) ){
    console.log('already released');
    return player;
  }
  console.log('releaseClient');

  let activeAvailable = (field === 'tip') ? 'isAvailable' : 'isActive';
  player = setPlayerFieldAttribute( player, field, index, activeAvailable, true );
  player = setPlayerFieldAttribute( player, field, index, 'isVisible', true );
  let audio = field;

  // Possibly adjust player resources that are blocked
  // or the expiration time for the event
  if( field === 'challenge' ){
    player = adjustPlayerBlockedResources( player, action );
    player = setPlayerFieldAttribute( player, field, index, 'activeTill', action['activeTill'] );
    player = setPlayerFieldAttribute( player, field, index, 'isEnded', false );

    // Audio might have an override
    audio = challengeAudio(action.scenario, action.challenge);
  }

  player = setPlayerAudio( player, audio );

  return player;
}

/**
 *
 * @param state
 * @param action
 * @param field Either "tip" or "challenge"
 * @return {*}
 */
function release( state, action, field ){
  let ret = state.slice();
  let client = action.client;
  let min = client ? client : 1;
  let max = client ? client : maxPlayers;
  console.log('release',action,min,max);
  for( let co=min; co<=max; co++ ){
    console.log('release',co);
    ret[co] = releaseClient( ret[co], action, field );

    if( field === 'challenge' ){
      // Adjust player score.completed
      ret[co] = adjustPlayerScore( action.scenario, ret[co], co );
    }
  }
  return ret;
}

function challengeEnded( state, action ){
  let field = 'challenge';
  let ret = state.slice();
  let client = action.client;
  let index = action[field];
  let min = client ? client : 1;
  let max = client ? client : maxPlayers;
  console.log('challengeEnded index',index,min,max);
  for( let co=min; co<=max; co++ ){
    console.log('challengeEnded player',co,ret[co][field][index]);
    if( ret[co][field][index]['isActive'] ){
      console.log('challengeEnded ending');
      // Only end ones currently active
      ret[co] = setPlayerFieldAttribute( ret[co], field, index, 'isActive', false );
      ret[co] = setPlayerFieldAttribute( ret[co], field, index, 'isVisible', false );
      ret[co] = setPlayerFieldAttribute( ret[co], field, index, 'activeTill', 0 );
      ret[co] = setPlayerFieldAttribute( ret[co], field, index, 'isEnded', true );
    }
  }
  return ret;
}

function visible( state, action, field, isVisible ){
  let ret = state.slice();
  let client = action.client;
  let index = action[field];
  let min = client ? client : 1;
  let max = client ? client : maxPlayers;
  for( let co=min; co<=max; co++ ){
    ret[co] = setPlayerFieldAttribute( ret[co], field, index, 'isVisible', isVisible );
  }
  return ret;
}

function resource( state, action, use ){
  console.log('resource',state,action);
  let client = action.client;
  let player = state[client];
  let resourceName = action.resource;
  let resource = player.resource[resourceName];

  let updatedValues = {
    ...resource,
    isUsed: use
  };

  let updatedResource = {};
  updatedResource[resourceName] = updatedValues;

  let updatedResources = Object.assign( {}, player.resource, updatedResource );

  let updatedPlayer = {
    ...player,
    resource: updatedResources
  };

  // Adjust player score completed
  updatedPlayer = adjustPlayerScore( action.scenario, updatedPlayer, client );

  let ret = state.map( (e,i) => (i === client) ? updatedPlayer : e );
  return ret;
}

function decision( state, action ){
  let client = action.client;
  let player = state[client];

  let updatedPlayer = {
    ...player,
    score: {
      ...player.score,
      decision: action.decision,
    }
  };

  // Adjust player score completed
  updatedPlayer = adjustPlayerScore( action.scenario, updatedPlayer, client );

  let ret = state.map( (e,i) => (i === client) ? updatedPlayer : e );
  return ret;
}

function completed( state, action ){
  let client = action.client;
  let player = state[client];

  let updatedPlayer = {
    ...player,
    score: {
      ...player.score,
      completed: action.completed
    }
  };

  // Do NOT adjust the player score

  let ret = state.map( (e,i) => (i === client) ? updatedPlayer : e );
  return ret;
}

export function player( state, action ){
  if( !state ){
    state = defaultState();
  }

  switch( action.type ){
    case Type.state:            return action.state.player;
    case Type.setScenario:      return setScenario( state, action );
    case Type.connected:        return connected( state, action, true );
    case Type.disconnected:     return connected( state, action, false );
    case Type.playAudio:        return audio( state, action );
    case Type.tipRelease:       return release( state, action, 'tip' );
    case Type.tipShow:          return visible( state, action, 'tip', true );
    case Type.tipHide:          return visible( state, action, 'tip', false );
    case Type.challengeRelease: return release( state, action, 'challenge' );
    case Type.challengeEnded:   return challengeEnded( state, action );
    case Type.challengeShow:    return visible( state, action, 'challenge', true );
    case Type.challengeHide:    return visible( state, action, 'challenge', false );
    case Type.resourceUse:      return resource( state, action, true );
    case Type.resourceRelease:  return resource( state, action, false );
    case Type.setDecision:      return decision( state, action );
    case Type.setCompleted:     return completed( state, action );
    case Type.reset:            return defaultState();
    default:                    return state;
  }
}
