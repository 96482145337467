import React from 'react';

import { getClassName } from '../../utils';

function NewsFeed({ t, feed, onClickNews }) {
  return (
    <div className="ee-news">
      <div className="ee-news-feed">
        <header>
          <h1>{feed.title}</h1>
        </header>
        <section>
          <ul>
            {feed.items.map((item, index) => {
              const title = item.title;
              const author = item.author;
              const newsProps = {
                title: title,
                content: item.content,
                author: author,
                date: item.published
              };
              return (
                <li key={`news-item-${index}`} onClick={() => onClickNews(newsProps)}>
                  <h2 dangerouslySetInnerHTML={{__html: title}} />
                  {author ? (
                    <dl>
                      <dt>{t('newsItem.authorLabel')}</dt>
                      <dd className={`ee-news-source ee-news-source-${getClassName(author)}`}>
                        <span className="sr-only">{author}</span>
                      </dd>
                    </dl>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </section>
      </div>
    </div>
  );
}

export default NewsFeed;
