import {Type} from '../action';

const defaultState = {
  count: 0,
  orgName: '',
  orgType: '',
  orgTypeOther: ''
};

function surveyValue( state ){
  return {
    ...defaultState,
    state
  }
}

export function survey( state = defaultState, action ){
  switch( action.type ){
    case Type.state:     return action.state.survey || defaultState;
    case Type.setSurvey: return surveyValue( state );
    case Type.reset:     return defaultState;
    default:             return state;
  }
}