import React from 'react';

import EventTimer from './EventTimer';

function Tracker({ t, tm, scenario, eventStart }) {
  let trackerView = null;
  switch (scenario) {
    case 'hurricane':
      trackerView = <HurricaneTracker />;
      break;
    case 'flood':
      trackerView = <FloodTracker />;
      break;
    case 'earthquake':
      trackerView = <EarthquakeTracker t={t} />;
        break;
    default:
      break;
  }
  return (
    <div className="ee-event-tracker">
      <header>
        <h1>{tm('module.participant.respond.aside.stormTracker.title')}</h1>
      </header>
      {trackerView}
      <EventTimer t={t} tm={tm} eventStart={eventStart} />
    </div>
  );
}

function HurricaneTracker() {
  return (
    <React.Fragment>
      <div className="ee-event-tracker-pulse-center">
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-1" />
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-2" />
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-3" />
      </div>
      <div className="ee-event-tracker-event" />
      <div className="ee-event-tracker-topography" />
    </React.Fragment>
  );
}

function FloodTracker() {
  return (
    <React.Fragment>
      <div className="ee-event-tracker-pulse-center">
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-1" />
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-2" />
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-3" />
      </div>
    </React.Fragment>
  );
}

function EarthquakeTracker({ t }) {
  return (
    <React.Fragment>
      <div className="ee-event-tracker-pulse-wrapper">
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-1">
          <div className="ee-pulse-center" />
          <div className="ee-pulse-ring ee-pulse-ring-sm" />
          <div className="ee-pulse-ring ee-pulse-ring-md" />
          <div className="ee-pulse-ring ee-pulse-ring-lg" />
          <div className="ee-pulse-ring ee-pulse-ring-xl" />
        </div>
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-2">
          <div className="ee-pulse-center" />
          <div className="ee-pulse-ring ee-pulse-ring-sm" />
          <div className="ee-pulse-ring ee-pulse-ring-md" />
          <div className="ee-pulse-ring ee-pulse-ring-lg" />
          <div className="ee-pulse-ring ee-pulse-ring-xl" />
        </div>
        <div className="ee-event-tracker-pulse ee-event-tracker-pulse-3">
          <div className="ee-pulse-center" />
          <div className="ee-pulse-ring ee-pulse-ring-sm" />
          <div className="ee-pulse-ring ee-pulse-ring-md" />
          <div className="ee-pulse-ring ee-pulse-ring-lg" />
          <div className="ee-pulse-ring ee-pulse-ring-xl" />
        </div>
      </div>
      <div className="ee-event-tracker-event">
        <div className="ee-magnitude-title">{t('respond.magnitude')}</div>
        <div className="ee-magnitude-control">
          <div className="ee-magnitude-value">{t('respond.magnitudeValue')}</div>
          <div className="ee-magnitude-graph" />
        </div>
        <div className="ee-magnitude-label">{t('respond.moderateIntensity')}</div>
      </div>
    </React.Fragment>
  );
}

export default Tracker;
