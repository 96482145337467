import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withModuleTranslation } from '../../i18n';
import { getActiveChallenge, getClassName, getStatuses } from '../../utils';
import CitywideButton from './CitywideButton';

class PlayerTypesRespond extends React.Component {
  render() {
    const { t, tm, notification, player, currentScenario, onClick } = this.props;
    const players = player.slice(1);
    const neighborhoods = currentScenario.neighborhood.slice(1);
    const challenges = currentScenario.challenges;
    const challengeStatuses = getStatuses(notification.challenge);
    return (
      <React.Fragment>
        <ul className="ee-player-type-list ee-player-type-list-respond ee-animate">
          {neighborhoods.map((neighborhood, neighborhoodIndex) => {
            const activeChallenge = getActiveChallenge(players[neighborhoodIndex].challenge, currentScenario);
            const activeChallengeClassName = (activeChallenge.key ? 'ee-challenge-in-progress' : '');
            const neighborhoodName = tm(`module.neighborhoods.${neighborhood}`);
            const neighborhoodClassName = `ee-player-type-${getClassName(neighborhoodName)}`;
            return (
              <li
                className={`ee-player-type ee-player-type-${neighborhoodIndex} ${neighborhoodClassName} ${activeChallengeClassName} ee-animate`}
                key={`neighborhood-${neighborhoodIndex}`}
              >
                <button className="btn btn-link" onClick={() => onClick(neighborhood)}>
                  <span className="sr-only">{neighborhoodName}</span>
                </button>
                <ul className="ee-notification-sent-list">
                  {challengeStatuses.map((challengeStatus, challengeIndex) => {
                    let status = 'no';
                    if (challengeStatus.player[neighborhoodIndex].acknowledged) {
                      status = 'ack';
                    } else if (challengeStatus.player[neighborhoodIndex].sent) {
                      status = 'yes';
                    }
                    return (
                      <li className={`ee-notification-sent ee-notification-sent-${status}`} key={`neighborhood-status-${challengeIndex}`}>
                        <span
                          className="sr-only"
                          dangerouslySetInnerHTML={{__html: tm(`module.phases.1.actions.challenges.${challenges[challengeIndex]}.caption`)}}
                        />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
        <CitywideButton
          phaseName="respond"
          text={t('phaseDialog.respond.cityWideChallenge')}
          onClick={onClick}
        />;
      </React.Fragment>
    );
  }
}

const translatedComponent = withTranslation()(withModuleTranslation()(PlayerTypesRespond));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
