
import {sectorName, neighborhoodName} from "../scenario";
import {gameId} from "./index";

export function myProps( client, state ){
  let scenarioName = state.scenario;
  let ret = {
    client: client,
    gameId: gameId
  };
  if( client >= 1 && client <= 6 ){
    let player = state.player[client];
    ret = {
      ...ret,
      sector:       sectorName( scenarioName, client ),
      neighborhood: neighborhoodName( scenarioName, client ),
      audio:        player.audio,
      tip:          player.tip,
      challenge:    player.challenge,
      resource:     player.resource,
      score:        player.score
    };
  }
  return ret;
}