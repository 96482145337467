import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import messages_en from './assets/locales/en/messages.json';
import hurricane_en from './assets/locales/en/hurricane.json';
import flood_en from './assets/locales/en/flood.json';
import earthquake_en from './assets/locales/en/earthquake.json';

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        messages: messages_en,
        hurricane: hurricane_en,
        flood: flood_en,
        earthquake: earthquake_en
      }
    },
    defaultNS: ['messages'],
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

export function withModuleTranslation() {
  return function Extend(WrappedComponent) {
    function EEWithModuleTranslation(props) {
      const { i18n, scenario } = props;
      let newProps = {...props};
      if (scenario) {
        newProps.tm = i18n.getFixedT(null, scenario);
      }
      return React.createElement(WrappedComponent, {
        ...newProps
      });
    }

    return EEWithModuleTranslation;
  };
}
