import React from 'react';
import Popup from 'reactjs-popup';

import i18n from '../../i18n';
import { getClassName } from '../../utils';

function NewsPopup({ title, content, author, date, ...otherProps }) {
  // otherProps may contain any of the props for Popup from the reactjs-popup library
  return (
    <div className="ee-popup-wrapper">
      <Popup {...otherProps} modal>
        {close => (
          <div className="ee-popup ee-popup-news">
            <button className="ee-popup-close-btn btn btn-link" onClick={close}>
              <span className="sr-only">{i18n.t('common.close')}</span>
            </button>
            <div className="ee-popup-title" dangerouslySetInnerHTML={{__html: title}} />
            <div className="ee-popup-content" dangerouslySetInnerHTML={{__html: content}} />
            {author ? (
              <div className="ee-popup-footer">
                <dl>
                  <dt>{i18n.t('newsItem.authorLabel')}</dt>
                  <dd className={`ee-news-source ee-news-source-${getClassName(author)}`}>
                    <span className="sr-only">{author}</span>
                  </dd>
                  <dt>{i18n.t('newsItem.publishedLabel')}</dt>
                  <dd>{date}</dd>
                </dl>
              </div>
            ) : null}
          </div>
        )}
      </Popup>
    </div>
  );
}

export default NewsPopup;

