import { useState, useEffect } from 'react';

import i18n from '../i18n';
import { scenario as allScenarios } from '../scenario';

export function getPlayers(players) {
  if (!players) return {};
  const playerCount = players.length;
  return players.slice(1, playerCount + 1); // Player 0 is the facilitator
}

export function getConnectedPlayerCount(players) {
  if (!players) return 0;
  return Object.values(players).filter(p => (p.connected)).length;
}

export function getAllPhaseNames() {
  let phases = {};
  Object.keys(allScenarios).map(scenario => {
    const scenarioPhases = getPhaseNames(i18n.t(`${scenario}:module.phases`, { returnObjects: true }));
    return phases[scenario] = scenarioPhases;
  });
  return phases;
}

export function getPhaseNames(phases) {
  if (!phases) return {};
  return phases.map(({ name, title }) => Object.assign({ name, title }));
}

export function getStatuses(statuses) {
  if (!statuses) return {};
  return statuses.map(status => {
    return { player: status.player.slice(1) };
  });
}

export function getClassName(value) {
  if (!value) return '';
  // Lowercase and replace spaces with hyphens.
  return value.replace(/\s+/g, '-').toLowerCase().replace(/[^a-z-]/g, '');
}

export function getActiveChallenge(challenges, currentScenario) {
  if (!challenges) return {};
  const activeChallengeIndex = challenges.findIndex(c => c.isActive);
  let activeChallenge = {};
  if (activeChallengeIndex >= 0) {
    activeChallenge.index = activeChallengeIndex;
    activeChallenge.key = currentScenario.challenges[activeChallengeIndex];
  }
  return activeChallenge;
}

export function getEndedChallenges(challenges, currentScenario) {
  if (!challenges) return [];
  const endedChallenges = challenges
    .map((c, index) => {
      return {
        key: currentScenario.challenges[index],
        isEnded: c.isEnded
      };
    })
    .filter(c => c.isEnded)
    .map(c => c.key);
  return endedChallenges;
}

export function getReceivedChallengeCount(challenges, playerIndex) {
  if (!challenges) return 0;
  return challenges.filter(c => (c.player[playerIndex].acknowledged)).length;
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function formatDate(epoch) {
  const date = new Date(epoch);
  return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
}

export function isEmptyObject(obj) {
  for (let key in obj) {
    return false;
  }
  return true;
}

export function isUserLoggedIn(user) {
  return (typeof user.id !== 'undefined');
}

export function isUserAdmin(user) {
  return isUserLoggedIn(user) &&
    user.roles && user.roles.includes('admin');
}

export function isUserFacilitator(client) {
  return (client === 'a');
}

export function isUserPlayer(client) {
  return (client >= 1 && client <= 6);
}

/* https://github.com/facebook/react/issues/5465#issuecomment-157888325 */
export function makeCancelable(promise) {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
}

/* https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs/36862446#36862446 */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

