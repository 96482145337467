import React from 'react';

import { FullPageWrapper } from '../../components/Wrapper';
import i18n from '../../i18n';

class Lobby extends React.Component {
  render() {
    return(
      <div className="ee-lobby-view">
        <FullPageWrapper withDoubleBackground>
          <main className="ee-connecting">
            <header>
              <h1 dangerouslySetInnerHTML={{__html: i18n.t('participant.connecting.title')}} />
            </header>
            <section className="ee-connecting-message">
              {i18n.t('participant.connecting.content')}
            </section>
          </main>
        </FullPageWrapper>
      </div>
    );
  }
}

export default Lobby;
