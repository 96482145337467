import React from 'react';
import Popup from 'reactjs-popup';

function Tooltip({ trigger, children }) {
  return (
    <div className="ee-tooltip-wrapper">
      <Popup trigger={trigger} position="top center">
        {children}
      </Popup>
    </div>
  );
}

export default Tooltip;
