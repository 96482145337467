import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ReactTimeout from 'react-timeout';

import Board from '../../components/player/Board';
import ChallengeBanner from '../../components/player/ChallengeBanner';
import EventAnimation from '../../components/player/EventAnimation';
import Footer from '../../components/player/Footer';
import NewsFeed from '../../components/player/NewsFeed';
import Tracker from '../../components/player/Tracker';
import { FullPageWrapper } from '../../components/Wrapper';
import { withModuleTranslation } from '../../i18n';
import { getActiveChallenge, getClassName, getEndedChallenges, isEmptyObject } from '../../utils';
import NewsPopup from '../../components/player/NewsPopup';
import GamePlayModal from '../../components/player/GamePlayModal';
import { scenario as allScenarios } from '../../scenario';

class GamePhaseRespond extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewsPopupOpen: false,
      newsProps: {},
      challengeBannerHeight: 0
    };
    this.challengeBannerRef = React.createRef();
    this.handleOpenChallengeModal = this.handleOpenChallengeModal.bind(this);
    this.handleCloseChallengeModal = this.handleCloseChallengeModal.bind(this);
    this.handleOpenNewsPopup = this.handleOpenNewsPopup.bind(this);
    this.handleCloseNewsPopup = this.handleCloseNewsPopup.bind(this);
  }

  componentDidMount() {
    // Always start at the top.
    window.scrollTo(0, 0);
    if (this.challengeBannerRef.current) {
      this.setState({ challengeBannerHeight: this.challengeBannerRef.current.clientHeight });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Move page back to the top when transitioning in either direction between
    // /2/2 and /2/3
    const { display: { part, screen } } = this.props;
    const prevPart = prevProps.display.part;
    const prevScreen = prevProps.display.screen;
    const transitionSplashToScroll =
      ((part === 2 && prevPart === 2) &&
        ((screen === 3 && prevScreen === 2) || (screen === 2 && prevScreen === 3)));
    if (transitionSplashToScroll) {
      window.scrollTo(0, 0);
    }
    let newChallengeBannerHeight = 0;
    if (this.challengeBannerRef.current) {
      newChallengeBannerHeight = this.challengeBannerRef.current.clientHeight;
    }
    if (prevState.challengeBannerHeight !== newChallengeBannerHeight) {
      this.setState({ challengeBannerHeight: newChallengeBannerHeight });
    }
  }

  handleOpenChallengeModal(challengeIndex) {
    // Automatically close the modal after a period of time.
    if (challengeIndex >= 0) {
      this.props.setTimeout(() => {
        this.handleCloseChallengeModal(challengeIndex);
      }, 60000);
    }
    this.setState({ showBanner: true });
  }

  handleCloseChallengeModal(challengeIndex) {
    if (challengeIndex >= 0) {
      this.props.challengeHide(challengeIndex);
    }
  }

  handleOpenNewsPopup(props) {
    this.setState({ isNewsPopupOpen: true, newsProps: props });
  }

  handleCloseNewsPopup() {
    this.setState({ isNewsPopupOpen: false, newsProps: {} });
  }

  render() {
    const { tm, scenario, display: { part, screen }, my: { neighborhood, challenge } } = this.props;
    const currentScenario = allScenarios[scenario];
    const neighborhoodName = tm(`module.neighborhoods.${neighborhood}`);
    const showNeighborhoodOverview = (part === 2 && screen === 2);
    const showNeighborhoodBoard = (part === 2 && screen === 3);
    let view = null;
    let hasChallengeBanner = false;
    let hasChallengeModal = false;
    let hasBlackout = false;
    let challengeBannerProps = {};
    let challengeModalProps = {};
    if (showNeighborhoodOverview) {
      view = <NeighborhoodOverview {...this.props} />;
    } else if (showNeighborhoodBoard) {
      const activeChallenge = getActiveChallenge(challenge, currentScenario);
      if (!isEmptyObject(activeChallenge)) {
        const activeChallengeIndex = activeChallenge.index;
        const challengeKey = activeChallenge.key;
        hasChallengeBanner = (activeChallenge.index >= 0);
        hasChallengeModal = ((activeChallenge.index >= 0) && challenge[activeChallengeIndex].isVisible);
        hasBlackout = (challengeKey === 'blackout');
        const challengeName = tm(`module.phases.1.actions.challenges.${challengeKey}.name`);
        const currentChallenge = tm(`module.participant.challenges.${challengeKey}`, { returnObjects: true });
        if (hasChallengeBanner) {
          challengeBannerProps = {
            name: challengeName,
            title: currentChallenge.title,
            description: currentChallenge.description,
            activeUntil: challenge[activeChallengeIndex].activeTill,
            bannerRef: this.challengeBannerRef
          };
        }
        if (hasChallengeModal) {
          challengeModalProps = {
            ...this.props,
            challengeIndex: activeChallengeIndex,
            name: challengeName,
            title: currentChallenge.title,
            content: currentChallenge.content,
            onOpen: this.handleOpenChallengeModal,
            onClose: this.handleCloseChallengeModal
          };
        }
      }
      view = <BoardAndAside {...this.props} currentScenario={currentScenario} hasBlackout={hasBlackout} onClickNews={this.handleOpenNewsPopup} />;
    }
    return (
      <React.Fragment>
        <div className={`ee-player-view ee-module-${scenario}`}>
          <div className={`ee-player-phase-view ee-player-respond-view ee-player-respond-view-${getClassName(neighborhoodName)}`}>
            {hasChallengeBanner ? (
              <React.Fragment>
                <Helmet>
                  <style type="text/css">{`
                    .ee-player-page .ee-player-game-wrapper { height: calc(100% - 95px - ${this.state.challengeBannerHeight}px); }
                 `}</style>
                </Helmet>
                <ChallengeBanner {...challengeBannerProps} />
              </React.Fragment>
            ) : null}
            {view}
            {showNeighborhoodBoard ? (
              <React.Fragment>
                {hasChallengeModal ? (
                  <Challenge {...challengeModalProps} />
                ) : null}
                <NewsPopup
                  open={this.state.isNewsPopupOpen}
                  {...this.state.newsProps}
                  onClose={this.handleCloseNewsPopup}
                />
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function Challenge({ t, challengeIndex, name, title, content, onOpen, onClose }) {
  const gamePlayModalProps = {
    isOpen: true,
    onOpen: (() => onOpen(challengeIndex)),
    onClose: (() => onClose(challengeIndex)),
    title: title,
    content: content,
    button: t('common.continue'),
    type: 'challenge',
    name: name
  };
  return <GamePlayModal {...gamePlayModalProps} />;
}

function NeighborhoodOverview({ t, tm, scenario, my: { neighborhood } }) {
  const neighborhoodName = tm(`module.neighborhoods.${neighborhood}`);
  const title = t('neighborhoodBeaconScreen.welcome');
  const message = tm(`module.participant.neighborhood.${neighborhood}.information`);
  return (
    <FullPageWrapper withBackground>
      <EventAnimation scenario={scenario} />
      <div className="ee-splash-view">
        <div className="ee-player-type-overview">
          <header>
            <div className="ee-neighborhood-welcome">{title}</div>
            <div className="ee-player-type"><span className="sr-only">{neighborhoodName}</span></div>
          </header>
          <section className="ee-player-type-information">{message}</section>
        </div>
      </div>
    </FullPageWrapper>
  );
}

function BoardAndAside(props) {
  const { t, tm, scenario, my: { neighborhood, challenge }, display: { eventStart }, reset, currentScenario, hasBlackout, onClickNews } = props;
  const neighborhoodName = tm(`module.neighborhoods.${neighborhood}`);
  const boardDescription = tm(`module.participant.respond.challenges.${neighborhood}.description`);
  const phaseName = tm('module.phases.1.name');
  const newsFeed = tm('module.participant.respond.aside.newsFeed', { returnObjects: true });
  const endedChallenges = getEndedChallenges(challenge, currentScenario);
  endedChallenges.forEach(challengeKey => {
    const currentChallenge = tm(`module.participant.challenges.${challengeKey}`, { returnObjects: true });
    newsFeed.items.unshift({
      title: currentChallenge.title,
      content: currentChallenge.content
    });
  });
  return (
    <React.Fragment>
      <div className="ee-player-game-wrapper">
        <div className="ee-player-content">
          <div className="ee-challenge-board">
            <header>
              <h1>{tm(`module.participant.respond.title`)}</h1>
            </header>
            <div className="ee-challenge-board-overview">
              <header>
                <h2><span className="sr-only">{neighborhoodName}</span></h2>
              </header>
              <section dangerouslySetInnerHTML={{__html: boardDescription}} />
            </div>
            <Board {...props} />
          </div>
        </div>
        <div className="ee-player-aside">
          <Tracker t={t} tm={tm} scenario={scenario} eventStart={eventStart} />
          <NewsFeed t={t} feed={newsFeed} onClickNews={onClickNews} />
        </div>
        {hasBlackout ? (
          <div className="ee-blackout" />
        ) : null}
      </div>
      <Footer tm={tm} scenario={scenario} phaseName={phaseName} reset={reset} />
    </React.Fragment>
  );
}

const timeoutSafeComponent = ReactTimeout(GamePhaseRespond);
const translatedComponent = withTranslation()(withModuleTranslation()(timeoutSafeComponent));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
