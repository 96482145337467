import React from 'react';
import {mapStateToPropsUser, mapDispatchToProps, Status} from "./action";
import {connect} from "react-redux";

import {createBrowserHistory} from 'history';

export const history = createBrowserHistory({ basename: process.env.REACT_APP_BASE_PATH });

export function stateToLocation( state ){
  let potentialReload = false;
  let pathname = '/';
  if( !state.gameId ){
    pathname = '/list';
  } else if( state.display ){
    pathname = `/play/${state.display.part}/${state.display.screen}`;
    potentialReload = true;
  } else {
    pathname = '/play';
    potentialReload = true;
  }

  let hash = '';
  if( state.client && state.gameId ){
    hash = `#${state.client}`;
  }

  let ret = {
    pathname,
    hash
  };
  console.log('stateToLocation ret',ret);

  // If our hash (clientid) has cleared, and we're playing the game,
  // close and re-open our connection
  let shouldReload = false;
  if( !hash && history.location.hash && potentialReload ){
    shouldReload = true;
  }

  // Only push it if something has changed.
  if (history.location.pathname !== pathname || history.location.hash !== hash) {
    history.push(ret);
  }

  if( shouldReload ){
    window.location.reload();
  }

  return ret;
}

export function pathDisplay( path ){
  let ret;
  console.log('pathDisplay',path);
  let d = path.substring(1).split('/');
  if( d[0] === 'play' && d.length >= 3 ){
    ret = {
      part:   parseInt( d[1], 10 ),
      screen: parseInt( d[2], 10 )
    }
  }
  return ret;
}

class History extends React.Component {

  constructor(props){
    super(props);

    history.listen( async (location, action) => {
      console.log('listen', action, location);
      if( action === 'POP' ){
        let display = pathDisplay( location.pathname );
        if( display ){
          let result = await this.props.displaySet( display );
          console.log('pop displaySet result',result);
          if( result.status === Status.error ){
            // Tried to go back, but not allowed, so go forward againpu
            history.goForward();
          }
        }
      }
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToPropsUser,mapDispatchToProps)(History);
