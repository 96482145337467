import React from 'react';
import JsxParser from 'react-jsx-parser';

import SoundEffect from './SoundEffect';
import Callout from './Callout';
import TimingTip from './TimingTip';
import PhaseComplete from './PhaseComplete';
import i18n from '../../i18n';
import TutorialTip from './TutorialTip';

function PhaseScript({ showTutorial, phase, section, script, onClickContinue, topRef }) {
  const scriptButton = script[section].button;
  const scriptAlert = script[section].alert;
  let hasContentTip = false;
  let hasActionTip = false;
  let contentTipProps = {};
  let actionTipProps = {};
  if (showTutorial && (phase === 0)) {
    switch (section) {
      case 0:
        hasContentTip = true;
        contentTipProps = {
          text: i18n.t('tooltip.soundButtonTip'),
          position: 'right',
          className: 'sound'
        };
        break;
      case 1:
        hasContentTip = true;
        contentTipProps = {
          text: i18n.t('tooltip.scrollScriptTip'),
          position: 'right',
          className: 'scroll'
        };
        break;
      default:
        break;
    }
    if (script[section].showRedButtonTip) {
      hasActionTip = true;
      actionTipProps = {
        text: i18n.t('tooltip.selectingRedActionButtonTip'),
        position: 'top',
        className: 'continue'
      }
    }
  }
  return (
    <React.Fragment>
      {hasContentTip ? (
        <div className="ee-tutorial">
          <TutorialTip {...contentTipProps} />
        </div>
      ) : null}
      <div className="ee-scroll-pane" ref={topRef}>
        <section className="ee-phase-script-content">
          {script.map((content, index) => {
            if (index <= section) {
              return (
                <div className="ee-phase-script-section-content" key={`script-section-${index}`}>
                  <JsxParser
                    components={{ SoundEffect, Callout, TimingTip, PhaseComplete }}
                    jsx={script[index].content}
                    renderInWrapper={false}
                  />
                </div>
              );
            }
            return null;
          })}
        </section>
        <section className="ee-phase-script-action">
          <React.Fragment>
            {hasActionTip ? (
              <div className="ee-tutorial">
                <TutorialTip {...actionTipProps} />
              </div>
            ) : null}
            {scriptButton ? (
              <button
                className="ee-continue-btn btn btn-link"
                onClick={onClickContinue}
                key={`continue-${phase}-${section}`}
              >
                {scriptButton}
              </button>
            ) : (
              scriptAlert ? (
                <div className="ee-script-action-alert" dangerouslySetInnerHTML={{ __html: scriptAlert }} />
              ) : null
            )}
          </React.Fragment>
        </section>
      </div>
    </React.Fragment>
  );
}

export default PhaseScript;

