import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import PlayerTypesRecover from '../../components/PlayerTypesRecover';
import Footer from '../../components/player/Footer';
import { withModuleTranslation } from '../../i18n';
import { scenario as allScenarios } from '../../scenario';
import { getClassName } from '../../utils';

class GamePhaseRecover extends React.Component {
  componentDidMount() {
    // Always start at the top.
    window.scrollTo(0, 0);
  }

  render() {
    const { tm, scenario, display: { part, screen }, player, reset } = this.props;
    const currentScenario = allScenarios[scenario];
    const phaseName = tm('module.phases.2.name');
    // We want to stay on the previous screen until the neighborhood scoring is
    // finished, not when the facilitator transitions to start the scoring.
    // So we always want to be showing the scoring screen for the previous
    // neighborhood until they go to the next (or the splash screen in the case
    // of the first neighborhood).
    const showScoringOverview = (part === 3 && screen <= 2);
    const showNeighborhoodScoring = (part === 3 && (screen > 2 && screen <= 8));
    const showCityScoring =
      ((part === 3 && screen === 9) || (part === 4 && screen === 1));
    // Neighborhoods are 0 indexed, and since we are not displaying until
    // moving onto the next screen, we need to subtract 3.
    const neighborhoodScoringProps = {
      tm,
      neighborhoodKeys: currentScenario.neighborhood.slice(1),
      currentNeighborhood: screen - 3,
      players: player.slice(1)
    };
    return (
      <div className={`ee-player-view ee-module-${scenario}`}>
        <div className="ee-player-phase-view ee-player-recover-view">
          <div className="ee-player-game-wrapper">
            <div className="ee-scoring-view">
              <ScoringOverview show={showScoringOverview} />
              <NeighborhoodScoring show={showNeighborhoodScoring} {...neighborhoodScoringProps} />
              {showCityScoring ? (
                <div className="ee-scoring-city">
                  <PlayerTypesRecover currentScenario={currentScenario} />
                </div>
              ) : null}
            </div>
          </div>
          <Footer tm={tm} scenario={scenario} phaseName={phaseName} reset={reset} />
        </div>
      </div>
    );
  }
}

function ScoringOverview({ show }) {
  const transitionProps = {
    in: show,
    unmountOnExit: true,
    classNames: 'ee-animate',
    timeout: 600
  };
  return (
    <CSSTransition {...transitionProps}>
      <header className="ee-scoring-overview">
        <h1>how did each <strong>neighborhood fare?</strong></h1>
      </header>
    </CSSTransition>
  );
}

function NeighborhoodScoring({ show, tm, neighborhoodKeys, currentNeighborhood, players }) {
  return (
    <React.Fragment>
      {show ? (
        <React.Fragment>
          {neighborhoodKeys.map((neighborhoodKey, neighborhoodIndex) => {
            const neighborhood = tm(`module.participant.recover.scores.${neighborhoodKey}`, {returnObjects: true});
            const score = players[neighborhoodIndex].score;
            const transitionProps = {
              in: (currentNeighborhood === neighborhoodIndex),
              appear: true,
              classNames: 'ee-animate',
              unmountOnExit: true,
              timeout: {
                enter: 600,
                exit: 600
              },
              key: `neighborhood-transition-${neighborhoodIndex}`
            };
            const scoreSections = [...Array(score.needMet.length + 1)];
            const finalScore = score.completed;
            return (
              <CSSTransition {...transitionProps}>
                <div
                  className={`ee-scoring-neighborhood ee-scoring-neighborhood-${getClassName(neighborhood.name)}`}
                  key={`scoring-neighborhood-${neighborhoodIndex}`}
                >
                  <header className="ee-scoring-header">
                    <div className="ee-scoring-player">
                      <span className="sr-only">{neighborhood.name}</span>
                    </div>
                  </header>
                  <section className="ee-scoring">
                    <header className="ee-scoring-question">{neighborhood.question}</header>
                    <ul className="ee-scoring-options">
                      {neighborhood.options.map((option, optionIndex) => {
                        const selectedClassName = ((optionIndex === (score.decision - 1)) ? `ee-scoring-option-selected` : '');
                        return (
                          <li
                            className={`ee-scoring-option ee-scoring-option-${optionIndex} ${selectedClassName}`}
                            key={`scoring-option-${optionIndex}`}
                          >
                            {option.description}
                          </li>
                        );
                      })}
                    </ul>
                    <div className="ee-scoring-total">
                      <ul className="ee-scoring-total-background">
                        {scoreSections.map((x, scoreSectionIndex) => {
                          return (
                            <li
                              className="ee-animate"
                              key={`score-background-${scoreSectionIndex}`}
                            >
                              {scoreSectionIndex * 100}
                            </li>
                          );
                        })}
                      </ul>
                      <ul className="ee-scoring-total-score">
                        {scoreSections.map((x, scoreSectionIndex) => {
                          const isFinal = (finalScore === scoreSectionIndex);
                          const progressClassName =
                            (finalScore > scoreSectionIndex ? 'ee-score-achieved' :
                              (isFinal ? 'ee-score-final' : ''));
                          return (
                            <li
                              className={`${progressClassName} ee-animate`}
                              key={`score-foreground-${scoreSectionIndex}`}
                            >
                              {isFinal ? (
                                scoreSectionIndex * 100
                              ) : null}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </section>
                </div>
              </CSSTransition>
            );
          })}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

const translatedComponent = withTranslation()(withModuleTranslation()(GamePhaseRecover));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
