import React from 'react';
import { mapStateToPropsUser,mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import JoinPage from './Join';
import LobbyPage from './Lobby'
import GamePhasePreparePage from './GamePhasePrepare';
import GamePhaseRespondPage from './GamePhaseRespond';
import GamePhaseRecoverPage from './GamePhaseRecover';
import GamePhaseAdaptPage from './GamePhaseAdapt';
import CreditsPage from './Credits';
import GameAudio from '../../components/player/GameAudio';
import { stateToLocation as location, history } from '../../History';
import { isUserPlayer } from '../../utils';
import i18n from '../../i18n';
import ConfirmPopup from '../../components/ConfirmPopup';

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSoundModalOpen: true
    };
    this.handleCloseSoundModal = this.handleCloseSoundModal.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    location(nextProps);
    return null;
  }

  handleCloseSoundModal() {
    this.setState({ isSoundModalOpen: false });
  }

  render() {
    const { scenario, isDemo, my: { client, audio }, stopAudio } = this.props;
    const confirmSoundProps = {
      title: i18n.t('soundCheck.title'),
      content: i18n.t('soundCheck.description'),
      button: i18n.t('soundCheck.confirm')
    };
    return (
      <React.Fragment>
        <Helmet>
          <body className={`ee-player-page ${isDemo ? 'ee-test-game-page' : ''}`} />
        </Helmet>
        {isDemo ? (
          <div className="ee-alert-banner">
            <div className="ee-alert ee-alert-info">
              <header>
                <h1>{i18n.t('banner.playerTestGameBannerTitle')}</h1>
              </header>
              <p>{i18n.t('banner.playerTestGameBannerDescription')}</p>
            </div>
          </div>
        ) : null}
        {isUserPlayer(client) ? (
          <React.Fragment>
            <Router history={history}>
              <Switch>
                <Route exact path="/play/0/1" component={LobbyPage} />
                <Route path="/play/1" component={GamePhasePreparePage} />
                <Route exact path="/play/2/1" component={GamePhasePreparePage} />
                <Route path="/play/2" component={GamePhaseRespondPage} />
                <Route path="/play/3" component={GamePhaseRecoverPage} />
                <Route exact path="/play/4/1" component={GamePhaseRecoverPage} />
                <Route path="/play/4" component={GamePhaseAdaptPage} />
                <Route path="/play/5" component={CreditsPage} />
                <Route path="/" component={JoinPage} />
              </Switch>
            </Router>
            <GameAudio scenario={scenario} playing={audio} client={client} stopAudio={stopAudio} />
            <ConfirmPopup
              isOpen={this.state.isSoundModalOpen}
              onClose={this.handleCloseSoundModal}
              onConfirm={this.handleCloseSoundModal}
              {...confirmSoundProps}
            />
          </React.Fragment>
        ) : (
          <JoinPage />
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(Player);
