import {Type} from '../action';

const defaultState = false;

export function isDemo( state = defaultState, action ){
  switch( action.type ){
    case Type.state:        return action.state.isDemo;
    case Type.setDemo:      return action.isDemo;
    case Type.reset:        return defaultState;
    default:                return state;
  }
}
