import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactTimeout from 'react-timeout';

import Footer from '../../components/player/Footer';
import GamePlayModal from "../../components/player/GamePlayModal";
import TabbedPopup from '../../components/player/TabbedPopup';
import { withModuleTranslation } from '../../i18n';
import { scenario as allScenarios } from '../../scenario';
import { getClassName } from '../../utils';

class GamePhasePrepare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isResourcesPopupOpen: false
    };
    this.handleOpenTipModal = this.handleOpenTipModal.bind(this);
    this.handleCloseTipModal = this.handleCloseTipModal.bind(this);
    this.handleOpenResourcesPopup = this.handleOpenResourcesPopup.bind(this);
    this.handleCloseResourcesPopup = this.handleCloseResourcesPopup.bind(this);
  }

  componentDidMount() {
    // Always start at the top.
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    // Move page back to the top when transitioning in either direction between
    // /1/2 and /1/3
    const { display: { part, screen } } = this.props;
    const prevPart = prevProps.display.part;
    const prevScreen = prevProps.display.screen;
    const transitionSplashToScroll =
      ((part === 1 && prevPart === 1) &&
        ((screen === 2 && prevScreen === 3) || (screen === 3 && prevScreen === 2)));
    if (transitionSplashToScroll) {
      window.scrollTo(0, 0);
    }
  }

  handleOpenTipModal(tipIndex) {
    // Automatically close the tip after a period of time.
    if (tipIndex >= 0) {
      this.props.setTimeout(() => {
        this.handleCloseTipModal(tipIndex);
      }, 60000);
    }
  }

  handleCloseTipModal(tipIndex) {
    if (tipIndex >= 0) {
      this.props.tipHide(tipIndex);
    }
  }

  handleOpenResourcesPopup() {
    this.setState({ isResourcesPopupOpen: true });
  }

  handleCloseResourcesPopup() {
    this.setState({ isResourcesPopupOpen: false });
  }

  render() {
    const { tm, scenario, display: { part, screen }, my: { sector } } = this.props;
    const sectorName = tm(`module.sectors.${sector}`);
    const resources = tm('module.participant.prepare.resources.dialog', { returnObjects: true });
    const showSectorOverview = (part === 1 && screen < 3);
    const showCityAndSectorOverview =
      ((part === 1 && screen === 3) || (part === 2 && screen === 1));
    let view = null;
    if (showSectorOverview) {
      view = <SectorOverview {...this.props} />;
    } else if (showCityAndSectorOverview) {
      view = <CityAndSectorOverview {...this.props} onClickResources={this.handleOpenResourcesPopup} />;
    }
    return (
      <div className={`ee-player-view ee-module-${scenario}`}>
        <div className={`ee-player-phase-view ee-player-prepare-view ee-player-prepare-view-${getClassName(sectorName)}`}>
          {view}
          {showCityAndSectorOverview ? (
            <React.Fragment>
              <TabbedPopup
                open={this.state.isResourcesPopupOpen}
                onClose={this.handleCloseResourcesPopup}
                title={resources.title}
                tabs={resources.sector[sector].tabset}
              />
              <Tip {...this.props} onOpen={this.handleOpenTipModal} onClose={this.handleCloseTipModal} />
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

function Tip({ t, tm, scenario, my: { tip }, onOpen, onClose }) {
  // Tips will never be visible but not available, so we don't have to check available.
  const availableTipIndex = tip.findIndex(t => t.isVisible);
  const hasTip = (availableTipIndex >= 0);
  if (!hasTip) {
    return null;
  }
  const tipKey = allScenarios[scenario].tip[availableTipIndex];
  const currentTip = tm(`module.participant.tips.${tipKey}`, {returnObjects: true});
  const gamePlayModalProps = {
    isOpen: true,
    onOpen: (() => onOpen(availableTipIndex)),
    onClose: (() => onClose(availableTipIndex)),
    title: currentTip.title,
    content: currentTip.content,
    button: t('common.continue'),
    type: 'tip'
  };
  return <GamePlayModal {...gamePlayModalProps} />;
}

function SectorOverview({ tm, my: { client, sector } }) {
  const sectorInformation = tm(`module.participant.sector.${sector}.information`, { returnObjects: true });
  return (
    <div className="ee-splash-view">
      <div className="ee-player-type-overview">
        <SectorHeader tm={tm} client={client} sector={sector} />
        <SectorInformation infoList={sectorInformation} />
      </div>
    </div>
  );
}

function CityAndSectorOverview({ tm, scenario, my: { client, sector }, reset, onClickResources }) {
  const phaseName = tm('module.phases.0.name');
  const content = tm(`module.participant.prepare.content`, { returnObjects: true });
  const asideTitle = tm('module.participant.prepare.aside.title');
  const resourcesButton = tm('module.participant.prepare.resources.button');
  const sectorInfoList = tm(`module.participant.sector.${sector}.information`, { returnObjects: true });
  return (
    <React.Fragment>
      <div className="ee-player-game-wrapper">
        <div className="ee-player-content">
          <div className="ee-city-overview">
            <header>
              <h1>{tm(`module.participant.prepare.title`)}</h1>
            </header>
            <CityInformation content={content} />
          </div>
        </div>
        <div className="ee-player-aside">
          <div className="ee-player-type-overview">
            <SectorHeader tm={tm} client={client} sector={sector} title={asideTitle} />
            <SectorInformation
              button={resourcesButton}
              onClickResources={onClickResources}
              infoList={sectorInfoList}
            />
          </div>
        </div>
      </div>
      <Footer tm={tm} scenario={scenario} phaseName={phaseName} reset={reset} />
    </React.Fragment>
  );
}

function SectorHeader({ tm, sector, title }) {
  const sectorName = tm(`module.sectors.${sector}`);
  return (
    <React.Fragment>
      <div className={`ee-player-type`}>
        <span className="sr-only">{sectorName}</span>
      </div>
      {title ? (
        <header>
          <h1>{title}</h1>
        </header>
      ) : null}
    </React.Fragment>
  );
}

function SectorInformation({ button, onClickResources, infoList }) {
  return (
    <section className="ee-player-type-information">
      {button ? (
        <div className="ee-actions">
          <button className="ee-resources-btn btn btn-link" onClick={onClickResources}>
            {button}
          </button>
        </div>
      ) : null}
      <ul>
        {infoList.map((info, index) => {
          return (
            <li key={`sector-information-${index}`}>{info}</li>
          );
        })}
      </ul>
    </section>
  );
}

function CityInformation({ content }) {
  return (
    <React.Fragment>
      {content.map((section, sectionIndex) => {
        return (
          <div className="ee-city-data-section" key={`city-data-${sectionIndex}`}>
            <header>
              <h2>{section.label}</h2>
              {section.legend ? (
                <h3>{section.legend}</h3>
              ) : null}
            </header>
            <section className="ee-city-data">
              <div className={`ee-city-data-${section.name}`}>
                {section.value ? (
                  <div className="ee-city-data-value">{section.value}</div>
                ) : null}
                {section.data ? (
                  section.data.map((datum, datumIndex) => {
                    let value = null;
                    if (datum.value) {
                      if (section.name === 'weather') {
                        value =
                          <div className="ee-city-datum-value">
                            {datum.value.split('').map((char, charIndex) => {
                              return (
                                <span
                                  className="ee-city-datum-value-letter"
                                  key={`city-datum-value-letter-${charIndex}`}
                                >
                                  {char}
                                </span>
                              );
                            })}
                          </div>;
                      } else {
                        value =
                          <div
                            className="ee-city-datum-value"
                            dangerouslySetInnerHTML={{__html: datum.value}}
                          />;
                      }
                    }
                    return (
                      <div className={`ee-city-datum ee-city-datum-${datum.name}`} key={`city-datum-${datumIndex}`}>
                        <div className="ee-city-datum-label">{datum.label}</div>
                        {value}
                        {datum.asterisk ? (
                          <div className="ee-city-datum-asterisk">{datum.asterisk}</div>
                        ) : null}
                      </div>
                    );
                  })
                ) : null}
                {(section.name === 'environment') ? (
                  <div className="ee-city-data-image" />
                ) : null}
              </div>
            </section>
          </div>
        );
      })}
    </React.Fragment>
  );
}

const timeoutSafeComponent = ReactTimeout(GamePhasePrepare);
const translatedComponent = withTranslation()(withModuleTranslation()(timeoutSafeComponent));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
