import React from 'react';
import { mapStateToPropsUser } from '../../action';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import i18n from '../../i18n';
import * as Auth from '../../action/auth';
import { history } from '../../History';

class AccountForm extends React.Component {
  constructor(props) {
    super(props);
    const { isNew, userInfo } = props;
    this.state = {
      firstName: (isNew ? '' : userInfo.firstName),
      lastName: (isNew ? '' : userInfo.lastName),
      email: (isNew ? '' : userInfo.email),
      password: '',
      confirmPassword: '',
      phoneNumber: (isNew ? '' : userInfo.phoneNumber),
      orgName: (isNew ? '' : userInfo.orgName),
      orgType: (isNew ? '' : userInfo.orgType),
      orgTypeOther: (isNew ? '' : userInfo.orgTypeOther ),
      touched: {
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        confirmPassword: false,
        phoneNumber: false,
        orgName: false,
        orgType: false,
        orgTypeOther: false
      }
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleBlurInput = this.handleBlurInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    toast.dismiss(this.createErrorId);
  }

  handleChangeInput(event) {
    console.log(event);
    this.setState({ [event.target.name]: event.target.value });
  }

  handleBlurInput(event) {
    const { isNew } = this.props;
    const fieldName = event.target.name;
    // Only handle onBlur for password when this is a new account.
    if (isNew || (fieldName !== 'password' && fieldName !== 'confirmPassword')) {
      this.setState({ touched: { ...this.state.touched, [fieldName]: true } });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    toast.dismiss(this.createErrorId);
    const { isNew } = this.props;
    if (this.isFormValid(isNew)) {
      const now = Date.now();
      let user = {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        orgName: this.state.orgName,
        orgType: this.state.orgType,
        orgTypeOther: this.state.orgTypeOther,
        updated: now
      }
      if (isNew) {
        user.created = now;
        user.password = this.state.password;
        Auth.createUser(user)
          .catch(err => {
            if (err.code === 409) {
              this.showError(i18n.t('createAccountScreen.accountExistsError'));
            } else {
              this.showError(i18n.t('createAccountScreen.accountCreateFailed'));
            }
          });
      } else {
        if (this.state.password !== '') {
          user.password = this.state.password;
        }
        Auth.updateUser(user)
          .then(res => {
            toast.success(i18n.t('accountSettingsScreen.settingsUpdated'), { autoClose: 8000 });
            history.push('/list');
          })
          .catch(err => {
            if (err.code === 409) {
              this.showError(i18n.t('accountSettingsScreen.accountExistsError'));
            } else {
              this.showError(i18n.t('accountSettingsScreen.accountUpdateFailed'));
            }
          });
      }
    }
  }

  showError(message) {
    this.createErrorId = toast.error(message);
  }

  isFormValid(isNew) {
    const errors = this.validate(isNew);
    return !(Object.values(errors).some(val => val));
  }

  hasFieldError(field, errors) {
    const hasError = errors[field];
    const shouldShow = this.state.touched[field];
    return (hasError ? shouldShow : false);
  }

  validate(isNew) {
    const passwordMismatch = (this.state.password !== this.state.confirmPassword);
    const passwordTooShort = (this.state.password.length < 6);
    const wrongEmailFormat = !(/[^\s]+@[^\s]+/.test(this.state.email));
    let errors = {
      firstName: (this.state.firstName === ''),
      lastName: (this.state.lastName === ''),
      email: (this.state.email === '' || wrongEmailFormat),
      password: passwordMismatch,
      confirmPassword: passwordMismatch,
      phoneNumber: (this.state.phoneNumber === ''),
      orgType: (this.state.orgType === ''),
      orgTypeOther: (this.state.orgType === 'Other' && this.state.orgTypeOther === '')
    };
    // Password can be blank if this is not a new account, it will just be
    // ignored.
    if (isNew) {
      errors.password = (errors.password || this.state.password === '' || passwordTooShort);
      errors.confirmPassword = (errors.confirmPassword || this.state.confirmPassword === '');
    }
    return errors;
  }

  orgTypeObject( orgType ){
    if( orgType ){
      return {
        label: orgType,
        value: orgType,
        target: {
          name: "orgType",
          value: orgType
        }
      }
    }
  }

  render() {
    const { isNew, submitText } = this.props;
    const errorClassName = 'ee-field-error';
    const errors = this.validate(isNew);
    const orgTypes = i18n.t('account.orgTypes',{returnObjects:true});
    return(
      <form className="ee-form" onSubmit={this.handleSubmit}>
        <div className="ee-form-body">
          <fieldset>
            <legend>{i18n.t('account.personalInformationLegend')}</legend>
            <div className="ee-form-row">
              <label htmlFor="first-name" className="sr-only">{i18n.t('account.firstNamePlaceholder')}</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={this.state.firstName}
                placeholder={i18n.t('account.firstNamePlaceholder')}
                required
                className={this.hasFieldError('firstName', errors) ? `${errorClassName}` : ''}
                onChange={this.handleChangeInput}
                onBlur={this.handleBlurInput}
              />
              <label htmlFor="last-name" className="sr-only">{i18n.t('account.lastNamePlaceholder')}</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={this.state.lastName}
                placeholder={i18n.t('account.lastNamePlaceholder')}
                required
                className={this.hasFieldError('lastName', errors) ? `${errorClassName}` : ''}
                onChange={this.handleChangeInput}
                onBlur={this.handleBlurInput}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>{i18n.t('account.accountInformationLegend')}</legend>
            <label htmlFor="email" className="sr-only">{i18n.t('account.emailPlaceholder')}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={this.state.email}
              placeholder={i18n.t('account.emailPlaceholder')}
              required
              className={this.hasFieldError('email', errors) ? `${errorClassName}` : ''}
              onChange={this.handleChangeInput}
              onBlur={this.handleBlurInput}
            />
            <div className="ee-form-row">
              <label htmlFor="password" className="sr-only">{i18n.t('account.passwordPlaceholder')}</label>
              <input
                type="password"
                id="password"
                name="password"
                value={this.state.password}
                placeholder={i18n.t('account.passwordPlaceholder')}
                required
                className={this.hasFieldError('password', errors) ? `${errorClassName}` : ''}
                onChange={this.handleChangeInput}
                onBlur={this.handleBlurInput}
              />
              <label htmlFor="confirm-password" className="sr-only">{i18n.t('account.confirmPasswordPlaceholder')}</label>
              <input
                type="password"
                id="confirm-password"
                name="confirmPassword"
                value={this.state.confirmPassword}
                placeholder={i18n.t('account.confirmPasswordPlaceholder')}
                required
                className={this.hasFieldError('confirmPassword', errors) ? `${errorClassName}` : ''}
                onChange={this.handleChangeInput}
                onBlur={this.handleBlurInput}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>{i18n.t('account.additionalInformationLegend')}</legend>
            <div className="ee-form-row">
              <label htmlFor="phone-number" className="sr-only">{i18n.t('account.phoneNumberPlaceholder')}</label>
              <input
                type="text"
                id="phone-number"
                name="phoneNumber"
                value={this.state.phoneNumber}
                placeholder={i18n.t('account.phoneNumberPlaceholder')}
                required
                className={this.hasFieldError('phoneNumber', errors) ? `${errorClassName}` : ''}
                onChange={this.handleChangeInput}
                onBlur={this.handleBlurInput}
              />
              <label htmlFor="org-name" className="sr-only">{i18n.t('account.orgNamePlaceholder')}</label>
              <input
                type="text"
                id="org-name"
                name="orgName"
                value={this.state.orgName}
                placeholder={i18n.t('account.orgNamePlaceholder')}
                required
                className={this.hasFieldError('orgName', errors) ? `${errorClassName}` : ''}
                onChange={this.handleChangeInput}
                onBlur={this.handleBlurInput}
              />
            </div>
            <label htmlFor="org-type-select" className="sr-only">{i18n.t('account.orgTypePlaceholder')}</label>
            <Select
              id="org-type-select"
              name="orgType"
              value={this.orgTypeObject( this.state.orgType )}
              placeholder={i18n.t('account.orgTypePlaceholder')}
              required
              className={this.hasFieldError('orgType', errors) ? `ee-form-select ${errorClassName}` : 'ee-form-select'}
              classNamePrefix="ee-form-select-"
              onChange={this.handleChangeInput}
              onBlur={this.handleBlurInput}
              options={orgTypes.map( orgType => this.orgTypeObject( orgType ) )}
            />
            {
              (this.state.orgType === orgTypes[orgTypes.length-1]) &&
              <div>
                  <label htmlFor="org-type-other" className="sr-only">{i18n.t('account.orgTypeOtherPlaceholder')}</label>
                  <input
                    type="text"
                    id="org-type-other"
                    name="orgTypeOther"
                    value={this.state.orgTypeOther}
                    placeholder={i18n.t('account.orgTypeOtherPlaceholder')}
                    required
                    className={this.hasFieldError('orgTypeOther', errors) ? `${errorClassName}` : ''}
                    onChange={this.handleChangeInput}
                    onBlur={this.handleBlurInput}
                  />
                </div>
            }
          </fieldset>
        </div>
        <div className="ee-form-actions">
          <button
            type="submit"
            className="ee-save-account-btn btn btn-link"
            disabled={!this.isFormValid(isNew)}
            onClick={this.handleSubmit}
          >
            {submitText}
          </button>
          {isNew ? (
            <Link to="/account/login">
              {i18n.t('createAccountScreen.signIn')}
            </Link>
          ) : null}
        </div>
      </form>
    );
  }
}

export default connect(mapStateToPropsUser, null)(AccountForm);
