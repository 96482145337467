import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Footer from '../../components/player/Footer';
import { withModuleTranslation } from '../../i18n';

class GamePhaseAdapt extends React.Component {
  render() {
    const { tm, scenario, reset } = this.props;
    const phaseName = tm('module.phases.3.name');
    const goals = tm('module.participant.adapt.keyLearnings', { returnObjects: true });
    return (
      <div className={`ee-player-view ee-module-${scenario}`}>
        <div className="ee-player-phase-view ee-player-adapt-view">
          <div className="ee-player-game-wrapper">
            <div className="ee-scroll-pane">
              <div className="ee-goals-view">
                <header className="ee-goals-header"></header>
                <section className="ee-goals">
                  <header className="ee-goals-title">{goals.title}</header>
                  <ul className="ee-goals-list">
                    {goals.items.map((goal, index) => {
                      return (
                        <li key={`goal-${index}`}>
                          <div className="ee-goal-number">{index + 1}</div>
                          <div className="ee-goal-text">{goal}</div>
                        </li>
                      );
                    })}
                  </ul>
                </section>
              </div>
            </div>
          </div>
          <Footer tm={tm} scenario={scenario} phaseName={phaseName} reset={reset} />
        </div>
      </div>
    );
  }
}

const translatedComponent = withTranslation()(withModuleTranslation()(GamePhaseAdapt));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
