import React from 'react';
import { OutboundLink } from 'react-ga';

import i18n from '../i18n';
import { getClassName, getCurrentYear } from '../utils';

function Footer() {
  const sponsors = i18n.t('sponsors', { returnObjects: true });
  return (
    <footer className="ee-footer">
      <ul className="ee-sponsor-list">
        {sponsors.map((sponsor, index) => {
          const name = sponsor.name;
          const url = sponsor.url;
          return (
            <li className={`ee-sponsor ee-sponsor-${getClassName(name)}`} key={`sponsor-${index}`}>
              <OutboundLink eventLabel={url} to={url}>
                <span className="sr-only">{name}</span>
              </OutboundLink>
            </li>
          );
        })}
      </ul>
      <p
        className="ee-copyright"
        dangerouslySetInnerHTML={{__html: i18n.t('global.copyright', { year: getCurrentYear() })}}
      />
    </footer>
  );
}

export default Footer;
