import {Type} from '../action';

const defaultState = {
  pending: true
};

export function userInfo( state = defaultState, action ){
  console.log('user',state,action);
  switch( action.type ){
    case Type.setUser:      return action.user || defaultState;
    default:                return state;
  }
}
