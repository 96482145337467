import React from "react";

import i18n from '../../i18n';

function TimingTip({ children }) {
  return (
    <blockquote className="ee-callout-with-banner ee-timing-tip">
      <div className="ee-callout-banner">
        <span className="ee-callout-banner-label">{i18n.t('facilitator.timingTipLabel')}</span>
      </div>
      <div className="ee-callout-content">{children}</div>
    </blockquote>
  );
}

export default TimingTip;
