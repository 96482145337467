import React from 'react';
import {Router, Switch, Route, NavLink, Redirect} from 'react-router-dom';
import {mapStateToPropsUser} from "../../action";
import {connect} from "react-redux";

import { history } from '../../History';

import {GameData,UserData} from './GameData';
import {Helmet} from "react-helmet";
import {FullPageWrapper} from "../../components/Wrapper";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class AdminPage extends React.Component {
  render(){
    return (
      <FullPageWrapper withBackground withHeader withFooter>
        <Header />
        <main className="ee-list-games-view ee-admin-view">
          <header className="ee-list-games-header">
            <h1>Game Administration</h1>
          </header>
          <div className='ee-admin-menu'>
            <div>
              <NavLink to="gameData" className="btn btn-link ee-admin-btn">Download Game Data</NavLink>
            </div>
            <div>
              <NavLink to="userData" className="btn btn-link ee-admin-btn">Download User Data</NavLink>
            </div>
          </div>
        </main>
        <Footer/>
      </FullPageWrapper>
    )
  }
}

class Admin extends React.Component {

  render(){
    return (
      <React.Fragment>
        <Helmet>
          <body className={`ee-facilitator-page`} />
        </Helmet>
        <Router history={history}>
          <Switch>
            <Route path='/admin/gameData' component={GameData} />
            <Route path='/admin/gamedata' component={GameData} />
            <Route path='/admin/userData' component={UserData} />
            <Route path='/admin/userdata' component={UserData} />
            <Route strict path='/admin/'         component={AdminPage}/>
            <Route exact  path='/admin'><Redirect to="admin/"/></Route>
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToPropsUser, null)(Admin);
