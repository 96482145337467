import React from 'react';
import ReactHowler from 'react-howler';

import { scenario as allScenarios } from '../../scenario';

function GameAudio({ scenario, playing, client, stopAudio }) {
  if (!allScenarios[scenario]) {
    return null;
  }
  const sounds = allScenarios[scenario].audio;
  return (
    <React.Fragment>
      {Object.keys(sounds).map((soundKey, index) => {
        const sound = sounds[soundKey];
        if (sound) {
          return (
            <ReactHowler
              src={[sound.src]}
              playing={playing === soundKey}
              loop={sound.loop}
              key={`game-audio-${index}`}
              onEnd={() => stopAudio(client)}
            />
          );
        }
        return null;
      })}
    </React.Fragment>
  );
}

export default GameAudio;
