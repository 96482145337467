import React from 'react';
import { mapStateToPropsUser,mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';

import i18n from '../../i18n';
import { FullPageWrapper } from '../../components/Wrapper';
import Footer from '../../components/Footer';

class Join extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gameId: ''
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeInput(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.isFormValid()) {
      this.props.setGameId(this.state.gameId);
    }
  }

  isFormValid() {
    return (this.state.gameId !== '' && this.state.gameId.length === 6);
  }

  render() {
    return (
      <FullPageWrapper withBackground withFooter>
        <main className="ee-join-view">
          <div className="ee-join">
            <header>
              <h1>
                <span className="sr-only">{i18n.t('global.title')}</span>
              </h1>
              <h2>{i18n.t('joinGameScreen.title')}</h2>
              <p>{i18n.t('joinGameScreen.gameCodeLabel')}</p>
            </header>
            <form className="ee-form" onSubmit={this.handleSubmit}>
              <div className="ee-form-body">
                <label htmlFor="game-id" className="sr-only">{i18n.t('joinGameScreen.placeholder')}</label>
                <input
                  type="text"
                  id="game-id"
                  name="gameId"
                  value={this.state.gameId}
                  placeholder={i18n.t('joinGameScreen.placeholder')}
                  autoCapitalize="characters"
                  autoFocus
                  onChange={this.handleChangeInput}
                />
              </div>
              <div className="ee-form-actions">
                <button
                  type="submit"
                  className="ee-submit-btn btn btn-link"
                  disabled={!this.isFormValid()}
                  onClick={this.handleSubmit}
                >
                  {i18n.t('joinGameScreen.joinGameButton')}
                </button>
              </div>
            </form>
          </div>
        </main>
        <Footer />
      </FullPageWrapper>
    );
  }
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(Join);

