import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import i18n, { withModuleTranslation } from '../../i18n';
import { getPhaseNames } from '../../utils';
import Tooltip from '../Tooltip';
import TutorialTip from './TutorialTip';

class PhaseFooter extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickMute = this.handleClickMute.bind(this);
  }

  handleClickMute() {
    this.props.stopAudio();
  }

  render() {
    const { t, tm, isDemo, currentPhase, display: { screen }, gameId, onClickQuit } = this.props;
    const allPhases = getPhaseNames(tm('module.phases', { returnObjects: true }));
    const settingsTrigger =
      <button className="ee-settings-btn btn btn-link">
        <span className="sr-only">{t('gameSettings.settings')}</span>
      </button>;
    let hasTip = false;
    let tipProps = {};
    if (isDemo && (currentPhase === 0)) {
      switch (screen) {
        case 1:
          hasTip = true;
          tipProps = {
            text: i18n.t('tooltip.muteButtonTip'),
            position: 'left',
            className: 'mute'
          };
          break;
        default:
          break;
      }
    }
    return (
      <div className="ee-phase-footer">
        <div className="ee-phase-footer-logo">
          <span className="sr-only">{t('global.title')}</span>
        </div>
        <div className="ee-phase-footer-breadcrumb">
          <ul>
            {allPhases.map((phase, index) => {
              const currentPhaseClassName = (currentPhase === index ? 'ee-breadcrumb-current' : '');
              const completePhaseClassName = (currentPhase > index ? 'ee-breadcrumb-complete' : '');
              const phaseClassNames = `ee-breadcrumb-${phase.name} ${currentPhaseClassName} ${completePhaseClassName}`;
              return (
                <li className={phaseClassNames} key={`phase-${index}`}>
                  <span className="sr-only">{phase.title}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="ee-phase-footer-utilities">
          {hasTip ? (
            <div className="ee-tutorial">
              <TutorialTip {...tipProps} />
            </div>
          ) : null}
          <div className="ee-game-settings">
            <Tooltip trigger={settingsTrigger}>
              <ul>
                <li>{t('gameSettings.gameCode')} {gameId}</li>
                <li>
                  <button className="btn btn-link" onClick={onClickQuit}>
                    {t('gameSettings.quitThisGame')}
                  </button>
                </li>
              </ul>
            </Tooltip>
          </div>
          <button className="ee-mute-btn btn btn-link" onClick={this.handleClickMute}>
            <span className="sr-only">{t('facilitator.settings.muteSounds')}</span>
          </button>
        </div>
      </div>
    );
  }
}

const translatedComponent = withTranslation()(withModuleTranslation()(PhaseFooter));
export default connect(mapStateToPropsUser, mapDispatchToProps)(translatedComponent);
