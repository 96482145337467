import React from 'react';

import TabbedPopup from './TabbedPopup';
import i18n from '../../i18n';
import Tooltip from '../Tooltip';
import MapPopup from './MapPopup';

function Footer({ tm, scenario, phaseName, reset }) {
  const rules = tm(`module.participant.${phaseName}.rules.dialog`, { returnObjects: true });
  const rulesTrigger =
    <button className="ee-rules-btn btn btn-link">
      {tm(`module.participant.${phaseName}.rules.button`)}
    </button>;
  const settingsTrigger =
    <button className="ee-settings-btn btn btn-link">
      <span className="sr-only">{i18n.t('gameSettings.settings')}</span>
    </button>;
  //const hasMap = (scenario === 'flood');
  const hasMap = false;
  const mapTrigger =
    <button className="ee-map-btn btn btn-link">
      <span className="sr-only">Map</span>
    </button>;
  return (
    <div className="ee-player-footer">
      <div className="ee-current-phase-logo">
        <span className="sr-only">{phaseName}</span>
      </div>
      <div className="ee-buttons-left">
        <TabbedPopup trigger={rulesTrigger} title={rules.title} tabs={rules.tabset} />
        {hasMap ? (
          <MapPopup trigger={mapTrigger} />
        ) : null}
      </div>
      <div className="ee-buttons-right">
        <div className="ee-game-settings">
          <Tooltip trigger={settingsTrigger} position="top right">
            <ul>
              <li>
                <button onClick={reset} className="btn btn-link">{i18n.t('gameSettings.quitThisGame')}</button>
              </li>
            </ul>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default Footer;
