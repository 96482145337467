import React from 'react';
import { mapStateToPropsUser, mapDispatchToProps } from '../../action';
import { connect } from 'react-redux';

import i18n from '../../i18n';

class SoundEffect extends React.Component {
  constructor(props) {
    super(props);
    this.handlePlayAudio = this.handlePlayAudio.bind(this);
  }

  handlePlayAudio(sound) {
    const { playAudio } = this.props;
    playAudio(sound);
  }

  render() {
    const { sound, button, children } = this.props;
    return (
      <blockquote className="ee-callout-with-banner ee-sound-effect">
        <div className="ee-callout-banner">
          <span className="ee-callout-banner-label">{i18n.t('facilitator.soundEffectLabel')}</span>
        </div>
        <div className="ee-callout-content">{children}</div>
        <button className="btn btn-link" onClick={() => (this.handlePlayAudio(sound))}>
          {button}
        </button>
      </blockquote>
    );
  }
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(SoundEffect);
