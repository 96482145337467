import {history} from "../History";

const auth = require('@feathersjs/authentication-client');

const {setUser} = require('./index');

let feathersApp;
let userService;
let mgmtService;
let store;

const noUser = {};

function dispatchUser( user ){

  console.log('dispatchUser',user);
  store.dispatch(setUser(user));

  return user;
}

export async function init( app, s ){
  feathersApp = app;
  store = s;
  userService = feathersApp.service('userInfo');
  console.log('userService',userService);

  mgmtService = feathersApp.service('acctRecovery');

  feathersApp.on('logout', () => {
    dispatchUser( noUser );
  });

  const options = {
    storage: window.sessionStorage
  };
  feathersApp.configure(auth(options));
  return feathersApp.authenticate().then( result => {
    console.log('auth init: ok', result);
    return handleToken( result.accessToken );
  }).catch(e => {
    console.log('auth init: failed', e);
    return dispatchUser( noUser );
  });
}

export async function login( userid, pass ){
  return feathersApp.authenticate({
    strategy: 'local',
    email: userid,
    password: pass
  }).then( response => {
    console.log('login ok', response);
    return handleToken( response.accessToken );
  }).catch(e => {
    // Show login page (potentially with `e.message`)
    console.error('login error', e);
    dispatchUser( noUser );
    return Promise.reject( e );
  });
}

export async function handleToken( accessToken ){
  console.log('accessToken',accessToken);
  let payload = await feathersApp.passport.verifyJWT(accessToken);
  console.log('payload',payload);
  let user    = await userService.get(payload.userId);
  console.log('user',user);
  return dispatchUser( user );
}

export async function createUser( userInfo ){
  return userService.create( userInfo )
    .then( created => {
      console.log('created user',created);
      return login( userInfo.email, userInfo.password )
    })
    .catch( err => {
      console.error('create user error',JSON.stringify(err,null,1));
      return Promise.reject( err );
    });
}

export async function updateUser( userInfo ){
  let currentUser = store.getState().userInfo;
  let id = currentUser.id;

  return userService.patch( id, userInfo )
    .then( updated => {
      console.log('updated user',updated);
      return updated;
    })
    .catch( err => {
      console.error('update user error', err);
      return Promise.reject( err );
    })
}

export async function requestResetPassword( email ){
  console.log('requestResetPassword',email);
  return mgmtService.create({
    action: 'sendResetPwd',
    value: {
      email: email
    }
  })
}

export async function resetPassword( email, code, password ){
  console.log('resetPassword',email,code);
  return mgmtService.create({
    action: 'resetPwdLong',
    value: {
      token: code,
      password: password
    }
  })
}

export async function logout(){
  return feathersApp.logout()
    .then( result => {
      console.log('logout result', result);
      return dispatchUser( noUser );
    })
    .then( userResult => history.push('/') )
    .catch( err => {
      console.error('logout error',err);
      return Promise.reject( err );
    });
}