import React from 'react';
import Equalizer from 'react-equalizer';

class Board extends React.Component {
  constructor(props) {
    super(props);
    this.option1Ref = null;
    this.option2Ref = null;
    this.handleClickPath = this.handleClickPath.bind(this);
    this.handleClickNeed = this.handleClickNeed.bind(this);
  }

  handleClickPath(newDecision) {
    this.props.setDecision(newDecision);
  }

  handleClickNeed(resourceKey) {
    const { my: { resource } } = this.props;
    const selectedResource = resource[resourceKey];
    if (!selectedResource.isBlocked) {
      if (selectedResource.isUsed) {
        this.props.resourceRelease(resourceKey);
      } else {
        this.props.resourceUse(resourceKey);
      }
    }
  }

  getEqualizerRefs() {
    return [
      this.option1Ref,
      this.option2Ref
    ];
  }

  render() {
    const { tm, my: { neighborhood, score: { decision } } } = this.props;
    const board = tm(`module.participant.respond.challenges.${neighborhood}`, { returnObjects: true });
    const boardPaths = board.options;
    return (
      <Equalizer nodes={() => this.getEqualizerRefs()} byRow={false}>
        <div className="ee-challenge-board-paths">
          {boardPaths.map((challenge, challengeIndex) => {
            const challengeDecision = challengeIndex + 1;
            const selectedClassName = ((decision === challengeDecision) ? `ee-board-path-selected ee-board-path-${challengeIndex}-selected` : '');
            return (
              <div
                className={`ee-board-path ee-board-path-${challengeIndex} ${selectedClassName}`}
                key={`challenge-board-path-${challengeIndex}`}
              >
                <header
                  className="ee-board-option"
                  onClick={() => this.handleClickPath(challengeDecision)}
                  ref={n => this[`option${challengeDecision}Ref`] = n}
                >
                  <h3 className="ee-option-name">
                    <span dangerouslySetInnerHTML={{__html: challenge.name}} />
                  </h3>
                </header>
                <h4 className="ee-needs-header">{tm('module.participant.respond.needLabel')}</h4>
                <Needs {...this.props} needs={challenge.needs} onClickNeed={this.handleClickNeed} />
              </div>
            );
          })}
        </div>
      </Equalizer>
    );
  }
}

function Needs({ needs, onClickNeed, ...otherProps }) {
  const { tm, my: { resource, score } } = otherProps;
  return (
    <React.Fragment>
      {needs.map((need, needIndex) => {
        const needOptions = need.options;
        const titleClassName = (score.needMet[needIndex] ? 'ee-needs-title-complete' : '');
        return (
          <section className="ee-needs-wrapper" key={`challenge-need-${needIndex}`}>
            <h5
              className={`ee-needs-title ${titleClassName}`}
              dangerouslySetInnerHTML={{__html: need.title}}
            />
            {needOptions.map((needOptionGroup, needOptionGroupIndex) => {
              const isLast = (needOptions.length === (needOptionGroupIndex + 1));
              const listClassName = (needOptionGroup.length > 1 ? 'ee-needs-list-multiple' : 'ee-needs-list-single');
              return (
                <React.Fragment key={`challenge-need-options-group-${needIndex}-${needOptionGroupIndex}`}>
                  <ul className={`ee-needs-list ${listClassName}`}>
                    {needOptionGroup.map((needOption, needOptionIndex) => {
                      let itemClassName = '';
                      //console.log(resource,needOption);
                      if( resource[needOption] ){
                        if (resource[needOption].isBlocked) {
                          itemClassName = 'ee-needs-list-item-disabled';
                        } else if (resource[needOption].isUsed) {
                          itemClassName = 'ee-needs-list-item-selected';
                        }
                      }
                      return (
                        <li
                          className={`ee-needs-list-item ${itemClassName}`}
                          key={`challenge-need-option-${needIndex}-${needOptionGroupIndex}-${needOptionIndex}`}
                          onClick={() => onClickNeed(needOption)}
                        >
                          {tm(`module.resourceCards.${needOption}`)}
                        </li>
                      );
                    })}
                  </ul>
                  {!isLast ? (
                    <div className="ee-needs-separator">
                      {tm('module.participant.respond.orLabel')}
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </section>
        );
      })}
    </React.Fragment>
  );
}

export default Board;
