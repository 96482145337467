import React from 'react';

import PlayerTypesPrepare from './PlayerTypesPrepare';
import PlayerTypesRespond from './PlayerTypesRespond';
import PlayerTypesRecover from '../PlayerTypesRecover';
import PlayerTypesAdapt from './PlayerTypesAdapt';

function PlayerTypes({ phase, section, currentScenario, showDialog, onOpenDialog }) {
  const playerTypesProps = {
    currentScenario,
    showDialog,
    onClick: onOpenDialog
  };
  let playerTypes = null;
  switch (phase) {
    case 0:
      playerTypes = <PlayerTypesPrepare {...playerTypesProps} />;
      break;
    case 1:
      playerTypes = <PlayerTypesRespond {...playerTypesProps} />;
      break;
    case 2:
      playerTypes = <PlayerTypesRecover {...playerTypesProps} section={section} />;
      break;
    case 3:
      playerTypes = <PlayerTypesAdapt {...playerTypesProps} />;
      break;
    default:
      break;
  }
  return (
    <div className={`ee-phase-actions-player-types ${showDialog ? 'invisible' : ''}`}>
      <div className="ee-phase-player-types">{playerTypes}</div>
    </div>
  );
}

export default PlayerTypes;

