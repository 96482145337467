import React from 'react';

const framesStyle = {
  'transform': 'scale(.4)', /* .4 is 40% of normal size */
  'transform-origin': '0 0',  /* Standard Property */
  'width': '3072px'
};

const frameStyle = {
  'min-height': '768px',
  'min-width': '1024px',
  'border': '0',
  'padding-bottom': '10px',
  'float': 'left'
};

function ViewAllClients() {
  document.body.style.backgroundColor = 'black';
  return (
    <div style={framesStyle}>
      <iframe style={frameStyle} src={`${process.env.PUBLIC_URL}/play${window.location.search}#1`} title="1" />
      <iframe style={frameStyle} src={`${process.env.PUBLIC_URL}/play${window.location.search}#2`} title="2" />
      <iframe style={frameStyle} src={`${process.env.PUBLIC_URL}/play${window.location.search}#3`} title="3" />
      <iframe style={frameStyle} src={`${process.env.PUBLIC_URL}/play${window.location.search}#4`} title="4" />
      <iframe style={frameStyle} src={`${process.env.PUBLIC_URL}/play${window.location.search}#5`} title="5" />
      <iframe style={frameStyle} src={`${process.env.PUBLIC_URL}/play${window.location.search}#6`} title="6" />
    </div>
  );
}

export default ViewAllClients;
