import hurricaneBoards  from './hurricane-boards.json';
import earthquakeBoards from './earthquake-boards.json';
import floodBoards     from './flood-boards.json';

import hurricaneChallengeResources from './hurricane-challenges.json';
import floodChallengeResources from './flood-challenges.json';
import earthquakeChallengeResources from './earthquake-challenges.json';


const sector = [
  "facilitator",
  "localAndStateGovernment",
  "businesses",
  "communityGroups",
  "households",
  "federalGovernment",
  "firstResponders"
];

const tip = [
  "sectorRoleCityInfoTip",
  "biggestThreatTip",
  "resourceBenefitsTip",
  "threeMinuteWarning"
];

const audio = {
  'alert': { src: `${process.env.PUBLIC_URL}/assets/audio/player/shared/Alert.mp3` },
  'applause': { src: `${process.env.PUBLIC_URL}/assets/audio/player/shared/Applause.mp3` },
  'challenge': { src: `${process.env.PUBLIC_URL}/assets/audio/player/shared/Challenge.mp3` },
  'fanfare': { src: `${process.env.PUBLIC_URL}/assets/audio/player/shared/Fanfare.mp3` },
  'happy': { src: `${process.env.PUBLIC_URL}/assets/audio/player/shared/Happy.mp3` },
  'tip': { src: `${process.env.PUBLIC_URL}/assets/audio/player/TipReceived.mp3` }
};

const hurricaneNeighborhood = [
  "facilitator",
  "midtown",
  "sunnybrook",
  "downtown",
  "hillside",
  "meadowglen",
  "waterview"
];

const hurricaneChallenges = [
  "flashFlood",
  "blackout",
  "roadClosure",
  "gridlock"
];

const hurricaneChallengeTiming = {
  "blackout": 2,
  "gridlock": 2
};

const hurricaneAudio = {
  ...audio,
  'ambiance': { src: `${process.env.PUBLIC_URL}/assets/audio/player/Hurricane/Seagulls.mp3`, loop: true },
  'warning': { src: `${process.env.PUBLIC_URL}/assets/audio/player/Hurricane/WindAndRain.mp3` },
  'event': { src: `${process.env.PUBLIC_URL}/assets/audio/player/Hurricane/StormWarning.mp3` }
};

const hurricane = {
  sector:       sector,
  tip:          tip,
  neighborhood: hurricaneNeighborhood,
  challenges:   hurricaneChallenges,
  challengeResources: hurricaneChallengeResources,
  challengeTiming:    hurricaneChallengeTiming,
  challengeAudioOverrides: {},
  boards:       hurricaneBoards,
  audio:        hurricaneAudio
};

const floodNeighborhood = [
  "facilitator",
  "hillside",
  "meadowglen",
  "downtown",
  "waterview",
  "sunnybrook",
  "midtown"
];

const floodChallenges = [
  "explosion",
  "mappingGlitch",
  "techOutage",
  "bridgeClosure"
];

const floodChallengeTiming = {
  "mappingGlitch": 5,
  "bridgeClosure": 2
};

const floodAudio = {
  ...audio,
  'ambiance': { src: `${process.env.PUBLIC_URL}/assets/audio/player/Flood/RiverCityAmbiance.mp3`, loop: true },
  'warning': { src: `${process.env.PUBLIC_URL}/assets/audio/player/Flood/LightRain.mp3` },
  'event': { src: `${process.env.PUBLIC_URL}/assets/audio/player/Flood/Lightning.mp3` }
};

const flood = {
  sector:       sector,
  tip:          tip,
  neighborhood: floodNeighborhood,
  challenges:   floodChallenges,
  challengeResources: floodChallengeResources,
  challengeTiming:    floodChallengeTiming,
  challengeAudioOverrides: {},
  boards:       floodBoards,
  audio:        floodAudio
};

const earthquakeNeighborhood = [
  "facilitator",
  "midtown",
  "waterview",
  "hillside",
  "sunnybrook",
  "downtown",
  "meadowglen"
];

const earthquakeChallenges = [
  "fire",
  "emergencyAid",
  "chasm",
  "trainDerailment"
];

const earthquakeChallengeTiming = {
  "fire":         1,
  "emergencyAid": 2
};

const earthquakeAudio = {
  ...audio,
  'ambiance': { src: `${process.env.PUBLIC_URL}/assets/audio/player/Earthquake/RockCityAmbiance.mp3`, loop: true },
  'warning': null,
  'event': { src: `${process.env.PUBLIC_URL}/assets/audio/player/earthquake.mp3` }
};

const earthquakeChallengeAudio = {
  "emergencyAid": "happy"
};

const earthquake = {
  sector:       sector,
  tip:          tip,
  neighborhood: earthquakeNeighborhood,
  challenges:   earthquakeChallenges,
  challengeResources: earthquakeChallengeResources,
  challengeTiming:    earthquakeChallengeTiming,
  challengeAudioOverrides: earthquakeChallengeAudio,
  boards:       earthquakeBoards,
  audio:        earthquakeAudio
};

export const scenario = {
  hurricane,
  flood,
  earthquake
};

function normalize( scenarioName ){
  if( !scenarioName || !scenario[scenarioName] ){
    scenarioName = 'hurricane';
  }
  return scenarioName;
}

/**
 * Given a client number, what is the name of this sector?
 * @param scenarioName
 * @param client
 * @return {string|*}
 */
export function sectorName( scenarioName, client ){
  scenarioName = normalize( scenarioName );
  return scenario[scenarioName].sector[client];
}

/**
 * Given a client number, what is the name of this neighborhood?
 * @param scenarioName
 * @param client
 * @return {string}
 */
export function neighborhoodName( scenarioName, client ){
  scenarioName = normalize( scenarioName );
  return scenario[scenarioName].neighborhood[client];
}

/**
 * Get the ordered name of the challenges, or a single challenge name.
 * @param scenarioName
 * @param optIndex Which challenge to get (optional)
 * @return {string[]} Either an array of all challenge names, or one challenge name.
 */
export function challengeName( scenarioName, optIndex ){
  scenarioName = normalize( scenarioName );
  let ret = scenario[scenarioName].challenges;
  if( typeof optIndex !== 'undefined' ){
    ret = ret[optIndex];
  }
  return ret;
}

/**
 * Given a challenge, what resources are blocked for that challenge
 * @param scenarioName
 * @param challengeNameOrIndex
 * @return {*} Array of resource names that are blocked
 */
export function challengeResources( scenarioName, challengeNameOrIndex ){
  scenarioName = normalize( scenarioName );
  if( typeof challengeNameOrIndex === 'number' ){
    challengeNameOrIndex = challengeName( scenarioName, challengeNameOrIndex );
  }
  return scenario[scenarioName].challengeResources[challengeNameOrIndex];
}

export function challengeTiming( scenarioName, challengeNameOrIndex ){
  scenarioName = normalize( scenarioName );
  if( typeof challengeNameOrIndex === 'number' ){
    challengeNameOrIndex = challengeName( scenarioName, challengeNameOrIndex );
  }
  let ret = scenario[scenarioName].challengeTiming[challengeNameOrIndex];
  if( ret ){
    ret = ret * 60 * 1000;
  }
  return ret;
}

export function challengeAudio(scenarioName, challengeNameOrIndex) {
  scenarioName = normalize(scenarioName);
  if (typeof challengeNameOrIndex === 'number') {
    challengeNameOrIndex = challengeName(scenarioName, challengeNameOrIndex);
  }
  let ret = 'challenge';
  let audioOverrides = scenario[scenarioName].challengeAudioOverrides;
  if (challengeNameOrIndex in audioOverrides) {
    ret = audioOverrides[challengeNameOrIndex];
  }
  return ret;
}

/**
 * What are all the resources that a neighborhood may use for one of their task options.
 * @param opt
 * @return {Array} Array of resource names for this neighborhood option
 */
function needsOptions( opt ){
  let ret = [];
  let allNeeds = opt;
  for( let co=0; co<allNeeds.length; co++ ){
    let options = allNeeds[co];
    for( let c1=0; c1<options.length; c1++ ){
      ret = ret.concat( options[c1] );
    }
  }
  return ret;
}

/**
 * For a neighborhood, what are all the resources that they may need?
 * @param boards
 * @param neighborhood
 * @return {Array} Array of resource names for this neighborhood
 */
function neighborhoodResources( boards, neighborhood ){
  let nc = boards[neighborhood];
  if( !nc ){
    return undefined;
  }

  let ret = [];

  let allOptions = nc;
  for( let co=0; co<allOptions.length; co++ ){
    ret = ret.concat( needsOptions( allOptions[co] ) );
  }

  return ret;
}

/**
 * For all the neighborhoods (indexed by client number), what resources do each need?
 * @param boards Info about all challenge boards
 * @param neighborhoods
 * @return {Array} Array of neighborhood resources
 */
function processResources( boards, neighborhoods ){
  let ret = [undefined];

  for( let co=1; co<neighborhoods.length; co++ ){
    let n = neighborhoods[co];
    ret.push( neighborhoodResources( boards, n ) );
  }

  return ret;
}

/**
 * For all the neighborhoods (indexed by client number), what resources do each need?
 * @param scenarioName
 * @return {Array} Array of neighborhood resources
 */
export function resources( scenarioName ){
  scenarioName = normalize( scenarioName );
  let neighborhoods = scenario[scenarioName].neighborhood;
  let boards = scenario[scenarioName].boards;
  let ret = processResources( boards, neighborhoods );
  return ret;
}

/**
 * For one neighborhood, what resources can it potentially use?
 * @param scenarioName
 * @param playerOrClient
 * @return {Array} Neighborhood resources
 */
export function playerResources( scenarioName, playerOrClient ){
  if( typeof playerOrClient === 'number' ){
    playerOrClient = neighborhoodName( scenarioName, playerOrClient )
  }
  let challenges = scenario[scenarioName].boards;
  return neighborhoodResources( challenges, playerOrClient );
}

/**
 *
 * @param scenarioName
 * @param playerOrClient
 * @param optDecision Optional. The player decision (either 1 or 2).
 * @param optNeed
 * @param optOption
 * @return {*}
 */
export function board( scenarioName, playerOrClient, optDecision, optNeed, optOption ){
  let ret;

  let challenges = scenario[scenarioName].boards;
  if( typeof playerOrClient === 'number' ){
    playerOrClient = neighborhoodName( scenarioName, playerOrClient )
  }
  ret = challenges[playerOrClient];

  if( typeof optDecision !== 'undefined' ){
    if( optDecision <= 0 || optDecision > ret.length ){
      console.error( 'Requesting decision of ', optDecision, playerOrClient, scenarioName, optNeed, optOption );
    }
    ret = ret[optDecision-1];

    if( typeof optNeed !== 'undefined' ){
      ret = ret[optNeed];

      if( typeof optOption !== 'undefined' ){
        ret = ret[optOption];
      }
    }
  }
  return ret;
}
