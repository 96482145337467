import React from 'react';
import { mapDispatchToProps, mapStateToPropsUser } from '../../action';
import { Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import ListPage from './List';
import LobbyPage from './Lobby'
import GamePhase from './GamePhase';
import CreditsPage from '../player/Credits';
import { stateToLocation as location, history } from '../../History';
import i18n from '../../i18n';
import ConfirmPopup from '../../components/ConfirmPopup';
import CreateGame from './CreateGame';

class Facilitator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEndGamePopupOpen: false
    };
    this.handleOpenEndGamePopup = this.handleOpenEndGamePopup.bind(this);
    this.handleCloseEndGamePopup = this.handleCloseEndGamePopup.bind(this);
    this.handleClickEndGameConfirm = this.handleClickEndGameConfirm.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (history.location.pathname.startsWith('/play')) {
      location(nextProps);
    }
    return null;
  }

  handleOpenEndGamePopup() {
    this.setState({ isEndGamePopupOpen: true });
  }

  handleCloseEndGamePopup() {
    this.setState({ isEndGamePopupOpen: false });
  }

  handleClickEndGameConfirm() {
    this.props.reset();
  }

  render() {
    const { isDemo } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <body className={`ee-facilitator-page ${isDemo ? 'ee-test-game-page' : ''}`} />
        </Helmet>
        <Router history={history}>
          <Switch>
            <Route exact path="/play/0/1" component={LobbyPage} />
            <Route path="/play/5" component={CreditsPage} />
            <Route path="/play" render={() => (
              <React.Fragment>
                {isDemo ? (
                  <div className="ee-alert-banner">
                    <div className="ee-alert ee-alert-info">
                      <header>
                        <h1>{i18n.t('banner.facilitatorTestGameBannerTitle')}</h1>
                      </header>
                      <p>{i18n.t('banner.facilitatorTestGameBannerDescription')}</p>
                    </div>
                    <button className="ee-end-game-btn btn btn-link" onClick={this.handleOpenEndGamePopup}>
                      {i18n.t('banner.facilitatorTestGameBannerEndGame')}
                    </button>
                  </div>
                ) : null}
                <GamePhase onClickQuit={this.handleOpenEndGamePopup} />
              </React.Fragment>
            )} />
            <Route path="/create/:gameType(new|test)" render={({ match }) => (
              <CreateGame isTest={match.params.gameType === 'test'} />
            )} />
            <Route path="/" component={ListPage} />
          </Switch>
        </Router>
        <ConfirmPopup
          isOpen={this.state.isEndGamePopupOpen}
          onClose={this.handleCloseEndGamePopup}
          onConfirm={this.handleClickEndGameConfirm}
          title={i18n.t('facilitator.quitModal.title')}
          content={i18n.t('facilitator.quitModal.description')}
          button={i18n.t('facilitator.quitModal.confirm')}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToPropsUser, mapDispatchToProps)(Facilitator);
