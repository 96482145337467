import React from 'react';

import PhaseDialogPrepare from './PhaseDialogPrepare';
import PhaseDialogRespond from './PhaseDialogRespond';
import PhaseDialogRecover from './PhaseDialogRecover';
import PhaseDialogAdapt from './PhaseDialogAdapt';

function PhaseDialog({ phase, currentScenario, playerTypeKey, handleCloseDialog }) {
  const dialogProps = {
    currentScenario,
    playerTypeKey,
    handleCloseDialog
  };
  let phaseDialog;
  switch (phase) {
    case 0:
      phaseDialog = <PhaseDialogPrepare {...dialogProps} />;
      break;
    case 1:
      phaseDialog = <PhaseDialogRespond {...dialogProps} />;
      break;
    case 2:
      phaseDialog = <PhaseDialogRecover {...dialogProps} />;
      break;
    case 3:
      phaseDialog = <PhaseDialogAdapt {...dialogProps} />;
      break;
    default:
      break;
  }
  return <div className="ee-phase-dialog ee-animate">{phaseDialog}</div>;
}

export default PhaseDialog;

